import { createContext, useContext, useMemo } from 'react';
import { useLocalStorage } from './useLocalStorage';
const AuthContext = createContext();
const API_URL = process.env.REACT_APP_AGENTS_REMOTELY_API_HOST || 'https://api.agentsremotely.com';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null);

  const signIn = async (data) => {
    setUser(data);
  };

  const signOut = () => {
    setUser(null);
  };

  const reauthorize = async () => {
    if(user.refreshToken) {
      try {
        const data = await fetch(API_URL + '/agents/' + user.userId + '/sessions', {
          body: JSON.stringify({refreshToken : user.refreshToken}),
          method: 'POST',
          mode: 'cors',
          //credentials: 'include'
        }).then((response) => {
          return response.json();
        }).then((res) => {
          return res;
        });
        setUser(Object.assign(user, data));
        return user;
      } catch(err) {
        console.log(err);
        signOut();
      }
    } else {
      signOut();
    }
  }
  const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const roles = {};
  if(user?.idToken) {
    const groups = parseJwt(user.idToken)['cognito:groups'];
    if(groups) {
      groups.forEach(group => {
        if(group.indexOf('role-') === 0) {
          roles[group.substring(5)] = 1;
        }
      });
    }
  }

  const value = useMemo(
    () => ({
      user,
      signIn,
      reauthorize,
      signOut,
      roles,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
