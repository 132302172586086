//https://github.com/shekharramola/react-wheel-of-prizes
import React, { useEffect, useState } from 'react'
import './WheelOfPrizes.scss';

const WheelComponent = ({
  segments,
  segColors,
  game,
  onFinished,
  primaryColor = 'black',
  contrastColor = 'white',
  buttonText = 'Spin',
  isOnlyOnce = true,
  size = 290,
  upDuration = 100,
  downDuration = 1000,
  attemptId,
  fontFamily = 'proxima-nova'
}) => {
  let currentSegment = ''
  let currentSegmentIndex = 0;
  let isStarted = false
  const [isFinished, setFinished] = useState(false)
  let timerHandle = 0
  const timerDelay = segments.length
  let angleCurrent = 0
  let angleDelta = 0
  let upTime;
  let downTime;
  let canvasContext = null
  let maxSpeed = Math.PI / `${segments.length}`
  let spinStart = 0
  let player;
  let frames = 0
  const centerX = 300
  const centerY = 300
  const winningSegment = game ? '$' + game.outcome.value : null;

  useEffect(() => {
    console.log('init wheel');
    wheelInit()
    if(game != null) {
      spin();
    }

    return () => {
      const canvas = document.querySelector('.wheel__canvas');
      if(canvas) {
        canvas.removeEventListener('click', spin);
        canvasContext.clearRect(0, 0, canvas.width, canvas.height);
      }
    };
  }, [segments, game])

  const wheelInit = () => {
    initCanvas()
    wheelDraw()
  }

  const initCanvas = () => {
    player = document.querySelector('.player');
    let canvas = document.querySelector('.wheel__canvas')
    console.log(navigator)
    if (navigator.userAgent.indexOf('MSIE') !== -1) {
      canvas = document.createElement('canvas')
      canvas.setAttribute('width', 1000)
      canvas.setAttribute('height', 600)
      canvas.setAttribute('id', 'canvas')
      document.querySelector('.wheel').appendChild(canvas)
    }
    //canvas.addEventListener('click', spin, false)
    canvasContext = canvas.getContext('2d')
  }
  const spin = () => {
    isStarted = true
    if (timerHandle === 0) {
      player.play();
      spinStart = new Date().getTime();
      // maxSpeed = Math.PI / ((segments.length*2) + Math.random())
      upTime = upDuration;//segments.length * upDuration
      downTime = downDuration;// + downTimeDelta * timerDelay;// - 2000 + (Math.random() * 4000);//segments.length * downDuration
      maxSpeed = Math.PI / segments.length;
      frames = 0;
      timerHandle = setInterval(onTimerTick, timerDelay);
    }
  }
  const onTimerTick = () => {
    frames++
    draw()
    const duration = new Date().getTime() - spinStart
    let progress = 0
    let finished = false
    if (duration < upTime) {
      progress = duration / upTime
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2)
    } else {
      if (winningSegment) {
        if (currentSegment === winningSegment && frames > segments.length * 5) {
          progress = duration / downTime 
          angleDelta =
            maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
          progress = 1
        } else {
          progress = duration / downTime
          angleDelta =
            maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
        }
      } else {
        progress = duration / downTime
        angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
      }
      if (progress >= 1) finished = true
    }

    player.playbackRate = 3 / maxSpeed * (angleDelta >= 0 ? angleDelta : 0);
    angleCurrent += angleDelta
    while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2
    if (finished) {
      setFinished(true)
      onFinished(currentSegment)
      clearInterval(timerHandle)
      timerHandle = 0
      angleDelta = 0
      player.pause();
    }
  }

  const wheelDraw = () => {
    clear()
    drawWheel()
  }

  const draw = () => {
    clear()
    drawWheel()
  }

  const adjust = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  };

  const drawSegment = (key, lastAngle, angle) => {
    const ctx = canvasContext
    const value = segments[key]
    ctx.save()
    ctx.beginPath()
    ctx.moveTo(centerX, centerY)
    ctx.arc(centerX, centerY, size, lastAngle, angle, false)
    ctx.lineTo(centerX, centerY)
    ctx.closePath()
    let grd = ctx.createLinearGradient(centerX + size * Math.cos(lastAngle), centerY + size * Math.sin(lastAngle), centerX + size * Math.cos(angle), centerY + size * Math.sin(angle));
    //let grd = ctx.createLinearGradient(centerX, centerY, centerX + size * Math.cos((angle + lastAngle) / 2), centerY + size * Math.sin((angle + lastAngle) / 2));
    grd.addColorStop(0, adjust(segColors[key % segColors.length], 100));
    grd.addColorStop(1, segColors[key % segColors.length]); 
    ctx.fillStyle = grd;//segColors[key % segColors.length]
    ctx.shadowColor="black";
    ctx.shadowBlur=7;
    ctx.fill()
    ctx.stroke()
    ctx.save()
    ctx.translate(centerX, centerY)
    ctx.rotate((lastAngle + angle) / 2)
    ctx.fillStyle = contrastColor
    ctx.font = 'bold 2em ' + fontFamily
    ctx.textAlign = 'right'
    ctx.shadowColor="black";
    ctx.shadowBlur=7;
    ctx.lineWidth=3;
    ctx.strokeText(value.substr(0, 21), size - 30, 0, 100)
    ctx.shadowBlur=0;
    ctx.fillText(value.substr(0, 21), size - 30, 0, 100)
    ctx.restore()
  }

  const drawWheel = () => {
    const ctx = canvasContext
    let lastAngle = angleCurrent
    const len = segments.length
    const PI2 = Math.PI * 2
    ctx.lineWidth = 1
    ctx.strokeStyle = primaryColor
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    ctx.font = '1em ' + fontFamily
    for (let i = 1; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent
      drawSegment(i - 1, lastAngle, angle)
      lastAngle = angle
    }

    drawNeedle()
    // Draw a center circle
    ctx.beginPath()
    ctx.arc(centerX, centerY, 50, 0, PI2, false)
    ctx.closePath()
    const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, 50);
    gradient.addColorStop(0, "#f00");
    gradient.addColorStop(1, "#800");
    ctx.fillStyle = gradient;//primaryColor
    ctx.lineWidth = 10
    ctx.strokeStyle = '#eaf199';//contrastColor
    ctx.fill()
    ctx.font = 'bold 1em ' + fontFamily
    ctx.fillStyle = contrastColor
    ctx.textAlign = 'center'
    ctx.shadowColor="black";
    ctx.shadowBlur=7;
    //ctx.fillText(buttonText, centerX, centerY + 3)
    ctx.stroke()
    ctx.beginPath()
    ctx.arc(centerX, centerY, 55, 0, PI2, false)
    ctx.closePath()

    ctx.lineWidth = 1
    ctx.strokeStyle = primaryColor
    ctx.stroke()

    // Draw outer circle
    ctx.beginPath()
    ctx.arc(centerX, centerY, size, 0, PI2, false)
    ctx.closePath()

    ctx.lineWidth = 4
    ctx.strokeStyle = primaryColor
    ctx.stroke()

    ctx.beginPath()
    ctx.arc(centerX, centerY, size + 4, 0, PI2, false)
    ctx.closePath()

    ctx.lineWidth = 10
    ctx.strokeStyle = '#eaf199';//primaryColor
    ctx.stroke()

    ctx.beginPath()
    ctx.arc(centerX, centerY, size + 14, 0, PI2, false)
    ctx.closePath()

    ctx.lineWidth = 10
    ctx.strokeStyle = primaryColor
    ctx.stroke()

  }

  const drawNeedle = () => {
    const ctx = canvasContext
    ctx.lineWidth = 1
    ctx.strokeStyle = '#eaf199';
    ctx.fillStyle = '#eaf199';
    ctx.beginPath()
    ctx.moveTo(centerX + 20, centerY - 50)
    ctx.lineTo(centerX - 20, centerY - 50)
    ctx.lineTo(centerX, centerY - 70)
    ctx.closePath()
    ctx.fill()
    const change = angleCurrent + Math.PI / 2
    let i =
      segments.length -
      Math.floor((change / (Math.PI * 2)) * segments.length) -
      1
    if (i < 0) i = i + segments.length
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = primaryColor
    ctx.font = 'bold 1.5em ' + fontFamily
    currentSegment = segments[i]
    currentSegmentIndex = i;
    isStarted && ctx.fillText(currentSegment, centerX + 10, centerY + size + 50)
  }
  const clear = () => {
    const ctx = canvasContext
    ctx.clearRect(0, 0, 1000, 800)
  }
  return (
    <div className="wheel">
      <canvas
        className='wheel__canvas'
        width='600'
        height='600'
        style={{
          pointerEvents: isFinished && isOnlyOnce ? 'none' : 'auto'
        }}
      />
      <audio className="player" preload="false" loop="loop">
        <source src="/audio/tick.mp3"/>
      </audio>  
    </div>
  )
}
export default WheelComponent
