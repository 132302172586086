'use strict'

module.exports = {
  FULL : { 
    '1' : [{ startTime : '0.00', endTime : '0.00', }], 
    '2' : [{ startTime : '0.00', endTime : '0.00', }], 
    '3' : [{ startTime : '0.00', endTime : '0.00', }], 
    '4' : [{ startTime : '0.00', endTime : '0.00', }], 
    '5' : [{ startTime : '0.00', endTime : '0.00', }], 
    '6' : [{ startTime : '0.00', endTime : '0.00', }], 
    '7' : [{ startTime : '0.00', endTime : '0.00', }], 
  },
};
