import React from 'react';
import { agentJobStatus } from 'agents-remotely-commons/src/model';
import useFetch from '../hooks/useFetch';
import {formatErrorMessage} from '../helper';
import { currency } from 'agents-remotely-commons/src/formatter';

const JobComponent = (props) => {
  return (
    <>
      <div className="job__line"><span className="job__line_label">Name:</span><span className="job__title">{props.component.name}</span></div>
      <div className="job__line"><span className="job__line_label">Description:</span><span className="job__line_link show-next">View</span>
          <div className="job__line job__description is-hidden">{props.component.description}</div>
      </div>
      <div className="job__line"><span className="job__line_label">Pay:</span><span className="job__agent-payment-amount">{currency(props.component.payDetails[0].details[0].amount)}</span>&nbsp;<span className="job__agent-payment-type-description">{props.component.payDetails[0].details[0].description}</span></div>
    </>
  );
}

const Job = (props) => {
  const {apiFetch, data, error, loading, setData} = useFetch();

  const updateJob = (id) => {
    apiFetch('POST', '/agents/{user.id}/jobs/' + id);
  };

  return (
    <div className="job view-job">
      <div className="job__line"><span className="job__line_label">Company:</span><span className="job__company-name">{props.job.company.name}</span></div>
      <div className="job__line"><span className="job__line_label">Name:</span><span className="job__title">{props.job.title}</span></div>
      <div className="job__line"><span className="job__line_label">Description:</span><span className="job__line_link show-next">View</span>
          <div className="job__line job__description is-hidden">{props.job.description}</div>
      </div>
      <div className="job__line"><span className="job__line_label">Join Type:</span><span className="job__join-type-description">{props.job.joinType.description}</span></div>
      <div className="job__line job__status is-hidden"><span className="job__line_label">Status:</span><span className="job__status-description">{props.job.status.description}</span></div>
      {props.job.components.map(component => <JobComponent component={component} key={component.id}/>)}
      <div className="job__line"><span className="job__line_label">Language:</span><span className="job__language-description">{props.job.language.description}</span></div>
      {loading ? <div className="processing"></div>
        : <div className="job__line"><button className="job__action" onClick={(e) => updateJob(props.job.id)}>{props.job.status.id === agentJobStatus.INVITED ? 'Join' : 'Quit'}</button></div>
      }
      {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
    </div>
  );
};

const Jobs = (props) => {
  const rows = [];
  for(const job of props.jobs) {
    rows.push(<Job job={job} key={job.id}/>)
  }
  return (
    <div className="jobs">{rows}</div>
  );
};

export default Jobs;
