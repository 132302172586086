'use strict'

module.exports = {
  NONE : 1,
  POSITION : 2,
  ESTIMATED_WAIT_TIME : 3,
  ids : {
    '1' : 'None',
    '2' : 'Position',
    '3' : 'Estimated Wait Time',
  },
};
