import React from 'react';
import GeneralInfo from '../../components/GeneralInfo';
import AccountSettingsForm from '../../components/AccountSettingsForm';
import useFetch from '../../hooks/useFetch';
import {formatErrorMessage} from '../../helper';

const AccountSettings = () => {
  const {apiFetch, data: agent, error, loading} = useFetch();

  React.useEffect(() => {
    apiFetch('GET', '/agents/{user.id}', {
      select: ['email', 'emailVerified', 'mfaEnabled', 'phone', 'phoneNumberVerified'],
    });
  }, []);

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Account Settings</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Privacy ',
            content: [
              {text: 'The information that you provide within the Agents Remotely platform is strictly confidential and secured through various measures. Our company places the utmost importance on safeguarding your data and takes all necessary precautions to ensure its protection. Rest assured that your information is in reliable hands.'},
            ],
          },
        ]}/>
        {loading ? <div className="processing"></div> : ''}
        {agent ? 
          <div className="account-settings-form content__form">
            <AccountSettingsForm agent={agent}/>
          </div>
          : ''
        }
        {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
      </div>
    </section>
  );
}

export default AccountSettings;
