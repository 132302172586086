import React from 'react';
import GeneralInfo from '../components/GeneralInfo';
import SignInForm from '../components/SignInForm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const SignIn = () => {
  return (
    <section className="content content__no-auth">
      <div className="title">
        <h1>Sign In</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Log in to',
            content: [
              {text: 'Start your financial independence by earning on your own terms.'},
              {text: 'Discover your ideal workplace by choosing companies that align with your values and goals.'},
              {text: 'Maximize your earning potential by working simultaneously for multiple companies.'},
            ],
          },
        ]}/>
        <GoogleReCaptchaProvider reCaptchaKey="6LfdZKUjAAAAACEziad2F6wX-1w0kMS0aU2Ghcgu">
          <SignInForm/>
        </GoogleReCaptchaProvider>
      </div>
    </section>
  );
}

export default SignIn;
