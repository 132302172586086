import React from 'react';
import TextInput from '../components/TextInput';
import { NavLink } from 'react-router-dom';
import { url } from 'agents-remotely-commons/src/formatter';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { formatErrorMessage } from '../helper';

const VerificationForm = (props) => {
  const {apiFetch, data: verification, error, loading} = useFetch();
  const navigate = useNavigate();

  if(verification) {
    navigate(new URLSearchParams(window.location.search).get('redirect') || url('accountSettings'));
  }
  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        verificationCode: '',
        verificationType: props.verificationType,
      }}
      validationSchema={Yup.object({
        verificationCode: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(6, 'Must be exactly 6 digits')
          .max(6, 'Must be exactly 6 digits'),
      })}
      onSubmit={(values) => {
        apiFetch('POST', '/agents/{user.id}/' + props.verificationType + 'Verification', {...values, accessToken: '{user.accessToken}'});
      }}
    >
      <Form className={"form " + props.formName}>
        <div className="form__error">
          {error ?
            formatErrorMessage(error)
            :
            <>
              A verification code has been {props.verificationType === 'email' ? 'emailed to you' : 'sent to your phone'}.  Please enter the code below.  If you have not received the message please check your spam folder.  After a few minutes, you can also request a new code by <NavLink className="form__link confirm-account__resend" to={url(props.verificationType + 'Verification', Object.fromEntries(new URLSearchParams(window.location.search)))}>clicking here</NavLink>.
            </>
          }
        </div>
        <div className="form__line">
          <TextInput
            formName={props.formName}
            label="Verification Code"
            name="verificationCode"
            type="tel"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            required="required"
          />
        </div>
        <div className="form__line">
          {loading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Submit</button>}
        </div>
      </Form>
    </Formik>
  );
}

export default VerificationForm;
