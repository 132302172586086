'use strict'
const currencyModel = require('../model/currency');

const toCamelCase = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const toTitleCase = (str) => {
  return str.toLowerCase().replace('-', ' ').replace('_', ' ').replace(/\s\s+/g, ' ').split(' ').map((word) => {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
};

const titleToWord = (s) => {
  let result = s.replace(/([A-Z])/g, ' $1' );
  return (result.charAt(0).toUpperCase() + result.slice(1).toLowerCase()).trim();
};

const titleToDash = (s) => {
  return (s.replace(/\.?([A-Z]+)/g, function (x,y){return '-' + y.toLowerCase()}).replace(/^-/, '')).replace(/ /g, '');
};

const currency = (val, currencyId=1) => {
  return (currencyModel.prefixes[currencyId] || '') + (val||0).toFixed(2) + (currencyModel.suffixes[currencyId] || '');
};

const agentBucketName = () => {
  return 'agents-remotely-agent-' + process.env.ACCOUNT_ID + '-us-east-1';
};

const companyBucketName = (companyId) => {
  return 'company-' + companyId + (process.env.ENVIRONMENT != 'prod' ? '-' + process.env.ENVIRONMENT : '');
};

const publicCompanyBucketUrl = (companyId) => {
  return 'https://' + companyBucketName(companyId) + '.s3.amazonaws.com';
};

const URLS = {
  accountSettings: '/account/settings.html',
  aptitudeTest: '/tests/aptitude.html',
  availability: '/account/availability.html',
  badges: '/badges.html',
  callDetailReport: '/reports/call-detail.html',
  callReport: '/reports/call.html',
  changePassword: '/account/change-password.html',
  confirmAccount: '/confirm-account.html',
  dashboard: '/dashboard.html',
  emailVerification: '/account/email-verification.html',
  forgotPassword: '/forgot-password.html',
  home: '/',
  interview: '/tests/aptitude.html',
  jobs: '/jobs.html',
  mfa: '/account/mfa.html',
  paymentCenter: '/payment/index.html',
  paymentMethods: '/payment/methods.html',
  paymentReport: '/reports/payment.html',
  paymentWithdrawal: '/payment/withdrawal.html',
  personalInfo: '/account/personal.html',
  phoneVerification: '/account/phone-verification.html',
  privacy: '/privacy.html',
  reports: '/reports.html',
  resetPassword: '/reset-password.html',
  scratchOff: '/tokens/scratch-off.html',
  signIn: '/sign-in.html',
  signUp: '/sign-up.html',
  slotMachine: '/tokens/slot-machine.html',
  summaryReport: '/reports/summary.html',
  tokenCenter: '/tokens/index.html',
  terms: '/terms.html',
  tests: '/tests/index.html',
  typingTest: '/tests/typing.html',
  verifySignIn: '/mfa.html',
  wheelOfPrizes: '/tokens/wheel.html',
  work: '/work.html',
  workSettings: '/account/work.html',
};

const url = (name, params) => {
  const queryString = params ?  new URLSearchParams(params).toString() : null;
  return URLS[name] + (queryString ? '?' + queryString : '');
};

module.exports = { 
  agentBucketName,
  companyBucketName,
  currency,
  publicCompanyBucketUrl,
  toCamelCase,
  toTitleCase,
  titleToWord,
  titleToDash,
  url,
};
