'use strict'

module.exports = {
  RANDOM : 1,
  AVAILABLE_AGENTS : 2,
  ONLINE_AGENTS : 3,
  PROJECTED_WAIT_TIME : 4,
  QUEUE_POSITION : 5,
  CALL_SCORE : 6,
  ids : {
    '1' : 'Random',
    '2' : 'Available Agents',
    '3' : 'Online Agents',
    '4' : 'Projected Wait Time',
    '5' : 'Queue Position',
    '6' : 'Call Score',
  },
};
