'use strict'

const BACKGROUND_CHECK = {
  taskId : 'd15c9c60-44bc-11eb-adeb-2d59e08a3cba',
  description : 'Complete your background check',
  priority : 0,
  url : 'https://trueme.goodhire.com/custom-link/280a59d9-121b-4203-a41c-dff122c5c42c',
};
const INTERVIEW = {
  taskId : 'd954dd10-44bc-11eb-b2fd-d958c6ec919d',
  description : 'Complete the interview',
  priority : 1000,
  url : '/interview.html',
};
const LANGUAGE_TEST = {
  taskId : '2d94b500-0ff4-11ec-abaa-9f38d2cd8237',
  description : 'Complete a language test',
  priority : 1000.1,
  url : '/user/languages.html',
};
const PERSONAL_INFORMATION = {
  taskId : 'fea46860-44bc-11eb-b163-8b97bd2e8da8',
  description : 'Update your personal information',
  priority : 1000.2,
  url : '/user/personal-settings.html',
};
const WORK_SETTINGS = {
  taskId : 'fff9bf30-44bc-11eb-bbae-a53557033005',
  description : 'Update your work settings',
  priority : 1000.3,
  url : '/user/work-settings.html',
};
const PAYMENT_SETTINGS = {
  taskId : '1c5cd080-671d-11ec-ab9b-c542f5e0af5f',
  description : 'Update your payment settings',
  priority : 1000.4,
  url : '/user/payment-settings.html',
};
const MFA = {
  taskId : '82db7000-0ea3-11ec-9e54-69afbee345c0',
  description : 'You must setup multi-factor authentication',
  priority : .1,
  url : '/qr.html',
};
const INITIAL_VERIFY_EMAIL = {
  taskId : '80043420-0ea3-11ec-bc85-73aa1b026acc',
  description : 'You must verify your email address',
  priority : 0,
  url : '/user/verification-code.html?attributeName=email',
};
const VERIFY_EMAIL = {
  taskId : '8182bdd0-0ea3-11ec-94e3-79a0279e3170',
  description : 'You must verify your email address',
  priority : .5,
  url : '/user/verification-code.html?attributeName=email',
};
const VERIFY_PHONE = {
  taskId : '8129c860-0ea3-11ec-8f52-6702d70cd333',
  description : 'You must verify your phone number',
  priority : .5,
  url : '/user/verification-code.html?attributeName=phone',
};
const SET_SCHEDULE = {
  taskId : '6fb2f510-70b6-11eb-beff-15f5a731840d',
  description : 'Set your schedule',
  priority : 2000,
  url : '/calendar.html',
};
const CHOOSE_COMPANIES = {
  taskId : '70e337b0-70b6-11eb-ab3e-41b9ec39e567',
  description : 'Choose companies to work for',
  priority : 1000,
  url : '/queue/view.html',
};
module.exports = {
  BACKGROUND_CHECK,
  CHOOSE_COMPANIES,
  INTERVIEW,
  INITIAL_VERIFY_EMAIL,
  LANGUAGE_TEST,
  MFA,
  PAYMENT_SETTINGS,
  PERSONAL_INFORMATION,
  SET_SCHEDULE,
  VERIFY_EMAIL,
  VERIFY_PHONE,
  WORK_SETTINGS,
};
