'use strict'

module.exports = {
  ONE_WAY : 1, //voicemail
  TWO_WAY : 2,
  CONFERENCE : 3,
  ids : {
    '1' : 'One-Way',
    '2' : 'Two-Way',
    '3' : 'Conference',
  },
};
