import React from 'react';
import TextInput from '../components/TextInput';
import { NavLink } from 'react-router-dom';
import { url } from 'agents-remotely-commons/src/formatter';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFetch from '../hooks/useFetch';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { formatErrorMessage } from '../helper';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const SignInForm = () => {
  const {apiFetch, data: user, error, loading} = useFetch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const { signIn } = useAuth();

  React.useEffect(() => {
    if(user) {
      if(user.accessToken) {
        signIn(user);
        navigate(url('dashboard'));
      } else {
        console.log(user);
        navigate(url('verifySignIn', user));
      }
    }
  }, [user]);

  return (
    <div className="sign-in-form content__form">
      <Formik
        validateOnChange={false}
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string().email('Email is invalid').required('Email is required'),
          password: Yup.string()
            .required('Password is required')
            .matches(/^.{8,}$/, 'Password is invalid'),
        })}
        onSubmit={async (values) => {
          const token = await executeRecaptcha();
          apiFetch('POST', '/agents/sessions', {...values, recaptchaResponse: token});
        }}
      >
        {props => (
          <Form className="form sign-in">
            {error ?
              <div className="form__error">
                {formatErrorMessage(error)}
                {error === 'Please confirm your phone before signing in.' ? <>&nbsp;<NavLink className="form__link confirm-account__resend" to={url('confirmAccount', {email: props.values.email, resend: 'true'})}>Click here</NavLink> if you didn't receive an SMS.</> : ''}
              </div>
            : ''}
            <div className="form__line">
              <TextInput
                formName="sign-in"
                label="Email"
                name="email"
                type="email"
                autoComplete="email"
                autoCorrect="off"
                spellCheck="false"
                required="required"
              />
            </div>
            <div className="form__line">
              <TextInput
                formName="sign-in"
                label="Password"
                name="password"
                type="password"
                autoComplete="current-password"
                autoCorrect="off"
                spellCheck="false"
                pattern="^.{8,}$"
                required="required"
              />
            </div>
            <div className="form__line"><NavLink className="form__link" to={url('forgotPassword')}>Forgot your password?</NavLink></div>
            <div className="form__line">
              {loading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Sign In</button>}
            </div>
            <div className="form__line">Need an account? <NavLink className="form__link" to={url('signUp')}>Sign up</NavLink></div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignInForm;
