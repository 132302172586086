import React, { useState } from 'react';
import SelectInput from './SelectInput';
import TextInput from './TextInput';
import PayoneerForm from './PayoneerForm';
import PayPalForm from './PayPalForm';
import WiseForm from './WiseForm';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { paymentMethod } from 'agents-remotely-commons/src/model';
import useFetch from '../hooks/useFetch';
import {formatErrorMessage} from '../helper';
import { currency } from 'agents-remotely-commons/src/model';
import { useNavigate } from 'react-router-dom';

const PaymentMethodForm = (props) => {
  const {apiFetch: addPaymentMethodFetch, data: addedPaymentMethod, error: addedPaymentMethodError, loading: addPaymentMethodLoading} = useFetch();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const navigate = useNavigate();

  const paymentMethods = [{value: ''}];
  for(const key in paymentMethod.ids) {
    paymentMethods.push({value: parseInt(key), label: paymentMethod.ids[key]});
  }

  if(addedPaymentMethod) {
    console.log('added');
    const redirect = new URLSearchParams(window.location.search).get('redirect');
    console.log(redirect);
    if(redirect) {
      navigate(redirect);
    } else {
      props.updatePaymentMethod();
    }
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        nickname: '',
        typeId: '',
        payoneerId: '',
        email: '',
        currencyId: currency.USD,
        accountHolderName: '',
        paymentTypeId: ''
      }}
      validationSchema={Yup.object({
        nickname: Yup.string()
          .required('Nickname is required')
          .max(50, 'Nickname cannot be more than 50 characters'),
        typeId: Yup.number().integer()
          .required('Type is required')
          .oneOf(Object.keys(paymentMethod.ids).map(item => parseInt(item)), 'Type is invalid'),
        payoneerId: Yup.string()
          .email('Email is invalid')
          .when('typeId', {
            is: paymentMethod.PAYONEER,
            then: (schema) => schema.required('Email is required'),
           }),
        email: Yup.string()
          .email('Email is invalid')
          .when('typeId', {
            is: paymentMethod.PAYPAL,
            then: (schema) => schema.required('Email is required'),
           }),
        currencyId: Yup.number()
          .integer('Currency must be an integer')
          .when('typeId', {
            is: (value) => value === paymentMethod.PAYPAL || value === paymentMethod.PAYONEER,
            then: (schema) => schema.required('Currency is required'),
           }),
        accountHolderName: Yup.string()
          .when('typeId', {
            is: paymentMethod.WISE,
            then: (schema) => schema.required('Account holder name is required').matches(/^([A-z0-9À-ž -]){2,}$/, 'Account holder name is invalid'),
           }),
      })}
      onSubmit={(values) => {
        addPaymentMethodFetch('POST', '/agents/{user.id}/paymentMethods', values);
      }}
    >
      {({ setFieldValue , isSubmitting }) => (
        <Form className="form payment-settings">
          {addedPaymentMethodError ? <div className="form__error">{formatErrorMessage(addedPaymentMethodError)}</div> : ''}
          <div className="form__line">
            <TextInput
              formName="payment-settings"
              label="Account Nickname"
              name="nickname"
              type="text"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
              required="required"
            />
          </div>
          <div className="form__line">
            <SelectInput formName="payment-settings" label="Payment Method" name="typeId" autoComplete="off" options={paymentMethods} onChange={(e) => {setSelectedPaymentMethod(e)}}/>
          </div>
          {selectedPaymentMethod ? 
              parseInt(selectedPaymentMethod) === paymentMethod.PAYPAL ? <PayPalForm/> : parseInt(selectedPaymentMethod) === paymentMethod.PAYONEER ? <PayoneerForm/> : <WiseForm setFieldValue={setFieldValue}/>
            : ''
          }
          <div className="form__line">
            {addPaymentMethodLoading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Add Payment Method</button>}
          </div>
        </Form>
      )}
  </Formik>
  );
};

export default PaymentMethodForm;
