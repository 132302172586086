import React from 'react';
import Jobs from '../components/Jobs';
import useFetch from '../hooks/useFetch';
import {formatErrorMessage} from '../helper';

const Work = () => {
  const {apiFetch: activeJobsFetch, data: activeJobs, error: activeJobsError, loading: activeJobsLoading, setData: setActiveJobs} = useFetch();
  const {apiFetch: availableJobsFetch, data: availableJobs, error: availableJobsError, loading: availableJobsLoading, setData: setAvailableJobs} = useFetch();
  
  React.useEffect(() => {
    activeJobsFetch('GET', '/agents/{user.id}/jobs', {status: 'active'});
    availableJobsFetch('GET', '/jobs');
  }, []);

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Jobs</h1>
      </div>
      <div className="content__container">
        {activeJobsLoading ? <div className="processing"></div> : ''}
        {activeJobs ? <Jobs jobs={activeJobs}/> : ''}
        {activeJobsError ? <div className="error-message">{formatErrorMessage(activeJobsError)}</div> : ''}
        {availableJobsLoading ? <div className="processing"></div> : ''}
        {availableJobs ? <Jobs jobs={availableJobs}/> : ''}
        {availableJobsError ? <div className="error-message">{formatErrorMessage(availableJobsError)}</div> : ''}
      </div>
    </section>
  );
}

export default Work;
