import SelectInput from './SelectInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Dates = ({startDate, setStartDate, endDate, setEndDate}) => {
  const now = new Date();

  const dates = [
    {value: 'TODAY', label: 'Today', dates: () => {
      const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      end.setDate(end.getDate() + 1);
      return {start, end};
    }},
    {value: 'YESTERDAY', label: 'Yesterday', dates: () => {
      const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      start.setDate(start.getDate() - 1);
      const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      return {start, end};
    }},
    {value: 'THIS_WEEK', label: 'This Week (Mon-Sun)', dates: () => {
      const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      start.setDate(start.getDate() - (start.getDay() - 1));
      const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      end.setDate(end.getDate() + 1);
      return {start, end};
    }},
    {value: 'LAST_WEEK', label: 'Last Week (Mon-Sun)', dates: () => {
      const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      start.setDate(start.getDate() - (start.getDay() - 1) - 7);
      const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      end.setDate(end.getDate() - (start.getDay() - 1));
      return {start, end};
    }},
    {value: 'LAST_7_DAYS', label: 'Last 7 Days', dates: () => {
      const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      start.setDate(start.getDate() - 7);
      const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      end.setDate(end.getDate() + 1);
      return {start, end};
    }},
    {value: 'LAST_14_DAYS', label: 'Last 14 Days', dates: () => {
      const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      start.setDate(start.getDate() - 14);
      const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      end.setDate(end.getDate() + 1);
      return {start, end};
    }},
    {value: 'THIS_MONTH', label: 'This Month', dates: () => {
      const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      start.setDate(1);
      const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      end.setDate(end.getDate() + 1);
      return {start, end};
    }},
    {value: 'LAST_30_DAYS', label: 'Last 30 Days', dates: () => {
      const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      start.setDate(start.getDate() - 30);
      const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      end.setDate(end.getDate() + 1);
      return {start, end};
    }},
    {value: 'LAST_MONTH', label: 'Last Month', dates: () => {
      const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      start.setMonth(start.getMonth() - 1);
      start.setDate(1);
      const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      end.setDate(1);
      return {start, end};
    }},
  ];

  const quickToDates = (setStartDate, setEndDate, val) => {
    for(const item of dates) {
      if(val === item.value) {
        const {start, end} = item.dates();
        setStartDate(start);
        setEndDate(end);
      }
    }
  };

  const quickChange = (val) => {
    quickToDates(setStartDate, setEndDate, val);
  };

  return <div className="report-header__line">
    <label className="report-header__label">Start Date
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          if(date > endDate) {
            setEndDate(date);
          }
        }}
        startDate={startDate}
        endDate={endDate}
        showTimeSelect
        selectsStart
        dateFormat="yyyy-MM-dd HH:mm"
        className="report-header__calendar"
        dayClassName={date => 'calendar__day'}
      />
    </label>
    <label className="report-header__label">End Date
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        showTimeSelect
        selectsEnd
        dateFormat="yyyy-MM-dd HH:mm"
        className="report-header__calendar"
        dayClassName={date => 'calendar__day'}
      />
    </label>
    <div>
      <SelectInput formName="report" label="Quick Dates" name="date" options={dates} onChange={quickChange}/>
    </div>
  </div>
}

export default Dates;
