import React from 'react';
import GeneralInfo from '../../components/GeneralInfo';
import WorkSettingsForm from '../../components/WorkSettingsForm';
import useFetch from '../../hooks/useFetch';
import {formatErrorMessage} from '../../helper';

const WorkSettings = () => {
  const {apiFetch, data: agent, error, loading} = useFetch();

  React.useEffect(() => {
    apiFetch('GET', '/agents/{user.id}', {
      select: ['resumeUrl', 'industries', 'jobAttributes'],
    });
  }, []);

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Experience & Interests</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Why we need this',
            content: [
              {text: 'The information requested is essential in facilitating a seamless partnership between you and potential partnering companies. By providing details regarding your interests and experience, we can present you with tailored opportunities that align with your skill set and expertise.'},
            ],
          },
          {
            text: 'Experience & Interests',
            content: [
              {text: 'Kindly indicate your years of experience in the relevant field, even if it is zero, as zero highlights your interest in the position.'},
            ],
          },
        ]}/>
        {loading ? <div className="processing"></div> : ''}
        {agent ? 
          <div className="work-settings-form content__form">
            <WorkSettingsForm agent={agent}/>
            <div className="work-settings__error is-hidden"/>
          </div>
          : ''
        }
        {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
      </div>
    </section>
  );
}

export default WorkSettings;
