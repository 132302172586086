import React from 'react';
import useFetch from '../../hooks/useFetch';
import { formatErrorMessage, getQueryStringParameter } from '../../helper';
import { AgGridReact } from 'ag-grid-react';
import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { callDetailType } from 'agents-remotely-commons/src/model';

const CallDetailReport = () => {
  const {apiFetch, data, error, loading} = useFetch();

  const submit = () => {
    apiFetch('GET', '/reports/callDetail', {
      callId: getQueryStringParameter('callId'),
    });
  };

  const msToTime = (duration) => {
    let milliseconds = Math.floor(duration % 1000),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;

    return hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
  }

  const callDetailFormatter = (item) => {
    if(item.details) {
      if('duration' in item.details) {
        return msToTime(item.details.duration);
      }
    }
    return null;
  };

  React.useEffect(() => {
    submit();
  }, []);

  const getRowData = () => {
    const rows = [];
    for(const item of data) {
      rows.push({callId: item.callId, type: item.callDetailType.description, duration: callDetailFormatter(item), createdAt: new Date(item.createdAt)});
    }
    return rows;
  };

  const renderAudio = (data) => {
    const item = data.find(item => item.callDetailType.id === callDetailType.CALL_RECORDING_ADDED);
    if(item) {
      return <audio controls>
        <source src={item.details.url} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>;
    }
  }

  const getColumnData = () => {
    return [
      {field: 'callId'},
      {field: 'type'},
      {field: 'duration'},
      {field: 'createdAt'},
    ];
  }

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Call Details</h1>
      </div>
      <div className="content__main content__main--padded">
        {loading ? <div className="processing"></div> : ''}
        {data ? 
          (data.length > 0 ? 
          <>
            {renderAudio(data)}
             <div className="report-data-grid ag-theme-alpine">
               <AgGridReact
                 rowData={getRowData()}
                 columnDefs={getColumnData()}
                 onFirstDataRendered={onFirstDataRendered}
                 onGridSizeChanged={onGridSizeChanged}>
               </AgGridReact>
            </div>
          </>
            : <div className="info-message">No results</div>)
          : ''
        }
        {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
      </div>
    </section>
  )
}

export default CallDetailReport;
