import React, { createContext, useContext, useState, useRef } from 'react';
import GraphQLSubscriptionClient from '../graphql-client';
import { useAuth } from './useAuth';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const { user, reauthorize } = useAuth();
  const [connected, setConnected] = useState(false);
  const [connectionError, setConnectionError] = useState(null);
  const [error, setError] = useState(null);
  const client = useRef(null);

  React.useEffect(() => {
    if(user) {
      const socket = GraphQLSubscriptionClient({
        host: process.env.REACT_APP_GRAPHQL_URL,
        authToken: user.accessToken,
        reauthorize: async () => {
          const updatedUser = await reauthorize();
          if(updatedUser) {
            return updatedUser.accessToken;
          }
        },
        onConnect: (data) => {
          setConnected(true);
          setConnectionError(null);
        },
        onFailure: (data) => {
          setConnected(false);
          setConnectionError(data);
        },
        onError: (data) => {
          setError(data);
        },
        onClose: (data) => {
          setConnected(false);
        },
      });

      client.current = socket;

      return () => {
        socket.close();
      };
    }
  }, [user]);

  const ws = {connected, connectionError, error, client: client?.current};
  return (
    <WebSocketContext.Provider value={ws}>
        {children}
    </WebSocketContext.Provider>
  )
}

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
