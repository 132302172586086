'use strict'

module.exports = {
  AF_ZA : 1,
  AR_AE : 2,
  AR_SA : 3,
  CY_GB : 4,
  DA_DK : 5,
  DE_CH : 6,
  DE_DE : 7,
  EN_AB : 8,
  EN_AU : 9,
  EN_GB : 10,
  EN_IE : 11,
  EN_IN : 12,
  EN_US : 13,
  EN_WL : 14,
  ES_ES : 15,
  ES_US : 16,
  FA_IR : 17,
  FR_CA : 18,
  FR_FR : 19,
  GA_IE : 20,
  GD_GB : 21,
  HE_IL : 22,
  HI_IN : 23,
  ID_ID : 24,
  IT_IT : 25,
  JA_JP : 26,
  KO_KR : 27,
  MS_MY : 28,
  NL_NL : 29,
  PT_BR : 30,
  PT_PT : 31,
  RU_RU : 32,
  TA_IN : 33,
  TE_IN : 34,
  TR_TR : 35,
  ZH_CN : 36,
  ids : {
    '1' : 'af-ZA',
    '2' : 'ar-AE',
    '3' : 'ar-SA',
    '4' : 'cy-GB',
    '5' : 'da-DK',
    '6' : 'de-CH',
    '7' : 'de-DE',
    '8' : 'en-AB',
    '9' : 'en-AU',
    '10' : 'en-GB',
    '11' : 'en-IE',
    '12' : 'en-IN',
    '13' : 'en-US',
    '14' : 'en-WL',
    '15' : 'es-ES',
    '16' : 'es-US',
    '17' : 'fa-IR',
    '18' : 'fr-CA',
    '19' : 'fr-FR',
    '20' : 'ga-IE',
    '21' : 'gd-GB',
    '22' : 'he-IL',
    '23' : 'hi-IN',
    '24' : 'id-ID',
    '25' : 'it-IT',
    '26' : 'ja-JP',
    '27' : 'ko-KR',
    '28' : 'ms-MY',
    '29' : 'nl-NL',
    '30' : 'pt-BR',
    '31' : 'pt-PT',
    '32' : 'ru-RU',
    '33' : 'ta-IN',
    '34' : 'te-IN',
    '35' : 'tr-TR',
    '36' : 'zh-CN',
  },
};
