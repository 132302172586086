import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import './SlotMachine.scss';

const RepeatButton = (props) => {
  return (
    <button className="repeat" onClick={props.onClick}>Play</button>
  );
}

const WinningSound = () => {
  return (
  <audio autoPlay="autoplay" className="winPlayer" preload="false">
    <source src="/audio/slotwin.mp3"/>
  </audio>  
  );
}
  
const Spinner = forwardRef((props, ref) => {
  const [position, setPosition] = useState();
  const totalSymbols = 9;
  const iconHeight = 180;
  const multiplier = 1; //DONT CHANGE!
  const delay = 100;
  let timer;
  let start;
  const speed = iconHeight * multiplier;    

  useImperativeHandle(ref, () => ({
    reset(endingPosition) {
      if (timer) { 
        clearInterval(timer); 
      }  

      start = setStartPosition();

      setPosition(start);

      let timeRemaining = props.timer;

      timer = setInterval(() => {
        if (timeRemaining <= 0 && (endingPosition === null || getSymbolFromPosition(timeRemaining) === endingPosition)) { 
          clearInterval(timer);        
          props.onFinish(getSymbolFromPosition(timeRemaining));
        } else {
          moveBackground();
        }      
        timeRemaining -= delay; 
      }, delay);      
    }
  }));

  const setStartPosition = () => {
    return ((Math.floor((Math.random()*totalSymbols))) * iconHeight)*-1;
  }

  const moveBackground = () => {
    setPosition(current => current - speed);
  }

  const getSymbolFromPosition = (timeRemaining) => {
    const maxPosition = (iconHeight * (totalSymbols-1)*-1);
    let moved = ((props.timer - timeRemaining) / delay) * multiplier
    let startPosition = start;
    let currentPosition = startPosition;    

    for (let i = 0; i < moved; i++) {              
      currentPosition -= iconHeight;

      if (currentPosition < maxPosition) {
        currentPosition = 0;
      }      
    }

    return Math.abs(currentPosition / iconHeight);
  }

  return (            
    <div 
      style={{backgroundPosition: '0px ' + position + 'px'}}
      className={`icons`}>          
      <div className="gradient-fade"></div>
    </div>
  )
});

const SlotMachine = (props) => {
  const [winner, setWinner] = useState(null);
  const matches = useRef([]);
  const child1 = useRef();
  const child2 = useRef();
  const child3 = useRef();
  
  const handleClick = () => { 
    setWinner(false);
    document.querySelector('.player').play();
    matches.current = [];
    setTimeout(() => {
      child1.current.reset(parseInt(Math.random()*9));
      child2.current.reset(parseInt(Math.random()*9));
      child3.current.reset(parseInt(Math.random()*9));
    }, 1900);
  }

  const payouts = [
    '90',  //bell
    '75', //watermelon
    '100', //diamond
    '95', //7
    '85', //star
    '65', //orange
    'bar', //bar
    '70', //grape
    '60', //cherry
  ];

  const finishHandler = (value) => {
    console.log(value);
    matches.current.push(value);  
    let results = false;

    if (matches.current.length === 3) {
      if(matches.current[0] === matches.current[1]) {
        if(matches.current[0] === matches.current[2]) {
          results = payouts[matches.current[0]];
        } else {
          results = 40;
        }
      } else if(matches.current[0] === matches.current[2] || matches.current[1] === matches.current[2]) {
        results = 40;
      };
      //let results = matches.current.every(match => match === first)
      setWinner(results);
    }
  }

  let repeatButton = null;
  let winningSound = null;

  if (winner) {
    winningSound = <WinningSound />
  } else {
  }
    repeatButton = <RepeatButton onClick={handleClick} />
  
  return (
    <div className="slot token-game">
      {winningSound}
      <audio className="player" preload="false">
        <source src="/audio/slotpull.mp3"/>
      </audio>  
      <div className="spinner-display-border-outer token-game__board">
        <div className="spinner-display-border">
          <div className="spinner-display">
            <div className={`spinner-container`}>
              <Spinner onFinish={finishHandler} ref={child1} timer={2000} />
              <Spinner onFinish={finishHandler} ref={child2} timer={2400} />
              <Spinner onFinish={finishHandler} ref={child3} timer={2800} />
            </div>
          </div>
        </div>
      </div>
      <div className="legend token-game__legend">
        Win up to $1 per token.
        <div className="legend-line">
          <span className="legend-icon legend-diamond"/>
          <span className="legend-icon legend-diamond"/>
          <span className="legend-icon legend-diamond"/>
          <span className="legend-win">100</span>
        </div>
        <div className="legend-line">
          <span className="legend-icon legend-7"/>
          <span className="legend-icon legend-7"/>
          <span className="legend-icon legend-7"/>
          <span className="legend-win">95</span>
        </div>
        <div className="legend-line">
          <span className="legend-icon legend-bell"/>
          <span className="legend-icon legend-bell"/>
          <span className="legend-icon legend-bell"/>
          <span className="legend-win">90</span>
        </div>
        <div className="legend-line">
          <span className="legend-icon legend-star"/>
          <span className="legend-icon legend-star"/>
          <span className="legend-icon legend-star"/>
          <span className="legend-win">85</span>
        </div>
        <div className="legend-line">
          <span className="legend-icon legend-bar"/>
          <span className="legend-icon legend-bar"/>
          <span className="legend-icon legend-bar"/>
          <span className="legend-win">80</span>
        </div>
        <div className="legend-line">
          <span className="legend-icon legend-watermelon"/>
          <span className="legend-icon legend-watermelon"/>
          <span className="legend-icon legend-watermelon"/>
          <span className="legend-win">75</span>
        </div>
        <div className="legend-line">
          <span className="legend-icon legend-grape"/>
          <span className="legend-icon legend-grape"/>
          <span className="legend-icon legend-grape"/>
          <span className="legend-win">70</span>
        </div>
        <div className="legend-line">
          <span className="legend-icon legend-orange"/>
          <span className="legend-icon legend-orange"/>
          <span className="legend-icon legend-orange"/>
          <span className="legend-win">65</span>
        </div>
        <div className="legend-line">
          <span className="legend-icon legend-cherry"/>
          <span className="legend-icon legend-cherry"/>
          <span className="legend-icon legend-cherry"/>
          <span className="legend-win">60</span>
        </div>
        <div className="legend-line">
          <span>Any Two Match</span>
          <span className="legend-win">40</span>
        </div>
      </div>
      <div className="token-game__controls">
        {repeatButton}          
      </div>
    </div>
  );
}  

export default SlotMachine;
