'use strict'

const ids = {
  '1' : 'Offline',
  '2' : 'Online',
  '3' : 'Pending Work',
  '4' : 'Busy',
  '5' : 'Actively Working',
  '6' : 'Quit',
  '7' : 'Removed',
  '8' : 'Suspended',
};

const values = {};
for(const id in ids) {
  values[ids[id]] = parseInt(id);
}

module.exports = {
  OFFLINE : 1,
  ONLINE : 2,
  PENDING_WORK : 3,
  BUSY : 4,
  ACTIVELY_WORKING : 5,
  QUIT : 6,
  REMOVED : 7,
  SUSPENDED : 8,
  CAN_WORK : [1, 2, 3, 4, 5],
  WORKING : [2, 3, 4, 5],
  ids : ids,
  values : values,
};
