'use strict'

module.exports = {
  WORK : 1,
  EMAIL_NOTIFICATION : 2,
  SMS_NOTIFICATION : 3,
  ids : {
    '1' : 'Work',
    '2' : 'Email Notificaiton',
    '3' : 'SMS Notificaiton',
  },
};
