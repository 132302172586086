import { createSlice } from '@reduxjs/toolkit'

export const moneySlice = createSlice({
  name: 'money',
  initialState: {
    value: '',
  },
  reducers: {
    update: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { update, increment, decrement } = moneySlice.actions;

export default moneySlice.reducer;
