import React from 'react';
import TextInput from './TextInput';

const PayPalForm = (props) => {
  return (
    <div className="paypal-form">
      <div className="form__line">
        <TextInput
          formName="paypal"
          label="PayPal Email"
          name="email"
          type="email"
          autoComplete="email"
          autoCorrect="off"
          spellCheck="false"
          required="required"
        />
      </div>
    </div>
  );
};

export default PayPalForm;
