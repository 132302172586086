import React from 'react';
import { useField } from 'formik';
import { titleToDash } from 'agents-remotely-commons/src/formatter';

const CheckBoxInput = ({ label, formName, ...props }) => {
 // React treats radios and checkbox inputs differently other input types, select, and textarea.
 // Formik does this too! When you specify `type` to useField(), it will
 // return the correct bag of props for you -- a `checked` prop will be included
 // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
 const [field, meta] = useField({ ...props, type: 'checkbox' });
 const classPrefix = formName + '__' + titleToDash(field.name);
 return (
   <div>
     <label className={'form__label ' + classPrefix + '-label'}>
       <input className={'form__checkbox ' + classPrefix} type="checkbox" {...field} {...props} />
       {label}
     </label>
     {meta.touched && meta.error ? (
       <div className={'form__error ' + classPrefix + '-error'}>{meta.error}</div>
     ) : null}
   </div>
 );
};

export default CheckBoxInput;
