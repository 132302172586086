import React from 'react';
import GeneralInfo from '../components/GeneralInfo';
import HiddenInput from '../components/HiddenInput';
import TextInput from '../components/TextInput';
import { url } from 'agents-remotely-commons/src/formatter';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { getQueryStringParameter, formatErrorMessage } from '../helper';

const ResetPassword = () => {
  const {apiFetch, data: user, error, loading} = useFetch();
  const navigate = useNavigate();

  if(user) {
    navigate(url('signIn'));
  }
  return (
    <section className="content content__no-auth">
      <div className="title">
        <h1>Reset password</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Reset Help',
            content: [
              {text: 'Enter the confirmation code emailed to you and a new password.'},
              {text: 'Passwords ar a minimum of 8 characters and must include numeric, special, lower and uppercase characters.'},
            ],
          },
        ]}/>
        <div className="reset-password-form content__form">
          <Formik
            validateOnChange={false}
            initialValues={{
              email: getQueryStringParameter('email'),
              confirmationCode: '',
              password: '',
            }}
            validationSchema={Yup.object({
              email: Yup.string().email('Email is invalid').required('Email is required'),
              password: Yup.string()
                .required('Password is required')
                .matches(/^.{8,}$/, 'Password is invalid'),
              confirmationCode: Yup.string()
                .required('Confirmation code is required')
                .matches(/^[0-9]+$/, 'Confirmation code must be only digits')
                .min(6, 'Confirmation code must be exactly 6 digits')
                .max(6, 'Confirmation code must be exactly 6 digits'),
            })}
            onSubmit={(values) => {
              apiFetch('PUT', '/agents/password', values);
            }}
          >
            <Form className="form reset-password">
              {error ? <div className="form__error">{formatErrorMessage(error)}</div> : ''}
              <div className="form__line">
                <HiddenInput
                  formName="reset-password"
                  name="email"
                  type="hidden"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                  required="required"
                />
                <TextInput
                  formName="reset-password"
                  label="Confirmation Code"
                  name="confirmationCode"
                  type="tel"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                  required="required"
                />
              </div>
              <div className="form__line">
                <TextInput
                  formName="reset-password"
                  label="New Password"
                  name="password"
                  type="password"
                  autoFocus={true}
                  autoComplete="new-password"
                  autoCorrect="off"
                  spellCheck="false"
                  pattern="^.{8,}$"
                  required="required"
                />
              </div>
              <div className="form__line">
                {loading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Change Password</button>}
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
