import React from 'react';
import Select from 'react-select'
import { useField } from 'formik';
import { titleToDash } from 'agents-remotely-commons/src/formatter';

const SelectInput = ({ label, formName, ...props }) => {
  const styles = {
    menu: base => ({
      ...base,
      marginTop: 0,
    }),
    control: base => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      maxHeight: '35px',
    }),
  };

  const [field, meta, { setValue, setTouched }] = useField(props);
  const classPrefix = formName + '__' + titleToDash(field.name);
  if('value' in props) {
    field.value = props.value;
  }

  const onChange = ({ value }) => {
    if(props.onChange) {
      props.onChange(value);
    }
    setValue(value);
  };

  return (
    <>
      {label ? <label className={'form__label ' + classPrefix + '-label'} htmlFor={props.id || props.name}>{label}</label> : ''}
      <Select className={'form__select ' + classPrefix} {...field} {...props} onChange={onChange} onBlur={setTouched} value={props.options.find((option) => option.value === field.value)} styles={styles}/>
      {meta.touched && meta.error ? (
        <div className={'form__error ' + classPrefix + '-error'}>{meta.error}</div>
      ) : null}
    </>
  );
};

export default SelectInput;
