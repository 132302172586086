import React, {useState, useRef} from 'react';
import useFetch from '../hooks/useFetch';
import { useAuth } from '../hooks/useAuth';
import { VonageDevice } from '../vonage-device';

const PhoneTest = ({children, showWrapper}) => {
  const states = {
    NEEDS_SYSTEM_CHECK: 1,
    PERFORMING_SYSTEM_CHECK: 2,
    NEEDS_AUDIO_CHECK: 3,
    PERFORMING_AUDIO_CHECK: 4,
    FINISHED_AUDIO_CHECK: 5,
    COMPLETED_CHECKS: 6,
  };

  const device = useRef();
  const { user } = useAuth();
  const [testState, setTestState] = useState(states.NEEDS_SYSTEM_CHECK);
  const [content, setContent] = useState(null);
  const [testError, setTestError] = useState(null);
  const {apiFetch: roleFetch, data: role, error: roleError, loading: roleLoading} = useFetch();
  const {apiFetch: agentStatusFetch, data: agentStatus, error: agentStatusError, loading: agentStatusLoading} = useFetch();

  React.useEffect(() => {
    if(agentStatus) {
      localStorage.setItem('agentOnline', 'true');
      if((localStorage.getItem('settingsChecked')||0) > new Date().getTime()) {
        setTestState(states.COMPLETED_CHECKS);
      } else {
        setTestState(states.NEEDS_AUDIO_CHECK);
      }
    }
  }, [agentStatus]);

  const updateSystemTestError = (message) => {
    setTestState(states.NEEDS_SYSTEM_CHECK);
    setTestError(message);
  };

  const finishAudioTest = () => {
    setTestState(states.FINISHED_AUDIO_CHECK);
    device.current.sendDigits('#');
  };

  const testInternetSpeed = () => {
    let startTime;

    const imageUrl = '/images/testbandwidth.jpg?epoch=' + new Date().getTime();
    const downloadSize = 3954901; //bytes
    const downloadImgSrc = new Image();

    downloadImgSrc.onload = () => {
      const endTime = new Date().getTime();
      const timeDuration = (endTime - startTime) / 1000;
      const speedInMbps = (downloadSize / timeDuration / 1024 / 1024).toFixed(2);

      roleFetch('POST', '/agents/{user.id}/roles/workableAgent', {bandwidth: speedInMbps});
    };
    startTime = new Date().getTime();
    downloadImgSrc.src = imageUrl;
  };

  const locationShared = async (geo) => {
    try {
      await navigator.mediaDevices.getUserMedia({video: false, audio: true})
      testInternetSpeed();
    } catch(err) {
      console.log(err);
      updateSystemTestError('You must allow your browser to use your microphone. Please allow and refresh the page.');
    }
  };

  const testAudioSettings = async () => {
    setTestState(states.PERFORMING_AUDIO_CHECK);
    let goodDisconnect = true;
    device.current = new VonageDevice({
      metaInfo: {
        component: {
          id : 'a007f800-fd06-11eb-bbd3-73d7d46288d6',
        },
      },
    });

    device.current.onReady = () => {
      device.current.connect({
        componentId : device.current.metaInfo.component.id,
        companyId: 'aa0d7f10-bf55-11ed-9299-510457a9f763',
        agentId : user.userId,
      });
    }
    device.current.onConnect = () => {
      goodDisconnect = true;
      setContent(device.current.metaInfo.component.content);
    };
    //disconnect gets called before error
    device.current.onDisconnect = () => {
      if(goodDisconnect) {
        const expires = new Date();
        expires.setTime(expires.getTime() + (.5*24*60*60*1000));
        localStorage.setItem('settingsChecked', expires.getTime());
        setTestState(states.COMPLETED_CHECKS);
      } else {
        setTestError('Failed audio check, please refresh and try again');
      }
      setContent(null);
      device.current.destroy();
    };
    device.current.onError = async () => {
      //handle {"code":31205,"message":"JWT Token Expired"}
      goodDisconnect = false;
      roleFetch('POST', '/agents/{user.id}/roles/workableAgent', {softphoneError : 'Could not connect'});
    };

    device.current.onAuthorization = (authorizeResponse) => {
      if(authorizeResponse.error) {
        console.log(authorizeResponse.error);
        updateSystemTestError('Could not fetch token, see console.log');
      }
    };
    device.current.authorize({
      closeProtection : true,
    });
  };
          
  const testSystemSettings = async () => {
    setTestState(states.PERFORMING_SYSTEM_CHECK);
    try {
      const locationResults = await navigator.permissions.query({name:'geolocation'});
      if(locationResults.state === 'granted'  || locationResults.state === 'prompt') {
        navigator.geolocation.getCurrentPosition(locationShared, (err) => {
          console.log(err);
          updateSystemTestError('You must enable sharing of location. If you are using a Mac make sure location sharing is enabled in your system preferences. Please refresh the page and select allow when prompted.');
        }, {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        });
      } else if(locationResults.state === 'denied') {
        updateSystemTestError('You must enable sharing of location. Please refresh the page and select allow when prompted.');
      }
    } catch(err) {
      console.log(err);
      updateSystemTestError('You must enable sharing of location. Please refresh the page and select allow when prompted.');
    }
  };

  const getTestState = () => {
    if(content) {
      return <>
        <div className="general-section__line">
          {content}
        </div>
        {testState === states.FINISHED_AUDIO_CHECK ? <div className="processing"></div> : <button className="button" onClick={finishAudioTest}>Finish</button>}
      </>;
    } else if(roleLoading || agentStatusLoading) {
      return <div className="processing"></div>;
    } else if(roleError) {
      return <div className="error-message">{roleError}</div> ;
    } else if(testError) {
      return <div className="error-message">{testError}</div> ;
    } else if(agentStatusError) {
      return <div className="error-message">{agentStatusError}</div> ;
    } else if(testState === states.COMPLETED_CHECKS) {
      return <>
        {showWrapper ? <>
          <div className="general-section__line">Feel free to test your audio settings again until you are satisfied.</div>
          <button className="language-test__start button--quaternary" onClick={testAudioSettings}>Retest Audio Settings</button>
        </> : ''}
        {children}
      </>;
    } else {
      if(role) {
        if(!agentStatus) {
          agentStatusFetch('PUT', '/agents/{user.id}/online');
        }
        if(testState === states.NEEDS_AUDIO_CHECK) {
          return <>
            <div className="general-section__line">Let's verify your speakers and microphone are properly working.</div>
            <button className="language-test__start button--quaternary" onClick={testAudioSettings}>Test Audio Settings</button>
          </>
        } else if(testState === states.PERFORMING_AUDIO_CHECK || testState === states.FINISHED_AUDIO_CHECK) {
          return <div className="processing"></div>;
        }
      } else if(testState === states.PERFORMING_SYSTEM_CHECK) {
        return <div className="processing"></div>;
      } else {
        return <>
          <div className="general-section__line">Before you can start we need to run a few tests on your system.</div>
          <div className="general-section__line">Please click the button below to continue.</div>
          <div className="general-section__line">It may take up to 30 seconds to complete.</div>
          <button className="test-call__settings button--quaternary" onClick={testSystemSettings}>Test System Settings</button>
        </>;
      }
    }
  };

  return getTestState();
}

export default PhoneTest;
