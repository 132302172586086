'use strict'

module.exports = {
  ACTIVE : 1,
  PAUSED : 2,
  REMOVED : 3,
  ids : {
    '1' : 'Active',
    '2' : 'Paused',
    '3' : 'Removed',
  },
};
