import React from 'react';
import './KeypadButton.scss';

const KeypadButton = ({ children, handleClick }) => {
  return (
    <button className="keypad-button" onClick={handleClick}>
      {children}
    </button>
  );
};

export default KeypadButton;
