import React, {useState} from 'react';
import TextInput from '../components/TextInput';
import SelectInput from './SelectInput';
import CheckBoxInput from './CheckBoxInput';
import Tooltip from './Tooltip';
import DowMultiRange from './DowMultiRange';
import { timeZone } from 'agents-remotely-commons/src/model';
import { NavLink } from 'react-router-dom';
import { url } from 'agents-remotely-commons/src/formatter';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { formatErrorMessage } from '../helper';

const AvailabilityForm = ({agent}) => {
  const {apiFetch, data: user, error, loading} = useFetch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if(user) {
      navigate(new URLSearchParams(window.location.search).get('redirect') || url('dashboard'));
    }
  }, [user]);

  const timeZones = [{value: ''}];
  for(const key in timeZone.ids) {
    timeZones.push({value: parseInt(key), label: timeZone.ids[key]});
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        timeZoneId: agent.timeZoneId,
        workSchedule: {
          ...agent.workSchedule,
        },
        notificationsEmailSchedule: {
          ...agent.notifications.email.schedule,
        },
        notificationsSmsSchedule: {
          ...agent.notifications.sms.schedule,
        },
        notificationsEmailEnabled: agent.notifications.email.enabled ? '1' : '0',
        notificationsSmsEnabled: agent.notifications.sms.enabled ? '1' : '0',
      }}
      validationSchema={Yup.object({
        timeZoneId: Yup.number().integer()
          .required('Time zone is required')
          .oneOf(Object.keys(timeZone.ids).map(item => parseInt(item)), 'Time zone is invalid'),
      })}
      onSubmit={async (values) => {
        apiFetch('PUT', '/agents/{user.id}', values);
      }}
    >
      {({ setFieldValue , isSubmitting }) => (
        <Form className="form account-settings">
          {error ? <div className="form__error">{formatErrorMessage(error)}</div> : ''}
          <div className="form__line work-schedule">
            <div className="form__sub-heading">
              Time & Availability <Tooltip text="The hours you are generally avaliable to work each week.<br/>We provide this information to companies to help match agents to their needs.<br />This is not what you are committing to work weekly."/>
            </div>
            <div className="form__sub-section form__line">
              <SelectInput formName="work-settings" label="Time Zone" name="timeZoneId" options={timeZones}/>
            </div>
            <div className="form__sub-section schedule">
              <DowMultiRange name="workSchedule" dowRanges={agent.workSchedule} setFieldValue={(sched) => setFieldValue('workSchedule', sched)}/>
            </div>
          </div>
          <div className="form__sub-heading">
            Notification Types and Times <Tooltip text="Get notifications when there is a shortage of agents for a company you work for."/>
          </div>
          <div className="form__sub-section">
            <div className="form__line">
              <CheckBoxInput formName="work-settings" label="Email" name="notificationsEmailEnabled" value="1" checked={agent.notifications.email.enabled} onChange={event => {agent.notifications.email.enabled = !agent.notifications.email.enabled; setFieldValue('notificationsEmailEnabled', event.target.checked)}}/>
            </div>
            {agent.notifications.email.enabled ? 
              <div className="form__sub-section schedule">
                <DowMultiRange name="notificationsEmailSchedule" dowRanges={agent.notifications.email.schedule} setFieldValue={(sched) => setFieldValue('notificationsEmailSchedule', sched)}/>
              </div>
              : ''
            }
            <div className="form__line">
              <CheckBoxInput formName="work-settings" label="Sms" name="notificationsSmsEnabled" value="1" checked={agent.notifications.sms.enabled} onChange={event => {agent.notifications.sms.enabled = !agent.notifications.sms.enabled; setFieldValue('notificationsSmsEnabled', event.target.checked)}}/>
            </div>
            {agent.notifications.sms.enabled ? 
              <div className="form__sub-section schedule">
                <DowMultiRange name="notificationsSmsSchedule" dowRanges={agent.notifications.sms.schedule} setFieldValue={(sched) => setFieldValue('notificationsSmsSchedule', sched)}/>
              </div>
              : ''
            }
          </div>
          <div className="form__line">
            {loading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Update Info</button>}
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default AvailabilityForm;
