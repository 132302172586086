import React, { useState } from "react";
import { formatErrorMessage, formatSimpleDate, getQueryStringParameter } from '../helper';
import { currency, url } from 'agents-remotely-commons/src/formatter';
import { NavLink } from 'react-router-dom';
import { agentRole, rewardType } from 'agents-remotely-commons/src/model';
import useFetch from '../hooks/useFetch';

const UserGoal = (props) => {
  const userGoals = [];
  const rewards = [];
  const progressTime = [0, 0];
  const totalTimeRange = [0, 0];
  const rewardGroups = {};
  const {apiFetch, data, error, loading} = useFetch();

  const updateUserGoal = () => {
    apiFetch('PUT', '/agents/{user.id}/goals/' + props.userGoal.goalId + '/close');
  };

  if('rewards' in props.userGoal) {
    for(const reward of props.userGoal.rewards) {
      rewardGroups[reward.type.id] = rewardGroups[reward.type.id] || [];
      rewardGroups[reward.type.id].push(reward);
    }
  }

  const getRewardSummary = (rewardTypeId, rewards) => {
    switch(parseInt(rewardTypeId)) {
      case rewardType.ROLE:
        const abilities = [];
        for(const reward of rewards) {
          if(reward.role === agentRole.VERIFIED) {
            abilities.push('New areas on the site including the testing, tokens and payment centers');
          } else {
            abilities.push(reward.role);
          }
        }
        return abilities.join('<br/>');
      case rewardType.GOAL:
        return rewards.length + ' task' + (rewards.length > 1 ? 's' : '');
      case rewardType.TEST:
        return rewards.length + ' test' + (rewards.length > 1 ? 's' : '');
      case rewardType.BADGE:
        return rewards.length + ' badge' + (rewards.length > 1 ? 's' : '');
      case rewardType.TOKENS:
        let tokens = 0;
        for(const reward of rewards) {
          tokens += reward.amount;
        }
        return tokens + ' token' + (tokens > 1 ? 's' : '');
      default:
        let money = 0;
        for(const reward of rewards) {
          money += reward.amount;
        }
        return currency(money);
    }
  };

  const getRewardTypeClass = (rewardTypeId) => {
    switch(parseInt(rewardTypeId)) {
      case rewardType.ROLE:
        return 'fa-user-plus';
      case rewardType.GOAL:
        return 'fa-check';
      case rewardType.TEST:
        return 'fa-clipboard';
      case rewardType.BADGE:
        return 'fa-award';
      case rewardType.TOKENS:
        return 'fa-coin';
      default:
        return 'fa-sack-dollar';
    }
  };

  for(const rewardTypeId in rewardGroups) {
    rewards.push(
      <div className="reward" key={rewardTypeId}>
        <span className={'reward__icon fa ' + getRewardTypeClass(rewardTypeId)}></span>
        <span>{getRewardSummary(rewardTypeId, rewardGroups[rewardTypeId])}</span>
      </div>
    );
  }
  for(const condition of props.userGoal.conditions) {
    totalTimeRange[0] += condition.estimatedTimeRange[0];
    totalTimeRange[1] += condition.estimatedTimeRange[1];
    if(condition.streak.completedAt) {
      progressTime[0] += condition.estimatedTimeRange[0];
      progressTime[1] += condition.estimatedTimeRange[1];
      userGoals.push(
        <div className='todo__item' key={condition.id}>
          <span className='completed fa fa-check'></span>
          <span className='strikethrough'>{condition.description}</span>
        </div>
      );
    } else {
      userGoals.push(
        <div className='todo__item' key={condition.id}>
          {getQueryStringParameter('processingConditionId') === condition.id ? <span className='todo__item--processing processing'></span> : <span className='incomplete fa fa-check'></span>}
          <span>{condition.description}</span>
          {getQueryStringParameter('processingConditionId') !== condition.id && condition.actionUrl ? <NavLink className='todo__link' to={url(condition.actionUrl.name, {redirect: url('dashboard', {processingConditionId: condition.id}), ...condition.actionUrl.params})}>(click here)</NavLink> : ''}
        </div>
      );
    }
  }
  const progress = progressTime[0];
  const totalMinutes = totalTimeRange[0];
  let progressPercent = (progress * 1.0) / totalMinutes * 100;
  if(isNaN(progressPercent)) {
   progressPercent = 100;
  }
  if(progressPercent < 0) {
    progressPercent = 0;
  }
  if(progressPercent > 100) {
    progressPercent = 100;
  }

  var c = Math.PI * (72 * 2); //72 is same as progress-bar r attribute
  var progressBarStrokeDashoffset = ((100 - progressPercent) / 100) * c;

  if(!data) {
    return (
      <div className='todo'>
        <div className="progress" data-pct="100">
          <div className="progress-data">
            <div className={progressPercent >= 100 ? 'progress-reward fa fa-treasure-chest progress-reward--complete' : 'progress-reward fa fa-treasure-chest'}></div>
            <div className="progress-percent">{Math.round(progressPercent)}%</div>
            <div className="progress-time">{progressPercent >= 100 ? 'Complete' : formatSimpleDate((totalMinutes - progress) * 60000) + ' left'}</div>
          </div>
          <svg className="progress-svg" width="100%" height="100%" viewport="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle className="progress-circle" r="72" cx="80" cy="80" fill="transparent" strokeDasharray="452.39" strokeDashoffset="0"></circle>
            <circle className="progress-circle progress-bar" r="72" cx="80" cy="80" fill="transparent" strokeDasharray="452.384" strokeDashoffset="0" style={{strokeDashoffset: progressBarStrokeDashoffset}}></circle>
          </svg>
        </div>
        <div className="todo-info">
          {'rewards' in props.userGoal ?
            <div className="rewards">
              <div className="rewards__title">
                Congratulations
              </div>
              <div className="reward">You've completed {props.userGoal.description}{rewards.length > 0 ? ' and unlocked:' : ''}</div>
              {rewards}
              {!props.userGoal.closedAt ? 
                <div>
                  {loading ? <div className="processing"></div> : <button className="button" onClick={updateUserGoal}>Hide Card</button>}
                  {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
                </div>
              : ''}
            </div>
            :
            <>
              <div className='todo__header-line'>{props.userGoal.description}</div>
              {userGoals}
            </>
          }
        </div>
      </div>
    );
  }
};

const UserGoals = (props) => {
  const [showHidden, setShowHidden] = useState(false);
  const userGoals = [];
  let hasHidden = false;
  let hasOpen = false;
  for(const userGoal of props.userGoals) {
    if(userGoal.closedAt) {
      hasHidden = true;
      if(showHidden) {
        userGoals.push(<UserGoal userGoal={userGoal} key={userGoal.goalId}/>)
      }
    } else {
      hasOpen = true;
      userGoals.push(<UserGoal userGoal={userGoal} key={userGoal.goalId}/>)
    }
  }
  return (
    <div className="content__main todos">
      {!hasOpen ? <div className="todos__finished">Nice Work!  Looks like you don't have any pending tasks</div> : ''}
      {userGoals}
      {hasHidden ?
        <div><span className="todos__hide" onClick={() => setShowHidden(current => !current)}>{showHidden ? 'Hide' : 'Show'} Past Completions</span></div>
      : ''}
    </div>
  );
};

export default UserGoals;
