'use strict'

module.exports = {
  AUTHORIZE: 1,
  ACCEPT: 2,
  DECLINE: 3,
  IGNORE: 4,
  END: 5,
  DIAL: 6,
  ids: {
    '1': 'Authorize',
    '2': 'Accept',
    '3': 'Decline',
    '4': 'Ignore',
    '5': 'End',
    '6': 'Dial',
  },
};
