import React from "react";

const GeneralInfo = (props) => {
  return (
    <div className="content__info">
      {props.contentGroups.map((contentGroup, index) => {
        const contentLines = contentGroup.content.map((content, cIndex) =>  <div className="content__info-line" key={cIndex}>{content.text}</div>);
        return <div className="content__info-group" key={index}>
          <div className="content__info-header-line">{contentGroup.text}</div>
          {contentLines}
        </div>
      })}
    </div>
  );
};

export default GeneralInfo;
