import React from 'react';
import { useField } from 'formik';
import { titleToDash } from 'agents-remotely-commons/src/formatter';

const TextInput = ({ label, formName, labelJsx, ...props }) => {
  const [field, meta, {setValue}] = useField(props);

  const onChange = (event) => {
    if(props.onChange) {
      props.onChange(event.target.value);
    }
    setValue(event.target.value);
  };

  const toggleShow = (event, cls) => {
    event.currentTarget.classList.toggle('fa-eye-slash');
    const input = document.querySelector('.' + cls);
    if(input.getAttribute('type') === 'password') {
      input.setAttribute('type', 'text');
    } else {
      input.setAttribute('type', 'password');
    }
  };

  const classPrefix = formName + '__' + titleToDash(field.name);
  return (
    <>
      {label ? <label className={'form__label ' + classPrefix + '-label'} htmlFor={props.id || props.name}>{label}{labelJsx ? labelJsx : ''}</label> : ''}
      <input className={'form__text-input ' + classPrefix} {...field} {...props}  onChange={onChange} />
      {props.type === 'password' ? <span className='fa fa-fw fa-eye form__field-icon' onClick={(event) => { toggleShow(event, classPrefix)}}></span> : ''}
      {meta.touched && meta.error ? (
        <div className={'form__error ' + classPrefix + '-error'}>{meta.error}</div>
      ) : null}
    </>
  );
};

export default TextInput;
