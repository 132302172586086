import React from 'react';

const Privacy = () => {
  return (
    <section className="content content__no-auth">
      <div className="title">
        <h1>Privacy Policy</h1>
      </div>
      <div className="terms">
        <div>
          We take your privacy seriously
        </div>
        <div>
          Agents Remotely ("Agents Remotely," "we," "us," and "our") is committed to protecting the privacy of all its users. We understand and value the importance of keeping your data safe and secure. Please contact us if you have any additional privacy or security questions after reading this information. This Privacy Policy governs how we collect and use your information when you use our websites, mobile apps, and related services ("Services").
        </div>
        <div className="">
          1. How we collect and use information
        </div>
        <div>
          We collect and use the following types of information:
        </div>
        <div className="">
          a. Information you give us. When you create an account, we may collect your personal information such as your username, first and last name, birth date, email address, mobile phone number and a photo, if you chose to have a photo associated with your account. We may collect billing information when you sign up.
        </div>
        <div className="">
          b. Usage Information. We collect information from and about your use of the Services and the devices you use to access the Services. This includes things like IP addresses, the type of browser and device you use, the web page you visited before coming to our sites, and information about the advertisements you view and the links you click on. Your devices (depending on your settings) may also transmit location information and/or an advertising identifier to the Services.
        </div>
        <div className="">
          2. Agents Remotely's use of your information
        </div>
        <div>
          We use the information we collect to provide, personalize, improve, offer, and protect our Services. If you contact Agents Remotely, we may use your information to provide customer support to you. We may use your email address to communicate with you, for example, to inform you about platform changes.
        </div>
        <div className="">
          3. Does Agents Remotely share my information
        </div>
        <div>
          We understand your information is important to you, and we treat it very carefully. We do not sell your personal information, and we do not share your personal information with third parties, except as described below.
        </div>
        <div className="">
          a. With vendors working for Agents Remotely. Agents Remotely uses certain trusted third parties to help us provide, personalize, improve, offer and protect our Services ("Vendors"). Vendors will access your information only to perform tasks on our behalf, in compliance with this Privacy Policy.
        </div>
        <div className="">
          b. For legal reasons. We may disclose your information to third parties if we determine that such disclosure is necessary to (a) comply with the law or in response to a law enforcement agency's request; (b) protect any person from death or serious bodily injury; (c) prevent fraud or abuse of Agents Remotely or our users; or (d) protect Agent Remotely's property rights.
        </div>
        <div className="">
          4. Use of cookies
        </div>
        <div>
          We use technologies like cookies and pixel tags to provide, improve, protect and promote our Services. For example, cookies help us with things like remembering your account for your next visit, understanding how you are interacting with our Services, and improving them based on that information. You can set your browser to not accept cookies, but this may limit your ability to use the Services.
        </div>
        <div className="">
          5. Accessing or correcting information
        </div>
        <div>
          As an Agents Remotely user, you have the opportunity to review and modify your personally identifiable information and your private content any time you log onto your account using an email address and password attached to the account.
        </div>
        <div className="">
          6. What if our business is sold?
        </div>
        <div>
          We reserve the right to transfer any information we have about you in the event of a merger with a third party or upon a sale or transfer of all or a portion of our business or assets to a third-party acquirer. The new company would be required to protect all the information that Agents Remotely has collected from its users under the same terms of this Privacy Policy.
        </div>
        <div className="">
          7. How Agents Remotely protects your information
        </div>
        <div>
          Agents Remotely cares about the security of your information and uses commercially reasonable physical, administrative, and technological safeguards to preserve the integrity and security of all information we collect and that we share with our service providers. However, no security system is impenetrable and we cannot guarantee the security of our systems 100%. In the event that any information under our control is compromised as a result of a breach of security, we will take reasonable steps to investigate the situation and where appropriate, notify those individuals whose information may have been compromised and take other steps, in accordance with any applicable laws and regulations.
        </div>
        <div className="">
          8. Changes and updates to the Privacy Policy
        </div>
        <div>
          We may occasionally update this Privacy Policy. When we do, we will also revise the "Last Updated" date on this page. We encourage you to review this Privacy Policy from time to time to stay informed of any updates or changes to this Privacy Policy. If you continue to use our Services after we make changes to the Privacy Policy, you agree to the changes.
        </div>
        <div className="">
          9. How to contact Agents Remotely
        </div>
        <div>
          If you have any questions, comments, or concerns about this Privacy Policy, or if you would like to delete your data, please...(click here)
        </div>
      </div>
    </section>
  );
}

export default Privacy;
