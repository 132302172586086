import React from 'react';
import TextInput from '../components/TextInput';
import { url } from 'agents-remotely-commons/src/formatter';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { formatErrorMessage } from '../helper';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ForgotPasswordForm = () => {
  const {apiFetch, data: user, error, loading} = useFetch();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  React.useEffect(() => {
    if(user) {
      navigate(url('resetPassword', {email: user.email}));
    }
  }, [user]);

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        email: '',
      }}
      validationSchema={Yup.object({
        email: Yup.string().email('Email is invalid').required('Email is required'),
      })}
      onSubmit={async (values) => {
        const token = await executeRecaptcha();
        apiFetch('POST', '/agents/password', {...values, recaptchaResponse: token});
      }}
    >
      <Form className="form forgot-password">
        {error ? <div className="form__error">{formatErrorMessage(error)}</div> : ''}
        <div className="form__line">
          <TextInput
            formName="forgot-password"
            label="Email"
            name="email"
            type="email"
            autoFocus={true}
            autoComplete="email"
            autoCorrect="off"
            spellCheck="false"
            required="required"
          />
        </div>
        <div className="form__line">
          {loading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Reset Password</button>}
        </div>
      </Form>
    </Formik>
  );
}

export default ForgotPasswordForm;
