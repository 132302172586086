import React from 'react';
import GeneralInfo from '../../components/GeneralInfo';
import AvailabilityForm from '../../components/AvailabilityForm';
import useFetch from '../../hooks/useFetch';
import {formatErrorMessage} from '../../helper';

const Availability = () => {
  const {apiFetch, data: agent, error, loading} = useFetch();

  React.useEffect(() => {
    apiFetch('GET', '/agents/{user.id}', {
      select: ['timeZoneId', 'workSchedule', 'notifications'],
    });
  }, []);

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Availability & Notifications</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Availability',
            content: [
              {text: 'Your availability, in terms of the hours you can work each week, is also essential as it enables us to match your schedule with the needs of the partnering companies. Note that this is not a binding commitment to your weekly working hours. You will be able to make your own schedule, you work when you want to work.'},
            ],
          },
          {
            text: 'Notifications',
            content: [
              {text: 'Finally, by opting into notifications, you will be kept informed of any fluctuations in volume or shortage of agents within the companies you are associated with, ensuring you are always aware of opportunities for additional work.'},
            ],
          },
        ]}/>
        {loading ? <div className="processing"></div> : ''}
        {agent ? 
          <div className="account-settings-form content__form">
            <AvailabilityForm agent={agent}/>
          </div>
          : ''
        }
        {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
      </div>
    </section>
  );
}

export default Availability;
