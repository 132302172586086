import { createSlice } from '@reduxjs/toolkit'

export const badgesSlice = createSlice({
  name: 'badges',
  initialState: {
    value: 0,
  },
  reducers: {
    increment: (state, action) => {
      state.value += action.payload;
    },
    decrement: (state, action) => {
      state.value -= action.payload;
    },
  },
});

export const { increment, decrement } = badgesSlice.actions;

export default badgesSlice.reducer;
