'use strict'

module.exports = {
  EQUAL: 1,
  NOT_EQUAL: 2,
  LESS_THAN: 3,
  LESS_THAN_OR_EQUAL: 4,
  GREATER_THAN: 5,
  GREATER_THAN_OR_EQUAL: 6,
  IN: 7,
  NOT_IN: 8,
  CONTAINS: 9,
  DOES_NOT_CONTAIN: 10,
  ids: {
    '1': 'equal',
    '2': 'notEqual',
    '3': 'lessThan',
    '4': 'lessThanInclusive',
    '5': 'greaterThan',
    '6': 'greaterThanInclusive',
    '7': 'in',
    '8': 'notIn',
    '9': 'contains',
    '10': 'doesNotContain',
  },
};
