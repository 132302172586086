'use strict'

const MFA = {
  taskId : '65be3e90-11cc-11ec-93b1-33d7893cffd3',
  description : 'You must setup multi-factor authentication',
  priority : .1,
  url : '/qr.html',
};
const INITIAL_VERIFY_EMAIL = {
  taskId : '66a2f940-11cc-11ec-a0b7-5f977a1c3e5f',
  description : 'You must verify your email address',
  priority : 0,
  url : '/user/verification-code.html?attributeName=email',
};
const VERIFY_EMAIL = {
  taskId : '675cd360-11cc-11ec-8dd9-7df420375c7b',
  description : 'You must verify your email address',
  priority : .5,
  url : '/user/verification-code.html?attributeName=email',
};
const VERIFY_PHONE = {
  taskId : '68001840-11cc-11ec-bbd5-112fa247c12f',
  description : 'You must verify your phone number',
  priority : .5,
  url : '/user/verification-code.html?attributeName=phone',
};
const CREATE_COMPONENT = {
  taskId : '68b3d7e0-11cc-11ec-ab6b-4d44c31645bc',
  description : 'Create components.  This can be an IVR, call queue, voicemail, etc...',
  priority : 1000,
  url : '/component/add.html',
};
const CREATE_JOB = {
  taskId : '696351c0-11cc-11ec-99b0-bdd99a92a634',
  description : 'Create a job with companies to work for',
  priority : 2000,
  url : '/job/add.html',
};

module.exports = {
  CREATE_COMPONENT,
  CREATE_JOB,
  INITIAL_VERIFY_EMAIL,
  MFA,
  VERIFY_EMAIL,
  VERIFY_PHONE,
};
