'use strict'

module.exports = {
  SIP_TRANSFER : 1,
  FORWARDING_NUMBER : 2,
  RECORDING : 3,
  FORWARDING_QUEUE : 4,
  ids : {
    '1' : 'SIP Transfer',
    '2' : 'Forwarding Number',
    '3' : 'Recording',
    '4' : 'Forwarding Queue',
  },
};
