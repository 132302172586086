import React, { useState } from 'react';
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import { currency } from 'agents-remotely-commons/src/model';

const PaymentType = (props) => {
  const content = props.paymentType.fields.map((field, index) => {
    return (
      <div className="form__line" key={index}>
        {field.group.map((group, gIndex) => {
          switch(group.type) {
            case 'select':
            case 'radio':
              const options = group.valuesAllowed.map(val => { return {value: val.key, label: val.name}});
              return <SelectInput formName="wise" label={field.name} name={'details.' + group.key} autoComplete="off" options={options} key={gIndex} required={group.required||false} onChange={(e) => { if(group.refreshRequirementsOnChange) { props.updateForm('details.' + group.key, e);} else { return e; } }}/>
            default:
              let type = 'text';
              let autocomplete = 'off'
              let label = field.name;
              let autocapitalize = 'off';
              if(group.key === 'email') {
                type = 'email';
                autocomplete = 'email';
              } else if(group.key === 'phoneNumber') {
                type = 'tel';
                autocomplete = 'tel';
              } else if(group.key === 'accountHolderName') {
                autocomplete = 'name';
                autocapitalize = 'words';
              } else if(group.key === 'address.firstLine') {
                autocomplete = 'address-line-1';
              } else if(group.key === 'address.secondLine') {
                autocomplete = 'address-line-2';
              } else if(group.key === 'address.city') {
                autocomplete = 'address-level2';
                autocapitalize = 'words';
              } else if(group.key === 'address.country') {
                autocomplete = 'country';
              } else if(group.key === 'address.postCode') {
                label = 'Postal Code';
                autocomplete = 'postal-code';
              }
              return <TextInput type={type} formName="wise" label={label} name={'details.' + group.key} autoComplete={autocomplete} autoCorrect="off" autoCapitalize={autocapitalize} spellCheck="off" minLength={group.minLength} maxLength={group.maxLength} pattern={group.validationRegexp} key={gIndex} required={group.required||false} onChange={(e) => { if(group.refreshRequirementsOnChange) { props.updateForm('details.' + group.key, e);} else { return e; }}}/>
          }
        })}
      </div>
    )
  });
  return (
    <>
      {content}
    </>
  );
}

const WiseForm = (props) => {
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentType, setPaymentType] = useState('');
  const [extraWiseFields, setExtraWiseFields] = useState({});
  const [currencyId, setCurrencyId] = useState(currency.USD);
  const currencies = [];
  for(const key in currency.ids) {
    currencies.push({value: parseInt(key), label: currency.ids[key]});
  }

  const dotToObj = (obj) => {
    const result = {};

    // For each object path (property key) in the object
    for (const objectPath in obj) {
      // Split path into component parts
      const parts = objectPath.split('.');

      // Create sub-objects along path as needed
      let target = result;
      while (parts.length > 1) {
        const part = parts.shift();
        target = target[part] = target[part] || {};
      }

      // Set value at end of path
      target[parts[0]] = obj[objectPath]
    }

    return result;
  }
  const postWiseForm = async (wiseFields) => {
    try {
      if(wiseFields && Object.keys(wiseFields).length > 0) {
        const response = await fetch('https://api.transferwise.com/v1/account-requirements?' + new URLSearchParams({
          source: 'USD',
          target: currency.ids[currencyId] || 'USD',
          sourceAmount : 1000,
        }), {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(dotToObj(wiseFields)),
        });

        const transferTypes = await response.json();
        if(transferTypes.length > 0) {
          setPaymentTypes(transferTypes.reduce((a, v) => ({ ...a, [v.type]: v}), {}));
          setPaymentType(transferTypes[0].type);
          props.setFieldValue('transferType', transferTypes[0].type);
        }
      }
    } catch(err) {
      console.log(err);
      throw err;
    }
  };

  const getWiseForm = async () => {
    try {
      const response = await fetch('https://api.transferwise.com/v1/account-requirements?' + new URLSearchParams({
        source: 'USD',
        target: currency.ids[currencyId] || 'USD',
        sourceAmount : 1000,
      }));

      const transferTypes = await response.json();
      setPaymentTypes(transferTypes.reduce((a, v) => ({ ...a, [v.type]: v}), {}));
      setPaymentType(transferTypes[0].type);
      props.setFieldValue('transferType', transferTypes[0].type);
    } catch(err) {
      console.log(err);
      throw err;
    }
  };

  const updateForm = (field, val) => {
    if(val) {
      setExtraWiseFields(previousState => {
        const state = {
          ...previousState,
          [field]: val,
        };
        postWiseForm(state);
        return state;
      });
      postWiseForm();
    } else if(field in extraWiseFields) {
      setExtraWiseFields(previousState => {
        const state = {...previousState};
        delete state[field];
        postWiseForm(state);
        return state;
      });
    }
  };

  React.useEffect(() => {
    getWiseForm();
  }, []);

  const paymentTypeOptions = Object.values(paymentTypes).map((item, index) => { return {value: item.type, label: item.title} });
  return (
    <div className="wise-form">
      <div className="form__line">
        <TextInput
          formName="wise"
          label="Account Holder Name"
          name="accountHolderName"
          type="text"
          pattern="^([A-z0-9À-ž '-]){2,}$"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          required="required"
        />
      </div>
      <div className="form__line">
        <SelectInput formName="wise" label="Bank Currency" name="currencyId" autoComplete="off" options={currencies} onChange={(e) => {setCurrencyId(e); getWiseForm();}}/>
      </div>
      {paymentTypeOptions.length > 0 ? 
        <div className="form__line">
          <SelectInput formName="wise" label="Payment Type" name="transferType" autoComplete="off" options={paymentTypeOptions} onChange={(e) => {setPaymentType(e)}}/>
        </div>
        : ''
      }
      {paymentType ? <PaymentType paymentType={paymentTypes[paymentType]} updateForm={updateForm}/> : ''}
    </div>
  );
};

export default WiseForm;
