import React from 'react';
import GeneralInfo from '../components/GeneralInfo';
import { NavLink } from 'react-router-dom';
import TextInput from '../components/TextInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { url } from 'agents-remotely-commons/src/formatter';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import useFetch from '../hooks/useFetch';
import { formatErrorMessage } from '../helper';

const Mfa = () => {
  const {apiFetch, data: user, error, loading} = useFetch();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const { signIn } = useAuth();

  React.useEffect(() => {
    if(user) {
      signIn(user);
      navigate(url('dashboard'));
    }
  }, [user]);

  return (
    <section className="content content__no-auth">
      <div className="title">
        <h1>2 Factor Authentication</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Verification',
            content: [
              {text: 'Please find and enter the code from the app you used when setting up MFA for your account.  This is likely the Authy or Google Authenticator app on your smart device.'},
              {text: 'NEVER share the code you receive with anyone.'},
              {text: 'No one from Agents Remotely or the companies on Agents Remotely will ask you for it.'},
              {text: 'If someone does ask you for it, please report the incident to us.'},
            ],
          },
        ]}/>
        <div className="mfa-form content__form">
          <Formik
            validateOnChange={false}
            initialValues={{
              verificationCode: '',
              email: urlParams.get('email'),
              challengeName: urlParams.get('challengeName'),
              sessionId: urlParams.get('sessionId'),
            }}
            validationSchema={Yup.object({
              verificationCode: Yup.string()
                .required('Verification code is required')
                .matches(/^[0-9]+$/, "Verification code must be only digits")
                .min(6, 'Verification code must be exactly 6 digits')
                .max(6, 'Verification code must be exactly 6 digits')
            })}
            onSubmit={async (values) => {
              apiFetch('POST', '/agents/sessions/' + urlParams.get('sessionId'), values);
            }}
          >
            {props => (
              <Form className="form mfa">
                {error ?
                  <div className="form__error">
                    {formatErrorMessage(error)}
                    {error === 'Please confirm your phone before signing in.' ? <>&nbsp;<NavLink className="form__link confirm-account__resend" to={url('confirmAccount', {email: props.values.email, resend: 'true'})}>Click here</NavLink> if you didn't receive an SMS.</> : ''}
                  </div>
                : ''}
                <div className="form__line">
                  <TextInput
                    formName="mfa"
                    name="email"
                    type="hidden"
                    required="required"
                  />
                  <TextInput
                    formName="mfa"
                    name="sessionId"
                    type="hidden"
                    required="required"
                  />
                  <TextInput
                    formName="mfa"
                    name="challengeName"
                    type="hidden"
                    required="required"
                  />
                  <TextInput
                    formName="mfa"
                    label="Verification Code"
                    name="verificationCode"
                    type="tel"
                    autoFocus={true}
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                    required="required"
                  />
                </div>
                <div className="form__line">
                  {loading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Submit</button>}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
}

export default Mfa;
