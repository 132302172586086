import { titleToWord } from 'agents-remotely-commons/src/formatter';
const DEBUG = true;
const API_URL = process.env.REACT_APP_AGENTS_REMOTELY_API_HOST || 'https://api.agentsremotely.com';

/*
const disconnectTwilio = () => {
  if(localStorage.getItem('agentOnline') === 'true') {
    sendReceive('PUT', '/agents/' + localStorage.getItem('agentId') + '/offline', {}, 'application/json', (json) => {
      if(!json.error) {
        localStorage.removeItem('agentOnline');
      } else {
        console.log(json.error);
      }
    });
  }
};

if(document.querySelector('.call') === null) {
  disconnectTwilio();
}
*/
const sendReceive = (method, path, params, responseType, handler, attempt) => {
  params = params || {};
  responseType = responseType || 'application/json';
  attempt = attempt || 0;
  if(DEBUG) {
    console.log(params);
  }
  let url = path.indexOf('://') < 0 ? API_URL + path : path.substring(0); //do substring to clone
  let body = null;

  if(method === 'GET' || method === 'HEAD') {
    if(Object.keys(params).length > 0)  {
      url += '?' + Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
    }
  } else if(params instanceof FormData || params instanceof File || params instanceof Blob) {
    body = params;
  } else {
    body = JSON.stringify(params);
  }

  const headers = {
    Accept : responseType,
  };

  if((path.indexOf('agentsremotely.com') >= 0 || path.indexOf('https:') !== 0) && localStorage.getItem('user')) {
    const user = JSON.parse(localStorage.getItem('user'));
    if(user?.idToken) {
      headers.Authorization = user.idToken;
    }
  }

  return fetch(url, {
    body: body,
    headers : headers,
    method: method,
    mode: 'cors',
    //credentials: 'include'
  }).then((response) => {
    if(responseType === 'application/json') {
      return response.json();
    } else if(responseType !== 'text/plain' || responseType !== 'text/html' || responseType !== 'text/xml' || responseType !== 'javascript/text') {
      return response.arrayBuffer();
    } else {
      return response.text();
    }
  }).catch((err) => {
    return {error: err.message};
  }).then(async (res) => {
    if(res && res.error) {
      if(res.error === 'The incoming token has expired') {
        await reauthorize();
        return sendReceive(method, path, params, responseType, handler);
      } else if(res.error === 'Unauthorized') {
        //clearAndRedirect();
      } else if(res.error === 'Database down') {
        return sendReceive(method, path, params, responseType, handler, ++attempt);
      } else {
        if(res.error.refreshTokens) {
          try {
            await reauthorize();
          } catch(err) {
            console.log(err);
          }
        }
        if(handler) {
          handler(res);
        } else {
          return res;
        }
      }
    } else if(res && res.message === 'Endpoint request timed out') {
      return sendReceive(method, path, params, responseType, handler, ++attempt);
    } else {
      if(res.refreshTokens) {
        try {
          await reauthorize();
        } catch(err) {
          console.log(err);
        }
      }
      if(handler) {
        handler(res);
      } else {
        return res;
      }
    }
  });
};

const reauthorize = async () => {
  const json = await sendReceive('POST', '/agents/' + localStorage.getItem('agentId') + '/sessions', {refreshToken : localStorage.getItem('agentRefreshToken')});
  if(DEBUG) {
    console.log(json);
  }
  if(!json.error) {
    //signIn(json);
  } else {
    //signOut();
  }
};

const formatSimpleDate = (millis) => {
  if(millis < 60000) {
    return Math.round(millis / 1000) + 's';
  } else if(millis < 3600000) {
    return Math.round(millis / 60000) + 'm';
  } else if(millis < 86400000) {
    return Math.round(millis / 3600000) + 'h';
  } else {
    return Math.round(millis / 86400000) + 'd';
  }
};

const getQueryStringParameter = (param) => {
  return new URLSearchParams(window.location.search).get(param);
};

const formatErrorMessage = (err) => {
  if(typeof err === 'object') {
    let html = [];
    for(let key in err) {
      html.push(<div className="error__line" key={key}>{titleToWord(key.endsWith('Id') ? key.substring(0, key.length - 2) : key) + ' ' + err[key].toLowerCase()}</div>);
    }
    return html;
  } else {
    return err;
  }
};

export {
  getQueryStringParameter,
  formatSimpleDate,
  formatErrorMessage,
  sendReceive,
};
