import { createSlice } from '@reduxjs/toolkit'

export const testsSlice = createSlice({
  name: 'tests',
  initialState: {
    value: {},
  },
  reducers: {
    clear: (state) => {
      state.value = {};
    },
    remove: (state, action) => {
      delete state.value[action.payload.id];
    },
    set: (state, action) => {
      state.value[action.payload.id] = action.payload;
    },
  },
});

export const { clear, remove, set } = testsSlice.actions;

export default testsSlice.reducer;
