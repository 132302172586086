import React from 'react';
import UserTests from '../../components/UserTests';
import GeneralInfo from '../../components/GeneralInfo';
import useFetch from '../../hooks/useFetch';
import {formatErrorMessage} from '../../helper';
import { useSelector, useDispatch } from 'react-redux';
import { clear as clearTests } from '../../features/testsSlice';

const TestCenter = () => {
  const {apiFetch, data: userTests, error, loading, setData: setUserTests} = useFetch();
  const updatedUserTests = useSelector((state) => state.tests.value);
  const dispatch = useDispatch();

  React.useEffect(() => {
    apiFetch('GET', '/agents/{user.id}/tests');

    /*
    if(Object.keys(updatedUserTests).length > 0) {
      dispatch(clearTests());
    }
    */
  }, [updatedUserTests]);

  /*
  if(userTests && Object.keys(updatedUserTests).length > 0) {
    setUserTests(current => {
      const newUserTests = [...current];
      for(let i = 0; i < newUserTests.length; i++) {
        if(newUserTests[i].testId in updatedUserTests) {
          newUserTests[i] = updatedUserTests[newUserTests[i].testId];
          delete updatedUserTests[newUserTests[i].testId];
        }
      }
      for(const userTestId in updatedUserTests) {
        newUserTests.push(updatedUserTests[userTestId]);
      }
      dispatch(clearTests());
      setUserTests(newUserTests);
      return newUserTests;
    });
  }
  */

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Testing Center</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'About',
            content: [
              {text: 'We highly recommend that you consider taking language aptitude tests only for those languages you have a solid grasp.'},
              {text: 'The test scores are intended to provide you with valuable insight into your language skills and competencies.'},
              {text: 'It is noteworthy that each test can be retaken once every three months, providing individuals with the opportunity to continually enhance their skills over time and demonstrate their newfound abilities.  Therefore, we highly recommend taking the practice test multiple times to maximize the chance of achieving optimal results.'},
            ],
          },
        ]}/>
        {loading ? <div className="processing"></div> : ''}
        {userTests ? 
          <UserTests userTests={userTests}/>
          : ''
        }
        {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
      </div>
    </section>
  );
}

export default TestCenter;
