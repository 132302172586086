'use strict'

const ids = {
  '3' : 'Online',
  '4' : 'Offline',
  '5' : 'Actively Working',
  '6' : 'Pending Work',
  '7' : 'Dialing',
  '8' : 'Working Task',
  '9' : 'Initializing Call',
};

const values = {};
for(const id in ids) {
  values[ids[id]] = parseInt(id);
}

module.exports = {
  ONLINE : 3, //able to take calls
  OFFLINE : 4,
  ACTIVELY_WORKING : 5,
  PENDING_WORK : 6,
  DIALING : 7,
  WORKING_TASK : 8,
  INITIALIZING_CALL : 9,
  WORKING_AND_BUSY : [5, 6, 7, 8, 9],
  CAN_WORK : [3, 4, 5, 6, 7, 8, 9],
  WORKING : [3, 5, 6, 7, 8, 9],
  ids : ids,
  values: values,
};
