import React from 'react';
import GeneralInfo from '../components/GeneralInfo';
import SignUpForm from '../components/SignUpForm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const SignUp = () => {
  return (
    <section className="content content__no-auth">
      <div className="title">
        <h1>Sign Up</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Why sign up?',
            content: [
              {text: 'Discover the freedom of setting your own hours.'},
              {text: 'Work remotely from anywhere, all you need is a computer and high-speed internet to get started.'},
              {text: 'Choose the companies and types of work that align with your interests and skill set.'},
              {text: 'Don\'t settle for a traditional 9-to-5. Join us and take charge of your work-life balance today.'},
            ],
          },
        ]}/>
        <GoogleReCaptchaProvider reCaptchaKey="6LfdZKUjAAAAACEziad2F6wX-1w0kMS0aU2Ghcgu">
          <SignUpForm/>
        </GoogleReCaptchaProvider>
      </div>
    </section>
  );
}

export default SignUp;
