'use strict'

module.exports = {
  COUNT: 1,
  DISTINCT_COUNT: 2,
  SUM: 3,
  AVG: 4,
  MIN: 5,
  MAX: 6,
  ids: {
    '1': 'Count',
    '2': 'Distinct Count',
    '3': 'Sum',
    '4': 'Average',
    '5': 'Minimum',
    '6': 'Maximum',
  },
};
