import React from 'react';
import FileInput from './FileInput';
import HiddenInput from './HiddenInput';
import useFetch from '../hooks/useFetch';
import {formatErrorMessage} from '../helper';

const FileUploadInput = (props) => {
  const {apiFetch, error, loading} = useFetch();

  const uploadChanged = async (event) => {
    const eventTarget = event.currentTarget;
    const fileName = props.uploadName + getFileExtension(eventTarget.files[0].type);
    await apiFetch('PUT', '/agents/{user.id}/files/' + fileName, eventTarget.files[0], null, eventTarget.files[0].type);
    props.setUploadUrl({name: fileName});
    props.setFieldValue(props.name, fileName);
  };

  const getFileExtension = (type) => {
    switch(type) {
      case 'image/png':
        return '.png';
      case 'image/jpg':
      case 'image/jpeg':
        return '.jpg';
      case 'application/msword':
        return '.doc';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '.docx';
      case 'application/pdf':
        return '.pdf';
      default:
        return '.txt';
    }
  };

  return (
    <>
      {error ? <div className="form__error">{formatErrorMessage(error)}</div> : ''}
      {loading ? <div className="processing"></div> : 
        <FileInput
          formName={props.formName}
          label={props.label}
          icon={props.uploadUrl.name ? <span className="form__uploaded fa fa-check"></span> : ''}
          name={props.uploadName}
          type="file"
          onChange={uploadChanged}
          accept={props.accept}
        />
      }
      <HiddenInput
        formName={props.formName}
        name={props.name}
        value={props.uploadUrl.name}
        type="hidden"
      />
    </>
  );
};

export default FileUploadInput;
