import React from 'react';
import useFetch from '../hooks/useFetch';
import {formatErrorMessage} from '../helper';
import UserBadges from '../components/Badges';

const Badges = () => {
  const {apiFetch, data: badges, error, loading} = useFetch();

  React.useEffect(() => {
    apiFetch('GET', '/agents/{user.id}/badges');
  }, []);

  return (
    <>
      {loading ? <div className="processing"></div> : ''}
      {badges ? <UserBadges badges={badges}/> : ''}
      {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
    </>
  )
}

export default Badges;
