'use strict'

module.exports = {
  ids : {
    '1' : 'Travel/Hospitality',
    '2' : 'Insurance',
    '3' : 'Retail',
    '4' : 'Technology',
    '5' : 'Education',
    '6' : 'Financial',
    '7' : 'Health Care',
  },
};
