import React from 'react';

const Home = () => {
  React.useEffect(() => {
    document.body.classList.add('home-page');
    document.querySelectorAll('.slideshow > li').forEach((current) => {
      current.addEventListener('animationend', (e) => {
        e.target.parentNode.appendChild(e.target);
      });
    });

    const adverbs = ['When', 'Where', 'How'];
    let adverbIndex = 0;
    setInterval(() => {
      if(adverbIndex >= adverbs.length) {
        adverbIndex = 0;
      }
      if(document.querySelector('.work-adverb')) {
        document.querySelector('.work-adverb').innerHTML = adverbs[adverbIndex++];
      }
    }, 2000);
    const head = document.querySelector('.ai-head');
    const phone = document.querySelector('.ai-phone');
    const pageWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const reveals = document.querySelectorAll('.reveal');

    const reveal = () => {
      const scrollPercent = (document.documentElement.scrollTop||document.body.scrollTop) / ((document.documentElement.scrollHeight||document.body.scrollHeight) - document.documentElement.clientHeight)
      head.style.left = (pageWidth/2 - 149.5) * scrollPercent + 'px';
      phone.style.right = (pageWidth/2 - 149.5) * scrollPercent + 'px';

      for (let i = 0; i < reveals.length; i++) {
        const windowHeight = window.innerHeight;
        const elementTop = reveals[i].getBoundingClientRect().top;
        const elementVisible = 0;

        if (elementTop < windowHeight - elementVisible) {
          if(!reveals[i].classList.contains('active')) {
            reveals[i].classList.add('active');
          }
        } else {
          if(reveals[i].classList.contains('active')) {
            reveals[i].classList.remove('active');
          }
        }
      }
    }

    //TODO refactor to use react to show hide
    document.querySelector('.home-video__cover').addEventListener('click', (e) => {
      document.querySelector('.home-video__vid').play();
    });
    document.querySelector('.home-video__play').addEventListener('click', (e) => {
      document.querySelector('.home-video__vid').play();
    });
    document.querySelector('.home-video__vid').addEventListener('playing', (e) => {
      document.querySelector('.home-video__cover').classList.add('is-hidden');
      document.querySelector('.home-video__play').classList.add('is-hidden');
    });
    [].forEach.call(document.querySelectorAll('.home-qa__question'), (el) => {
      el.addEventListener('click', (e) => {
        el.querySelector('.answer').classList.toggle('is-hidden');
      });
    });

    window.addEventListener('scroll', reveal, {passive: true});
    reveal();
    return () => {
      document.body.classList.remove('home-page');
    }
  }, [])

  return (
    <>
    <div className="home-containers">
      <div className="home-container home-overview">
        <ul className="home-overview-slideshow slideshow">
          <li className="slideshow__img slideshow__img--1"></li>
          <li className="slideshow__img slideshow__img--5"></li>
          <li className="slideshow__img slideshow__img--3"></li>
          <li className="slideshow__img slideshow__img--4"></li>
          <li className="slideshow__img slideshow__img--2"></li>
          <div className="home-content-main reveal fade trigger" trigger-index="0">
            <div className="work"><span>Work</span><span className="work-adverb">When</span><span>You Want</span></div>
            <div className="home-content__description home-content-main__description">A place for talented contact center agents to work on their own terms</div><a className="button--big home-overview__button" href="/sign-up.html">Start Earning Now</a>
          </div>
        </ul>
      </div>
      <div className="home-container home-intro__container">
        <div className="home-intro__title reveal fade trigger" trigger-index="1">Opportunities Await You</div>
        <div className="home-intro-opportunities">
          <div className="home-content__bubble home-content__bubble-1 reveal fade-left">
            <div className="home-bubble__line--big">
              <div>Customer Service</div><img className="home-bubble__img" src="../images/csr1.jpg" alt="customer service agent"/>
            </div>
          </div>
          <div className="home-content__bubble home-content__bubble-2 reveal fade">
            <div className="home-bubble__line--big">
              <div>Inbound Sales</div><img className="home-bubble__img" src="/images/csr2.jpg" alt="sales agent"/>
            </div>
          </div>
          <div className="home-content__bubble home-content__bubble-3 reveal fade-right">
            <div className="home-bubble__line--big">
              <div>Live Chat</div><img className="home-bubble__img" src="/images/csr3.jpg" alt="live chat agent"/>
            </div>
          </div>
        </div>
        <div className="home-intro-content reveal fade-bottom">
          <div className="home-intro-content__title">Welcome to the revolutionary platform for freelance Support Specialists!</div>
          <div className="home-intro-content__description">Our platform is not your traditional contact center; we offer a new and unique approach to delivering exceptional customer service. With our platform, you have the freedom to work from anywhere in the world, at any time, and even set your own rates. The platform is equipped with artificial intelligence, real-time analytics, and reporting features to help optimize performance and exceed expectations. Additionally, we offer exciting games and competitions to keep the work experience both fun and rewarding. Come sign up today to experience the freedom, flexibility, and financial independence that you deserve!</div>
        </div>
      </div>
      <div className="home-container home-overview__container">
        <div className="home-overview__reasons reveal fade">
          <div className="home-reasons">
            <div className="home-reasons__title">Work when you want.<br/>Make what you want.</div>
            <div className="home-reason">
              <div className="home-reason__title"><span className="fa fa-globe"></span>Be Free</div>
              <div className="home-reason__description">Enjoy the freedom to work from anywhere in the world, whether that's the comfort of your own home, a sandy beach, or the peak of a mountain. The possibilities are endless.</div>
            </div>
            <div className="home-reason">
              <div className="home-reason__title"><span className="fa fa-sack-dollar"></span>Get Paid</div>
              <div className="home-reason__description">Take control of your earnings and receive payments on your own terms, exactly how and when you want them.</div>
            </div>
            <div className="home-reason">
              <div className="home-reason__title"><span className="fa fa-check"></span>Succeed</div>
              <div className="home-reason__description">By leveraging real-time AI and analytics tools, identify opportunities that perfectly match your talents and interests. Whether you want to excel in sales, customer service, or any other field, advanced analytics provide the insights and guidance to help you reach your peak performance.</div>
            </div>
            <div className="home-reason">
              <div className="home-reason__title"><span className="fa fa-gamepad"></span>Have Fun</div>
              <div className="home-reason__description">By incorporating game elements into work activities, you can feel a sense of accomplishment and progress, leading to higher job satisfaction and performance. Additionally, gamification can help employees develop new skills, track your progress, and collaborate with colleagues in a fun and rewarding way.</div>
            </div>
          </div>
          <div className="home-video">
            <div className="home-video__container">
              <video className="home-video__vid" src="https://agentsremotely.com/videos/intro.mp4" poster="/images/poster.png" controls="controls" preload="auto" playsInline="playsInline"></video>
              <img className="home-video__cover" src="/images/poster.png" alt="video cover art"/>
              <span className="home-video__play button--big fa fa-play"></span>
            </div>
            <div className="home-video__question">Ready to start working on your own terms?</div><a className="button--big home-overview__button" href="/sign-up.html">Sign Up Now</a>
          </div>
        </div>
      </div>
      <div className="home-container home-how__container">
        <div className="home-how__title reveal fade trigger" trigger-index="1">Joining is Quick &amp; Easy
          <div className="home-how__sub-title">Start earning in under an hour</div>
        </div>
        <div className="home-how-flow">
          <div className="home-how-connect reveal fade-bottom"></div>
          <div className="home-how-cards reveal fade-bottom">
            <div className="home-how-card">
              <div className="home-how-card__title fa fa-user-plus"></div>
              <div className="home-how__description">Joining is a breeze and only takes a few minutes. During onboarding, you will be asked to take a brief language aptitude test. This helps us find the perfect partner to match your skills and interests.</div>
            </div>
            <div className="home-how-card">
              <div className="home-how-card__title fa fa-handshake"></div>
              <div className="home-how__description">We will suggest a list of potential partners that align with your skills. Take the time to evaluate and select the best fit. This will help ensure a successful working relationship.</div>
            </div>
            <div className="home-how-card">
              <div className="home-how-card__title fa fa-chalkboard"></div>
              <div className="home-how__description">The partner orientation program is designed to meet the individual needs of our partners; therefore, the amount of time required to complete the program may vary depending on their unique circumstances.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-container home-pay__container">
        <div className="home-pay-card--left reveal fade-left">
          <div className="home-pay-card__content--left"><img className="home-pay__img" src="/images/moneyreceived.jpg" alt="instant pay"/>
            <div>
              <div className="home-pay__title">Pay is instant and protected</div>
              <div className="home-pay__description">Payments for your services are sent to us in advance by the companies you choose to work with. This is how we protect your funds and transfer payment to your account immediately once the task is complete.</div>
              <div className="home-pay__description">Rest assured that all the money you earn through our platform is 100% yours to keep. Our revenue comes solely from businesses using our platform, not from your earnings.</div>
              <div className="home-pay__description">You have complete control over your funds with the option to withdraw any amount, as frequently or infrequently as you prefer. Whether you choose to take out all your funds or leave a portion for a later date, the decision is entirely up to you.</div>
            </div>
          </div>
        </div>
        <div className="home-pay-card--right reveal fade-right">
          <div className="home-pay-card__content--right"><img className="home-pay__img" src="/images/celebrate.jpg" alt="work at on pace"/>
            <div>
              <div className="home-pay__title">More effort, more rewards.</div>
              <div className="home-pay__description">Have the flexibility to work at a pace that suits you, whether that means putting in ten-hour days or taking it easy. You possess the power to shape the size of your compensation, as it directly corresponds to the amount of time you devote to the task.</div>
              <div className="home-pay__description">Utilizing our cutting-edge analytic system gives you access to a wide range of lucrative opportunities and earn a substantially higher income.</div>
              <div className="home-pay__description">We offer the flexibility to receive payment in a wide range of currencies, so you can choose the currency that best suits your needs.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-container home-summary__container">
        <div className="home-summary__title reveal fade">Turn your Potential into your Success.</div><a className="button--big home-overview__button reveal fade" href="/sign-up.html">Start Working Now</a>
      </div>
      <div className="home-container home-qa__container">
        <div className="home-qa">
          <div className="home-qa__title">Questions And Answers</div>
          <div className="home-qa__description">
            <div className="home-qa__question">
              <div className="question">What kind of gigs are available?</div>
              <div className="answer is-hidden">Inbound sales, inbound customer service, outbound sales, outbound customer service, and live chat</div>
            </div>
            <div className="home-qa__question">
              <div className="question">How much money can I make?</div>
              <div className="answer is-hidden">There is no cap.</div>
            </div>
            <div className="home-qa__question">
              <div className="question">How much does it cost?</div>
              <div className="answer is-hidden">Nothing! It is 100% free to join and start working.</div>
            </div>
            <div className="home-qa__question">
              <div className="question">What do I need?</div>
              <div className="answer is-hidden">A computer, internet, and a headset (if you are taking calls).</div>
            </div>
            <div className="home-qa__question">
              <div className="question">How do I get paid?</div>
              <div className="answer is-hidden">Direct deposit or Payoneer.</div>
            </div>
            <div className="home-qa__question">
              <div className="question">How long does it take to get started?</div>
              <div className="answer is-hidden">You can be making money in less than 1 hour.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img className="ai-phone" src="/images/ai-phone.png" alt="phone"/>
    <img className="ai-head" src="/images/ai-head.png" alt="ai head"/>
    </>
  );
}

export default Home;
