import React from 'react';
import UserGoals from '../components/UserGoals';
import GeneralInfo from '../components/GeneralInfo';
import useFetch from '../hooks/useFetch';
import {formatErrorMessage} from '../helper';
import { useSelector, useDispatch } from 'react-redux';
import { clear as clearGoals } from '../features/goalsSlice';

const Dashboard = () => {
  const updatedUserGoals = useSelector((state) => state.goals.value);
  const dispatch = useDispatch();
  const {apiFetch, data: userGoals, error, loading, setData: setUserGoals} = useFetch();
  
  React.useEffect(() => {
    apiFetch('GET', '/agents/{user.id}/goals');

    /*
    if(Object.keys(updatedUserGoals).length > 0) {
      dispatch(clearGoals());
    }
    */
  }, [updatedUserGoals]);

  /*
  if(userGoals && Object.keys(updatedUserGoals).length > 0) {
    setUserGoals(current => {
      const newUserGoals = [...current];
      for(let i = 0; i < newUserGoals.length; i++) {
        if(newUserGoals[i].goalId in updatedUserGoals) {
          newUserGoals[i] = updatedUserGoals[newUserGoals[i].goalId];
          delete updatedUserGoals[newUserGoals[i].goalId];
        }
      }
      for(const userGoalId in updatedUserGoals) {
        newUserGoals.push(updatedUserGoals[userGoalId]);
      }
      dispatch(clearGoals());
      return newUserGoals;
    });
  }
  */

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Dashboard</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Welcome',
            content: [
              {text: 'Agents Remotely is a streamlined platform that swiftly connects top-tier agents with various companies, ensuring efficient collaboration.'},
              {text: 'To safeguard your personal information and account security, it is crucial to establish strong passwords and activate two-factor authentication.'},
              {text: 'Our platform utilizes the information and test results you provide to generate a personalized list of companies that align with your qualifications.'},
              {text: 'To stay updated on additional opportunities, please ensure your contact information is accurate and up to date. Rest assured, you will be promptly notified whenever new opportunities arise.'},
              {text: 'For optimal convenience, we strongly recommend opting for email and/or SMS notifications, enabling you to stay informed about new opportunities as soon as they become available'},
            ]
          }
        ]}/>
        {loading ? <div className="processing"></div> : ''}
        {userGoals ? <UserGoals userGoals={userGoals}/> : ''}
        {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
      </div>
    </section>
  );
}

export default Dashboard;
