/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const agentMessages = /* GraphQL */ `
  subscription AgentMessages($agentId: ID!) {
    agentMessages(agentId: $agentId) {
      agentId
      createdAt
      eventTypeId
      message
    }
  }
`;
export const callMessages = /* GraphQL */ `
  subscription CallMessages($callId: ID!) {
    callMessages(callId: $callId) {
      callId
      createdAt
      eventTypeId
      message
      role
      sentiment
      transcript
    }
  }
`;
export const componentMessages = /* GraphQL */ `
  subscription ComponentMessages($componentId: ID!) {
    componentMessages(componentId: $componentId) {
      componentId
      createdAt
      eventTypeId
      message
    }
  }
`;
export const notifiedCallStream = /* GraphQL */ `
  subscription NotifiedCallStream($callId: ID!) {
    notifiedCallStream(callId: $callId) {
      callId
      createdAt
      role
      sentiment
      transcript
    }
  }
`;
export const notifiedPushQueue = /* GraphQL */ `
  subscription NotifiedPushQueue($agentId: ID!) {
    notifiedPushQueue(agentId: $agentId) {
      agentId
      agentStatusId
      componentId
      callId
      callComponentId
      partyConnectionTypeId
      recordingUrl
      transcriptionUrl
      phoneNumberCalled
      phoneNumberCalledFrom
      phoneCampaignNumberAssignmentTags {
        key
        value
      }
      updatedAt
    }
  }
`;
export const notifiedComponent = /* GraphQL */ `
  subscription NotifiedComponent($id: ID!) {
    notifiedComponent(id: $id) {
      id
      agentStatuses {
        id
        description
        count
      }
      callStatuses {
        id
        description
        count
      }
      updatedAt
    }
  }
`;
export const addedAgentCall = /* GraphQL */ `
  subscription AddedAgentCall($agentId: ID!) {
    addedAgentCall(agentId: $agentId) {
      agentId
      callId
      componentId
      callComponentId
      createdAt
      endedAt
    }
  }
`;
export const updatedAgentCall = /* GraphQL */ `
  subscription UpdatedAgentCall($agentId: ID!) {
    updatedAgentCall(agentId: $agentId) {
      agentId
      callId
      componentId
      callComponentId
      createdAt
      endedAt
    }
  }
`;
