import React, { useState } from 'react';

const VerbalTest = (props) => {
  const [finish, setFinish] = useState(false);

  React.useEffect(() => {
    if(finish) {
      props.onFinish();
    }
  }, [finish]);

  const onFinish = () => {
    setFinish(true);
  };

  if(!finish) {
    return (
      <>
        <div className="language-test__content">{props.test[props.practice ? 'practiceComponent' : 'component'].content}</div>
        <button className="button" onClick={onFinish}>Finish</button>
      </>
    );
  } else {
    return <div className="processing"></div>;
  }
};

export default VerbalTest;
