import React from 'react';
import { NavLink } from 'react-router-dom';
import { url } from 'agents-remotely-commons/src/formatter';

const Footer = (props) => {
  return (
    <section className="footer">
      <NavLink to={url('privacy')}>Privacy Policy</NavLink>
      <NavLink to={url('terms')}>Terms of Service</NavLink>
      <span>&copy; Agents Remotely {new Date().getFullYear()}</span>
    </section>
  );
}

export default Footer;
