'use strict'

module.exports = {
  NORMAL : 1,
  TEST : 2,
  INTERVIEW : 3,
  PUSH_QUEUE : 4, //change agent status to busy and pushes call to agents
  PULL_QUEUE : 5, //doesn't change agent status allows agents to ask for call (useful for voicemail)
  ids : {
    '1' : 'Normal',
    '2' : 'Test',
    '3' : 'Interview',
    '4' : 'Push Queue',
    '5' : 'Pull Queue',
  },
};
