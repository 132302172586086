'use strict'

module.exports = {
  PLATFORM : 1,
  AGENTS : 2,
  ids : {
    '1' : 'Platform',
    '2' : 'Agents',
  },
};
