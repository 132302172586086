'use strict'

const ids = {
  '1': 'Starts With',
  '2': 'Contains',
  '3': 'Ends With',
};

const values = {};
for(const id in ids) {
  values[ids[id]] = parseInt(id);
}

module.exports = {
  STARTS_WITH: 1,
  CONTAINS: 2,
  ENDS_WITH: 3,
  ids: ids,
  values: values,
};
