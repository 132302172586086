import React from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { currency, url } from 'agents-remotely-commons/src/formatter';
import { useAuth } from '../hooks/useAuth';
import { useSelector } from 'react-redux';

const Navbar = (props) => {
  const tokens = useSelector((state) => state.tokens.value);
  const money = useSelector((state) => state.money.value);
  const { roles, user, signOut } = useAuth();

  if(!user?.idToken) {
    return <Navigate to={url('home')}/>;
  }

  return (
    <section className="nav">
      <div className="nav-menu">
        <NavLink className={({isActive}) => isActive ? "nav__item-active nav__item" : 'nav__item'} to={url('dashboard')}>Dashboard</NavLink>
        {roles.verified ? <NavLink className={({isActive}) => isActive ? "nav__item-active nav__item" : 'nav__item'} to={url('tests')}>Tests</NavLink> : ''}
        {roles.verified ? <NavLink className={({isActive}) => isActive ? "nav__item-active nav__item" : 'nav__item'} to={url('jobs')}>Jobs</NavLink> : ''}
        {roles.hired ? <>
          <NavLink className={({isActive}) => isActive ? "nav__item-active nav__item" : 'nav__item'} to={url('work')}>Work</NavLink>
          <div className="nav__item">
            <div className="nav__item-header">Reports</div>
            <div className="nav__item-menu">
              <NavLink className="nav__sub-item" to={url('callReport')}>Calls</NavLink>
              <NavLink className="nav__sub-item" to={url('paymentReport')}>Payment</NavLink>
              <NavLink className="nav__sub-item" to={url('summaryReport')}>Summary</NavLink>
            </div>
          </div>
        </> : ''}
      </div>
      <div className="nav-icons">
        {roles.verified && false ? <NavLink className="badge-icon nav__sub-item fa fa-award" to={url('badges')}></NavLink> : ''}
        {roles.verified && false ? <NavLink className="token-icon nav__sub-item fa fa-coin" to={url('tokenCenter')}><div className="nav-tokens">{tokens}</div></NavLink> : ''}
        {roles.verified ? <NavLink className="money-icon nav__sub-item fa fa-sack-dollar" to={url('paymentCenter')}><div className="nav-money">{currency(money)}</div></NavLink> : ''}
        <div className="nav__item nav-icon">
          <div className="nav__item-header fa fa-user"></div>
          <div className="nav__item-header fa fa-cog"></div>
          <div className="nav__item-menu nav__item-menu-right">
            <NavLink className="nav__sub-item" to={url('accountSettings')}>Account Settings</NavLink>
            {roles.verified ? <NavLink className="nav__sub-item" to={url('availability')}>Availability & Notifications</NavLink> : ''}
            {roles.verified ? <NavLink className="nav__sub-item" to={url('workSettings')}>Experience & Interests</NavLink> : ''}
            {roles.verified ? <NavLink className="nav__sub-item" to={url('paymentMethods')}>Payment Settings</NavLink> : ''}
            {roles.verified ? <NavLink className="nav__sub-item" to={url('personalInfo')}>Personal Info</NavLink> : ''}
            <span className="nav__sub-item nav__sign-out" onClick={signOut}>Sign Out</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Navbar;
