'use strict'

module.exports = {
  ids : {
    '1' : 'Africa/Abidjan',
    '2' : 'Africa/Accra',
    '3' : 'Africa/Algiers',
    '4' : 'Africa/Bissau',
    '5' : 'Africa/Cairo',
    '6' : 'Africa/Casablanca',
    '7' : 'Africa/Ceuta',
    '8' : 'Africa/El Aaiun',
    '9' : 'Africa/Johannesburg',
    '10' : 'Africa/Juba',
    '11' : 'Africa/Khartoum',
    '12' : 'Africa/Lagos',
    '13' : 'Africa/Maputo',
    '14' : 'Africa/Monrovia',
    '15' : 'Africa/Nairobi',
    '16' : 'Africa/Ndjamena',
    '17' : 'Africa/Sao Tome',
    '18' : 'Africa/Tripoli',
    '19' : 'Africa/Tunis',
    '20' : 'Africa/Windhoek',
    '21' : 'America/Adak',
    '22' : 'America/Anchorage',
    '23' : 'America/Araguaina',
    '24' : 'America/Argentina/Buenos Aires',
    '25' : 'America/Argentina/Catamarca',
    '26' : 'America/Argentina/Cordoba',
    '27' : 'America/Argentina/Jujuy',
    '28' : 'America/Argentina/La Rioja',
    '29' : 'America/Argentina/Mendoza',
    '30' : 'America/Argentina/Rio Gallegos',
    '31' : 'America/Argentina/Salta',
    '32' : 'America/Argentina/San Juan',
    '33' : 'America/Argentina/San Luis',
    '34' : 'America/Argentina/Tucuman',
    '35' : 'America/Argentina/Ushuaia',
    '36' : 'America/Asuncion',
    '37' : 'America/Atikokan',
    '38' : 'America/Bahia',
    '39' : 'America/Bahia Banderas',
    '40' : 'America/Barbados',
    '41' : 'America/Belem',
    '42' : 'America/Belize',
    '43' : 'America/Blanc-Sablon',
    '44' : 'America/Boa Vista',
    '45' : 'America/Bogota',
    '46' : 'America/Boise',
    '47' : 'America/Cambridge Bay',
    '48' : 'America/Campo Grande',
    '49' : 'America/Cancun',
    '50' : 'America/Caracas',
    '51' : 'America/Cayenne',
    '52' : 'America/Chicago',
    '53' : 'America/Chihuahua',
    '54' : 'America/Costa Rica',
    '55' : 'America/Creston',
    '56' : 'America/Cuiaba',
    '57' : 'America/Curacao',
    '58' : 'America/Danmarkshavn',
    '59' : 'America/Dawson',
    '60' : 'America/Dawson Creek',
    '61' : 'America/Denver',
    '62' : 'America/Detroit',
    '63' : 'America/Edmonton',
    '64' : 'America/Eirunepe',
    '65' : 'America/El Salvador',
    '66' : 'America/Fort Nelson',
    '67' : 'America/Fortaleza',
    '68' : 'America/Glace Bay',
    '69' : 'America/Goose Bay',
    '70' : 'America/Grand Turk',
    '71' : 'America/Guatemala',
    '72' : 'America/Guayaquil',
    '73' : 'America/Guyana',
    '74' : 'America/Halifax',
    '75' : 'America/Havana',
    '76' : 'America/Hermosillo',
    '77' : 'America/Indiana/Indianapolis',
    '78' : 'America/Indiana/Knox',
    '79' : 'America/Indiana/Marengo',
    '80' : 'America/Indiana/Petersburg',
    '81' : 'America/Indiana/Tell City',
    '82' : 'America/Indiana/Vevay',
    '83' : 'America/Indiana/Vincennes',
    '84' : 'America/Indiana/Winamac',
    '85' : 'America/Inuvik',
    '86' : 'America/Iqaluit',
    '87' : 'America/Jamaica',
    '88' : 'America/Juneau',
    '89' : 'America/Kentucky/Louisville',
    '90' : 'America/Kentucky/Monticello',
    '91' : 'America/La Paz',
    '92' : 'America/Lima',
    '93' : 'America/Los Angeles',
    '94' : 'America/Maceio',
    '95' : 'America/Managua',
    '96' : 'America/Manaus',
    '97' : 'America/Martinique',
    '98' : 'America/Matamoros',
    '99' : 'America/Mazatlan',
    '100' : 'America/Menominee',
    '101' : 'America/Merida',
    '102' : 'America/Metlakatla',
    '103' : 'America/Mexico City',
    '104' : 'America/Miquelon',
    '105' : 'America/Moncton',
    '106' : 'America/Monterrey',
    '107' : 'America/Montevideo',
    '108' : 'America/Nassau',
    '109' : 'America/New York',
    '110' : 'America/Nipigon',
    '111' : 'America/Nome',
    '112' : 'America/Noronha',
    '113' : 'America/North Dakota/Beulah',
    '114' : 'America/North Dakota/Center',
    '115' : 'America/North Dakota/New Salem',
    '116' : 'America/Nuuk',
    '117' : 'America/Ojinaga',
    '118' : 'America/Panama',
    '119' : 'America/Pangnirtung',
    '120' : 'America/Paramaribo',
    '121' : 'America/Phoenix',
    '122' : 'America/Port-au-Prince',
    '123' : 'America/Port of Spain',
    '124' : 'America/Porto Velho',
    '125' : 'America/Puerto Rico',
    '126' : 'America/Punta Arenas',
    '127' : 'America/Rainy River',
    '128' : 'America/Rankin Inlet',
    '129' : 'America/Recife',
    '130' : 'America/Regina',
    '131' : 'America/Resolute',
    '132' : 'America/Rio Branco',
    '133' : 'America/Santarem',
    '134' : 'America/Santiago',
    '135' : 'America/Santo Domingo',
    '136' : 'America/Sao Paulo',
    '137' : 'America/Scoresbysund',
    '138' : 'America/Sitka',
    '139' : 'America/St Johns',
    '140' : 'America/Swift Current',
    '141' : 'America/Tegucigalpa',
    '142' : 'America/Thule',
    '143' : 'America/Thunder Bay',
    '144' : 'America/Tijuana',
    '145' : 'America/Toronto',
    '146' : 'America/Vancouver',
    '147' : 'America/Whitehorse',
    '148' : 'America/Winnipeg',
    '149' : 'America/Yakutat',
    '150' : 'America/Yellowknife',
    '151' : 'Antarctica/Casey',
    '152' : 'Antarctica/Davis',
    '153' : 'Antarctica/DumontDUrville',
    '154' : 'Antarctica/Macquarie',
    '155' : 'Antarctica/Mawson',
    '156' : 'Antarctica/Palmer',
    '157' : 'Antarctica/Rothera',
    '158' : 'Antarctica/Syowa',
    '159' : 'Antarctica/Troll',
    '160' : 'Antarctica/Vostok',
    '161' : 'Asia/Almaty',
    '162' : 'Asia/Amman',
    '163' : 'Asia/Anadyr',
    '164' : 'Asia/Aqtau',
    '165' : 'Asia/Aqtobe',
    '166' : 'Asia/Ashgabat',
    '167' : 'Asia/Atyrau',
    '168' : 'Asia/Baghdad',
    '169' : 'Asia/Baku',
    '170' : 'Asia/Bangkok',
    '171' : 'Asia/Barnaul',
    '172' : 'Asia/Beirut',
    '173' : 'Asia/Bishkek',
    '174' : 'Asia/Brunei',
    '175' : 'Asia/Chita',
    '176' : 'Asia/Choibalsan',
    '177' : 'Asia/Colombo',
    '178' : 'Asia/Damascus',
    '179' : 'Asia/Dhaka',
    '180' : 'Asia/Dili',
    '181' : 'Asia/Dubai',
    '182' : 'Asia/Dushanbe',
    '183' : 'Asia/Famagusta',
    '184' : 'Asia/Gaza',
    '185' : 'Asia/Hebron',
    '186' : 'Asia/Ho Chi Minh',
    '187' : 'Asia/Hong Kong',
    '188' : 'Asia/Hovd',
    '189' : 'Asia/Irkutsk',
    '190' : 'Asia/Jakarta',
    '191' : 'Asia/Jayapura',
    '192' : 'Asia/Jerusalem',
    '193' : 'Asia/Kabul',
    '194' : 'Asia/Kamchatka',
    '195' : 'Asia/Karachi',
    '196' : 'Asia/Kathmandu',
    '197' : 'Asia/Khandyga',
    '198' : 'Asia/Kolkata',
    '199' : 'Asia/Krasnoyarsk',
    '200' : 'Asia/Kuala Lumpur',
    '201' : 'Asia/Kuching',
    '202' : 'Asia/Macau',
    '203' : 'Asia/Magadan',
    '204' : 'Asia/Makassar',
    '205' : 'Asia/Manila',
    '206' : 'Asia/Nicosia',
    '207' : 'Asia/Novokuznetsk',
    '208' : 'Asia/Novosibirsk',
    '209' : 'Asia/Omsk',
    '210' : 'Asia/Oral',
    '211' : 'Asia/Pontianak',
    '212' : 'Asia/Pyongyang',
    '213' : 'Asia/Qatar',
    '214' : 'Asia/Qostanay',
    '215' : 'Asia/Qyzylorda',
    '216' : 'Asia/Riyadh',
    '217' : 'Asia/Sakhalin',
    '218' : 'Asia/Samarkand',
    '219' : 'Asia/Seoul',
    '220' : 'Asia/Shanghai',
    '221' : 'Asia/Singapore',
    '222' : 'Asia/Srednekolymsk',
    '223' : 'Asia/Taipei',
    '224' : 'Asia/Tashkent',
    '225' : 'Asia/Tbilisi',
    '226' : 'Asia/Tehran',
    '227' : 'Asia/Thimphu',
    '228' : 'Asia/Tokyo',
    '229' : 'Asia/Tomsk',
    '230' : 'Asia/Ulaanbaatar',
    '231' : 'Asia/Urumqi',
    '232' : 'Asia/Ust-Nera',
    '233' : 'Asia/Vladivostok',
    '234' : 'Asia/Yakutsk',
    '235' : 'Asia/Yangon',
    '236' : 'Asia/Yekaterinburg',
    '237' : 'Asia/Yerevan',
    '238' : 'Atlantic/Azores',
    '239' : 'Atlantic/Bermuda',
    '240' : 'Atlantic/Canary',
    '241' : 'Atlantic/Cape Verde',
    '242' : 'Atlantic/Faroe',
    '243' : 'Atlantic/Madeira',
    '244' : 'Atlantic/Reykjavik',
    '245' : 'Atlantic/South Georgia',
    '246' : 'Atlantic/Stanley',
    '247' : 'Australia/Adelaide',
    '248' : 'Australia/Brisbane',
    '249' : 'Australia/Broken Hill',
    '250' : 'Australia/Darwin',
    '251' : 'Australia/Eucla',
    '252' : 'Australia/Hobart',
    '253' : 'Australia/Lindeman',
    '254' : 'Australia/Lord Howe',
    '255' : 'Australia/Melbourne',
    '256' : 'Australia/Perth',
    '257' : 'Australia/Sydney',
    /*
    '258' : 'Etc/GMT',
    '259' : 'Etc/GMT+1',
    '260' : 'Etc/GMT+10',
    '261' : 'Etc/GMT+11',
    '262' : 'Etc/GMT+12',
    '263' : 'Etc/GMT+2',
    '264' : 'Etc/GMT+3',
    '265' : 'Etc/GMT+4',
    '266' : 'Etc/GMT+5',
    '267' : 'Etc/GMT+6',
    '268' : 'Etc/GMT+7',
    '269' : 'Etc/GMT+8',
    '270' : 'Etc/GMT+9',
    '271' : 'Etc/GMT-1',
    '272' : 'Etc/GMT-10',
    '273' : 'Etc/GMT-11',
    '274' : 'Etc/GMT-12',
    '275' : 'Etc/GMT-13',
    '276' : 'Etc/GMT-14',
    '277' : 'Etc/GMT-2',
    '278' : 'Etc/GMT-3',
    '279' : 'Etc/GMT-4',
    '280' : 'Etc/GMT-5',
    '281' : 'Etc/GMT-6',
    '282' : 'Etc/GMT-7',
    '283' : 'Etc/GMT-8',
    '284' : 'Etc/GMT-9',
    '285' : 'Etc/UTC',
    */
    '286' : 'Europe/Amsterdam',
    '287' : 'Europe/Andorra',
    '288' : 'Europe/Astrakhan',
    '289' : 'Europe/Athens',
    '290' : 'Europe/Belgrade',
    '291' : 'Europe/Berlin',
    '292' : 'Europe/Brussels',
    '293' : 'Europe/Bucharest',
    '294' : 'Europe/Budapest',
    '295' : 'Europe/Chisinau',
    '296' : 'Europe/Copenhagen',
    '297' : 'Europe/Dublin',
    '298' : 'Europe/Gibraltar',
    '299' : 'Europe/Helsinki',
    '300' : 'Europe/Istanbul',
    '301' : 'Europe/Kaliningrad',
    '302' : 'Europe/Kiev',
    '303' : 'Europe/Kirov',
    '304' : 'Europe/Lisbon',
    '305' : 'Europe/London',
    '306' : 'Europe/Luxembourg',
    '307' : 'Europe/Madrid',
    '308' : 'Europe/Malta',
    '309' : 'Europe/Minsk',
    '310' : 'Europe/Monaco',
    '311' : 'Europe/Moscow',
    '312' : 'Europe/Oslo',
    '313' : 'Europe/Paris',
    '314' : 'Europe/Prague',
    '315' : 'Europe/Riga',
    '316' : 'Europe/Rome',
    '317' : 'Europe/Samara',
    '318' : 'Europe/Saratov',
    '319' : 'Europe/Simferopol',
    '320' : 'Europe/Sofia',
    '321' : 'Europe/Stockholm',
    '322' : 'Europe/Tallinn',
    '323' : 'Europe/Tirane',
    '324' : 'Europe/Ulyanovsk',
    '325' : 'Europe/Uzhgorod',
    '326' : 'Europe/Vienna',
    '327' : 'Europe/Vilnius',
    '328' : 'Europe/Volgograd',
    '329' : 'Europe/Warsaw',
    '330' : 'Europe/Zaporozhye',
    '331' : 'Europe/Zurich',
    '332' : 'Factory',
    '333' : 'Indian/Chagos',
    '334' : 'Indian/Christmas',
    '335' : 'Indian/Cocos',
    '336' : 'Indian/Kerguelen',
    '337' : 'Indian/Mahe',
    '338' : 'Indian/Maldives',
    '339' : 'Indian/Mauritius',
    '340' : 'Indian/Reunion',
    '341' : 'Pacific/Apia',
    '342' : 'Pacific/Auckland',
    '343' : 'Pacific/Bougainville',
    '344' : 'Pacific/Chatham',
    '345' : 'Pacific/Chuuk',
    '346' : 'Pacific/Easter',
    '347' : 'Pacific/Efate',
    '348' : 'Pacific/Enderbury',
    '349' : 'Pacific/Fakaofo',
    '350' : 'Pacific/Fiji',
    '351' : 'Pacific/Funafuti',
    '352' : 'Pacific/Galapagos',
    '353' : 'Pacific/Gambier',
    '354' : 'Pacific/Guadalcanal',
    '355' : 'Pacific/Guam',
    '356' : 'Pacific/Honolulu',
    '357' : 'Pacific/Kiritimati',
    '358' : 'Pacific/Kosrae',
    '359' : 'Pacific/Kwajalein',
    '360' : 'Pacific/Majuro',
    '361' : 'Pacific/Marquesas',
    '362' : 'Pacific/Nauru',
    '363' : 'Pacific/Niue',
    '364' : 'Pacific/Norfolk',
    '365' : 'Pacific/Noumea',
    '366' : 'Pacific/Pago Pago',
    '367' : 'Pacific/Palau',
    '368' : 'Pacific/Pitcairn',
    '369' : 'Pacific/Pohnpei',
    '370' : 'Pacific/Port Moresby',
    '371' : 'Pacific/Rarotonga',
    '372' : 'Pacific/Tahiti',
    '373' : 'Pacific/Tarawa',
    '374' : 'Pacific/Tongatapu',
    '375' : 'Pacific/Wake',
    '376' : 'Pacific/Wallis',
  },
};
