import React from 'react';
import { NavLink } from 'react-router-dom';
import { currency, url } from 'agents-remotely-commons/src/formatter';
import GeneralInfo from '../../components/GeneralInfo';
import useFetch from '../../hooks/useFetch';
import {formatErrorMessage} from '../../helper';
import { update as updateMoney } from '../../features/moneySlice';
import { useDispatch } from 'react-redux';

const Payments = () => {
  const {apiFetch, data: agent, error, loading} = useFetch();
  const dispatch = useDispatch();

  React.useEffect(() => {
    apiFetch('GET', '/agents/{user.id}', {
      select: ['money'],
    });
  }, []);

  if(agent) {
    dispatch(updateMoney(agent.money));
  }

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Payment Center</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Payment Center',
            content: [
              {text: 'Your all-in-one hub for financial control.'},
              {text: 'Instant withdrawals, customizable schedules, and easy payment method management.'},
              {text: 'Access your payment history with ease. Take charge of your finances today.'},
            ]
          }
        ]}/>
        {loading ? <div className="processing"></div> : ''}
        {agent ? 
          <div className="payment-center">
            <div className="payment-center__title">
              You have <span className="payment-center__amount"> {currency(agent.money)}</span> in your wallet.
            </div>
            <div className="cards">
              <div className="card">
                <div className="card__title">
                  <span className="card__icon fa fa-file-export"></span>
                  <NavLink className="card__link" to={url('paymentWithdrawal')}>Withdrawl</NavLink>
                </div>
                <div className="card__description">
                  Setup the schedule that works best for you, or perform an instant withdrawal.
                </div>
              </div>
              <div className="card">
                <div className="card__title">
                  <span className="card__icon fa fa-wallet"></span>
                  <NavLink className="card__link" to={url('paymentMethods')}>Manage Payment Methods</NavLink>
                </div>
                <div className="card__description">
                  Add or update payment methods including Wise, Payoneer, or bank accounts.
                </div>
              </div>
              <div className="card">
                <div className="card__title">
                  <span className="card__icon fa fa-chart-pie"></span>
                  <NavLink className="card__link" to={url('paymentReport')}>View Reports</NavLink>
                </div>
                <div className="card__description">
                  View earnings and payment history.
                </div>
              </div>
            </div>
          </div>
          : ''
        }
        {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
      </div>
    </section>
  )
}

export default Payments;
