import React from 'react';
import GeneralInfo from '../../components/GeneralInfo';
import TextInput from '../../components/TextInput';
import QRCode from 'react-qr-code';
import { url } from 'agents-remotely-commons/src/formatter';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFetch from '../../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { formatErrorMessage } from '../../helper';
import { useAuth } from '../../hooks/useAuth';

const AccountMfa = () => {
  const { user } = useAuth();
  const {apiFetch: verificationFetch, data: verification, error: verificationError, loading: verificationLoading} = useFetch();
  const {apiFetch: tokenFetch, data: token, error: tokenError, loading: tokenLoading} = useFetch();
  const navigate = useNavigate();

  React.useEffect(() => {
    tokenFetch('GET', '/agents/{user.id}/mfa', {accessToken: '{user.accessToken}'});
  }, []);

  if(verification) {
    navigate(new URLSearchParams(window.location.search).get('redirect') || url('accountSettings'));
  }
  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Configure 2 Factor Authentication</h1>
      </div>
      <div className="content__container-full">
        {tokenLoading ? <div className="processing"></div> : ''}
        {tokenError ? <div className="error-message">{formatErrorMessage(tokenError)}</div> : ''}
        {token ?
          <div className="mfa-form content__form-full">
            <Formik
              validateOnChange={false}
              initialValues={{
                verificationCode: '',
              }}
              validationSchema={Yup.object({
                verificationCode: Yup.string()
                  .matches(/^[0-9]+$/, "Must be only digits")
                  .min(6, 'Must be exactly 6 digits')
                  .max(6, 'Must be exactly 6 digits'),
              })}
              onSubmit={(values) => {
                verificationFetch('POST', '/agents/{user.id}/mfa', {...values, accessToken: '{user.accessToken}'});
              }}
            >
              <Form className="form mfa">
                {verificationError ? <div className="form__error">{formatErrorMessage(verificationError)}</div> : ''}
                <div>
                  We require all users to use MFA to help protect accounts and personal information. Once configured, you'll be required to enter a code created by the&nbsp;
                  <a href="https://m.google.com/authenticator" target="_blank">Google Authenticator</a>,&nbsp;
                  <a href="http://guide.duosecurity.com/" target="_blank">Duo Mobile</a>,&nbsp;
                  <a href="https://www.authy.com/" target="_blank">Authy</a>, or&nbsp;
                  <a href="https://nam06.safelinks.protection.outlook.com/?url=https%3A%2F%2Fapp.adjust.com%2Fh66ftb_42hbak%3Fcampaign%3Dappstore_ios%26fallback%3Dhttps%3A%2F%2Fitunes.apple.com%2Fapp%2Fmicrosoft-authenticator%2Fid983156458&amp;data=02%7C01%7Carielsc%40microsoft.com%7C05a23fbb0d7545043ac708d7f38fe9e1%7C72f988bf86f141af91ab2d7cd011db47%7C1%7C0%7C637245671762583009&amp;sdata=Cael7o0lxwBAl7klGANd2hFy%2Fc%2BWoa3EbKmWAXNfL%2F0%3D&amp;reserved=0" target="_blank">Microsoft Authenticator</a> app in order to sign into your Agents Remotely account.
                </div>
                <div className="mfa__section">
                  <img className="mfa__section-left" src="../images/configure2fa_step1@2x.png"/>
                  <div>
                    <p className="mfa__section-step">Step 1</p>
                    <p className="mfa__section-title">Get the App</p>
                    <p>
                      Download and install the&nbsp;
                      <a href="https://m.google.com/authenticator" target="_blank">Google Authenticator</a>,&nbsp;
                      <a href="http://guide.duosecurity.com/third-party-accounts" target="_blank">Duo Mobile</a>,&nbsp;
                      <a href="https://www.authy.com/" target="_blank">Authy</a>, or&nbsp;
                      <a href="https://nam06.safelinks.protection.outlook.com/?url=https%3A%2F%2Fapp.adjust.com%2Fh66ftb_42hbak%3Fcampaign%3Dappstore_ios%26fallback%3Dhttps%3A%2F%2Fitunes.apple.com%2Fapp%2Fmicrosoft-authenticator%2Fid983156458&amp;data=02%7C01%7Carielsc%40microsoft.com%7C05a23fbb0d7545043ac708d7f38fe9e1%7C72f988bf86f141af91ab2d7cd011db47%7C1%7C0%7C637245671762583009&amp;sdata=Cael7o0lxwBAl7klGANd2hFy%2Fc%2BWoa3EbKmWAXNfL%2F0%3D&amp;reserved=0" target="_blank">Microsoft Authenticator</a> app for your phone or tablet.
                    </p>
                  </div>
                </div>
                <div className="mfa__section">
                  <div className="mfa__section-left">
                    <QRCode value={'otpauth://totp/AgentsRemotely:' + user.email + '?secret=' + token.secretCode + '&issuer=AgentsRemotely.com'} />
                  </div>
                  <div>
                    <p className="mfa__section-step">Step 2</p>
                    <p className="mfa__section-title">Scan this Barcode</p>
                    <p>Open the authentication app and:</p>
                    <ul className="mfa__section-list">
                      <li>Tap the "+" icon in the top-right of the app</li>
                      <li>Scan the image to the left, using your phone's camera</li>
                    </ul>
                    <p>
                      (<a href="https://hotelplannertech.slack.com/account/settings/2fa_app#" onClick={(e) => {e.preventDefault(); document.querySelector('.mfa__section-no-scan').classList.toggle('mfa__section-no-scan--show'); return false;}}>Can't scan this barcode?</a>)
                    </p>
                    <div className="mfa__section-no-scan hidden">
                      <p>Instead of scanning, use your authentication app's "Manual entry" or equivalent option and provide the following time-based key. (Lower-case letters will work, too.)</p><code id="init_key_code" className="init_2fa_key">{token.secretCode}</code>
                      <p>Your app will then generate a 6-digit verification code, which you use below.</p>
                    </div>
                  </div>
                </div>
                <div className="mfa__section">
                  <img className="mfa__section-left" src="../images/configure2fa_step3@2x.png"/>
                  <div>
                    <p className="mfa__section-step">Step 3</p>
                    <p className="mfa__section-title">Enter Verification Code</p>
                    <p>Once the barcode above is scanned, enter the 6-digit verification code generated by the app.</p>
                    <div className="form__line">
                      <TextInput
                        formName="mfa"
                        placeholder="Code"
                        name="verificationCode"
                        type="tel"
                        autoFocus={true}
                        autoComplete="off"
                        autoCorrect="off"
                        spellCheck="false"
                        required="required"
                      />
                    </div>
                    <div className="form__line">
                      {verificationError ? <div className="form__error">{formatErrorMessage(verificationError)}</div> : ''}
                      {verificationLoading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Verify Code & Activate</button>}
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
          : ''
        }
      </div>
    </section>
  );
}

export default AccountMfa;
