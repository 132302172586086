import React, { useState } from 'react';
import GeneralInfo from '../../components/GeneralInfo';
import useFetch from '../../hooks/useFetch';
import {formatErrorMessage} from '../../helper';
import { currency } from 'agents-remotely-commons/src/formatter';
import { update as updateMoney } from '../../features/moneySlice';
import { useDispatch } from 'react-redux';

const PaymentWithdrawal = () => {
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const {apiFetch: agentFetch, data: agent, error: agentError, loading: agentLoading} = useFetch();
  const {apiFetch: withdrawalFetch, data: withdrawal, error: withdrawalError, loading: withdrawalLoading} = useFetch();
  const dispatch = useDispatch();
  let amount = 0;

  React.useEffect(() => {
    agentFetch('GET', '/agents/{user.id}', {
      select: ['money'],
    });
  }, [withdrawalAmount]);

  if(agent) {
    dispatch(updateMoney(agent.money));
    amount = agent.money;
  }

  const withdrawalSubmit = () => {
    withdrawalFetch('DELETE', '/agents/{user.id}/wallet/' + agent.money);
  };

  const renderWithdrawal = () => {
    if(withdrawalLoading) {
      return <div className="processing"></div>;
    } else {
      if(withdrawalAmount) {
        return <div>Succesfully withdrew {withdrawalAmount}</div>;
      } else if(withdrawal) {
        setWithdrawalAmount(amount);
      } else {
        if(agent.money > 0) {
          return <div>Withdrawal options coming Soon</div>
          //return <button className="button" onClick={withdrawalSubmit}>Withdrawal</button>;
        }
      }
    }
  }

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Payment Withdrawal</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Payment Withdrawal',
            content: [
              {text: 'This is your hub to control every regarding your money.'},
              {text: 'Perform an instant withdrawal or setup a schedule'},
              {text: 'Add or modify payment methods'},
              {text: 'See your payment history'},
            ]
          }
        ]}/>
        {agentLoading ? <div className="processing"></div> : ''}
        {agent ? 
          <div>
            <div>
              You have {currency(agent.money)} Available for withdrawal.
            </div>
            {renderWithdrawal()}
            {withdrawalError ? <div className="error-message">{formatErrorMessage(withdrawalError)}</div> : ''}
          </div>
          : ''
        }
        {agentError ? <div className="error-message">{formatErrorMessage(agentError)}</div> : ''}
      </div>
    </section>
  )
}

export default PaymentWithdrawal;
