'use strict'

module.exports = {
  BUSINESS : 1,
  PERSONAL : 2,
  ids : {
    '1' : 'Business',
    '2' : 'Personal',
  },
};
