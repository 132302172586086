import React from 'react';
import TextInput from '../components/TextInput';
import CheckBoxInput from './CheckBoxInput';
import PhoneNumberInput from '../components/PhoneNumberInput';
import Tooltip from '../components/Tooltip';
import { NavLink } from 'react-router-dom';
import { url } from 'agents-remotely-commons/src/formatter';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { formatErrorMessage } from '../helper';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const SignUpForm = () => {
  const {apiFetch, data: user, error, loading} = useFetch();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  React.useEffect(() => {
    if(user) {
      navigate(url('confirmAccount', {email: user.email}));
    }
  }, [user]);

  return (
    <div className="sign-up-form content__form">
      <Formik
        validateOnChange={false}
        initialValues={{
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          phoneDialCode: '1',
          phoneCountryCode: 'US',
          agreeToTerms: false,
          notificationsSmsAvailable: true,
          notificationsWhatsAppAvailable: false,
        }}
        validationSchema={Yup.object({
          email: Yup.string().email('Email is invalid').required('Email is required'),
          password: Yup.string()
            .required('Password is required')
            .matches(/^.{8,}$/, 'Password is invalid'),
          firstName: Yup.string()
            .required('First name is required')
            .matches(/^([A-z0-9À-ž '-]){2,}$/, 'First name is invalid'),
          lastName: Yup.string()
            .required('Last name is required')
            .matches(/^([A-z0-9À-ž '-]){2,}$/, 'Last name is invalid'),
          phoneNumber: Yup.string()
            .required('Phone number is required')
            .min(10, 'Phone number must be at least 10 digits'),
          agreeToTerms: Yup.boolean()
            .oneOf([true], 'Must accept terms'),
          notificationsSmsAvailable: Yup.boolean(),
          notificationsWhatsAppAvailable: Yup.boolean()
            .when('notificationsSmsAvailable', {
              is: true,
              then: (schema) => schema.oneOf([true,false], 'Must be able to receive SMS or WhatsApp'),
              otherwise: (schema) => schema.oneOf([true], 'Must be able to receive SMS or WhatsApp'),
            }),
        })}
        onSubmit={async (values) => {
          const token = await executeRecaptcha();
          if(values.phoneNumber.indexOf('+') === 0) {
            values.phoneNumber = values.phoneNumber.substring(values.phoneDialCode.length + 1);
          }
          apiFetch('POST', '/agents', {...values, recaptchaResponse: token});
        }}
      >
        {({ setFieldValue , isSubmitting }) => (
          <Form className="form sign-up">
            {error ?
              <div className="form__error">
                {formatErrorMessage(error)}
                {error === 'An account with the given email already exists.' ? <>&nbsp;&nbsp;<NavLink className="form__link confirm-account__resend" to={url('signIn')}>Click here</NavLink> to login.</> : ''}
              </div>
            : ''}
            <div className="form__line">
              <TextInput
                formName="sign-up"
                label="Email"
                name="email"
                type="email"
                autoComplete="email"
                autoCorrect="off"
                spellCheck="false"
                required="required"
              />
            </div>
            <div className="form__line">
              <TextInput
                formName="sign-up"
                label="First Name"
                name="firstName"
                type="text"
                autoComplete="given-name"
                autoCorrect="off"
                spellCheck="false"
                pattern="^([A-z0-9À-ž '-]){2,}$"
                required="required"
              />
            </div>
            <div className="form__line">
              <TextInput
                formName="sign-up"
                label="Last Name"
                name="lastName"
                type="text"
                autoComplete="family-name"
                autoCorrect="off"
                spellCheck="false"
                pattern="^([A-z0-9À-ž '-]){2,}$"
                required="required"
              />
            </div>
            <div className="form__line">
              <PhoneNumberInput
                formName="sign-up"
                label="Phone Number"
                name="phoneNumber"
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="form__line">
              <TextInput
                formName="sign-up"
                label="Password"
                labelJsx=<Tooltip text="Min 8 characters. Must include numeric, special, lower and uppercase characters."/>
                name="password"
                type="password"
                autoComplete="new-password"
                autoCorrect="off"
                spellCheck="false"
                pattern="^.{8,}$"
                required="required"
              />
            </div>
            <div className="form__line">
              You must be able to receive SMS or messages in WhatsApp in order to complete the sign up process.  Please mark which ones you can receive.
            </div>
            <div className="form__line">
              <CheckBoxInput formName="sign-up" label="SMS" name="notificationsSmsAvailable"/>
            </div>
            <div className="form__line">
              <CheckBoxInput formName="sign-up" label="WhatsApp" name="notificationsWhatsAppAvailable"/>
            </div>
            <div className="form__line">
              By signing up you agree to the the Terms of Service
            </div>
            <div className="form__line">
              <CheckBoxInput formName="sign-up" label="Yes I Agree" name="agreeToTerms"/>
            </div>
            <div className="form__line">
              {loading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Sign Up</button>}
            </div>
            <div className="form__line">Already have an account? <NavLink className="form__link" to={url('signIn')}>Sign in</NavLink></div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SignUpForm;
