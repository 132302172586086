'use strict'

const ids = {
  '1' : 'Invited',
  '2' : 'Awaiting Acceptance',
  '3' : 'Accepted',
  '4' : 'Awaiting Training',
  '5' : 'Training',
  '6' : 'Trained',
  '7' : 'Awaiting Verification',
  '8' : 'Verified',
  '9' : 'Quit',
  '10' : 'Removed',
  '11' : 'Suspended',
  '12' : 'Offline',
  '13' : 'Online',
  '14' : 'Pending Work',
  '15' : 'Busy',
  '16' : 'Actively Working',
  '17' : 'Declined',
  '18' : 'Countered',
};

const values = {};
for(const id in ids) {
  values[ids[id]] = parseInt(id);
}

module.exports = {
  INVITED : 1,
  AWAITING_ACCEPTANCE : 2,
  ACCEPTED : 3,
  AWAITING_TRAINING : 4,
  TRAINING : 5,
  TRAINED : 6,
  AWAITING_VERIFICATION : 7,
  VERIFIED : 8,
  QUIT : 9,
  REMOVED : 10,
  SUSPENDED : 11,
  OFFLINE : 12,
  ONLINE : 13,
  PENDING_WORK : 14,
  BUSY : 15,
  ACTIVELY_WORKING : 16,
  DECLINED : 17,
  COUNTERED : 18,
  CAN_WORK : [12, 13, 14, 15, 16],
  WORKING : [13, 14, 15, 16],
  ids : ids,
  values : values,
  updateIds : {
    '1' : 'Invite',
    '3' : 'Accept',
    '8' : 'Verify Trained',
    '10' : 'Remove',
    '11' : 'Suspend',
  },
};
