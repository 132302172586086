import React, { useState } from 'react';
import { language, testType } from 'agents-remotely-commons/src/model';
import { url } from 'agents-remotely-commons/src/formatter';
import { formatErrorMessage, formatSimpleDate } from '../helper';
import useFetch from '../hooks/useFetch';
import InfoWindow from './InfoWindow';

const UserTest = ({userTest, getResults}) => {
  const getUrl = (testTypeId) => {
    switch(testTypeId) {
      case testType.VERBAL:
      case testType.TRANSCRIPTION:
        return 'aptitudeTest';
      case testType.TYPING:
        return 'typingTest';
      default:
        return 'interview';
    }
  };

  const getTestTypeIcon = (testTypeId) => {
    switch(testTypeId) {
      case testType.VERBAL:
        return 'fa-microphone';
      case testType.TRANSCRIPTION:
        return 'fa-headphones';
      case testType.TYPING:
        return 'fa-keyboard';
      default:
        return 'fa-clipboard';
    }
  };

  const getCountry = (languageId) => {
    switch(languageId) {
      case language.ENGLISH:
        return 'US';
      case language.SPANISH:
        return 'ES';
      case language.FRENCH:
        return 'FR';
      case language.RUSSIAN:
        return 'RU';
      case language.GERMAN:
        return 'DE';
      case language.DUTCH:
        return 'NL';
      case language.PORTUGESE:
        return 'PT';
      case language.HINDI:
        return 'IN';
      case language.CHINESE:
        return 'CN';
      default:
        return '';
    }
  };

  const RETAKE_LIMIT = 90 * 24 * 60 * 60 * 1000;

  const testStatuses = {
    inProgress: 1,
    processing: 2,
    available: 3,
    unavailable: 4,
  };

  const getCurrentStatus = (attempts) => {
    if(attempts?.length > 0) {
      if('score' in attempts[0]) {
        if(attempts[0].createdAt > new Date().getTime() - RETAKE_LIMIT) {
          if(!attempts[0].completedAt) {
            return testStatuses.inProgress;
          }
          return testStatuses.unavailable;
        }
      } else {
        return testStatuses.processing;
      }
    }
    return testStatuses.available;
  };

  const testStatus = getCurrentStatus(userTest.attempts);
  const practiceTestStatus = getCurrentStatus(userTest.practiceAttempts);

  return (
    <div className="test">
      <div className="test__info">
        <div className="company">
          <img className="company__logo" src={userTest.company.logo} alt="company logo"/>
          <div className="company__name">{userTest.company.name}</div>
        </div>
        <div className="test__language">
          <img className="test__language-flag" src={'https://purecatamphetamine.github.io/country-flag-icons/3x2/' + getCountry(userTest.language.id) + '.svg'} alt='flag'/>
          <div className="test__language-description">{userTest.language.description}</div>
        </div>
        <div className="test__type">
          <span className={`test__icon fa ${getTestTypeIcon(userTest.type.id)}`}></span>
          <div className="test__type-description">{userTest.type.description}</div>
        </div>
      </div>
      <div className="test__description">{userTest.description}</div>
      <div className="test__results">
        <div className="test__result">
          {userTest.type.id !== testType.INTERVIEW && (userTest.practiceAttempts?.[0]?.score >= 0 || userTest.attempts?.[0]?.score >= 0) ? 
            (userTest.practiceAttempts?.[0]?.score >= 0 ?
              <div className="test__score test__score-practice" onClick={() => getResults(true, userTest.id, userTest.practiceAttempts.length)}>{Math.round(userTest.practiceAttempts[0].score)}<span className="fa fa-search test__score-icon"></span></div>
            :  <div className="test__score">-</div>
            )
          : ''}
          {practiceTestStatus === testStatuses.processing ? 
            (userTest.type.id === testType.INTERVIEW ? <div className="test__score fa fa-check"></div> : <div className="processing"></div>) : 
            <div className="test__take">
              {practiceTestStatus === testStatuses.inProgress ? 'In Progress' : <a className="test__link button-reverse" href={url(getUrl(userTest.type.id), {id: userTest.id, practice: true})}>Practice</a> }
            </div>
          }
        </div>
        <div className="test__result">
          {userTest.type.id !== testType.INTERVIEW && (userTest.practiceAttempts?.[0]?.score >= 0 || userTest.attempts?.[0]?.score >= 0) ? <div className="test__score">{userTest.attempts?.[0]?.score >= 0 ? Math.round(userTest.attempts[0].score) : '-'}</div> : ''}
          {testStatus === testStatuses.processing ? 
            (userTest.type.id === testType.INTERVIEW ? <div className="test__score fa fa-check"></div> : <div className="processing"></div>) : 
            <div className="test__take">
              {testStatus === testStatuses.available ? <a className="test__link button" href={url(getUrl(userTest.type.id), {id: userTest.id})}>{userTest.attempts?.[0]?.score >= 0 ? 'Retake' : 'Take'}</a> : (testStatus === testStatuses.inProgress ? 'In Progress' : 'Retake in ' + formatSimpleDate((RETAKE_LIMIT + userTest.attempts[0].createdAt) - new Date().getTime()))}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

const UserTests = ({userTests}) => {
  const {apiFetch: testResultsFetch, data: testResults, error: testResultsError, loading: testResultsLoading} = useFetch();
  const {apiFetch: audioFetch, data: audio, error: audioError, loading: audioLoading, setData: setAudio} = useFetch();
  const [showResults, setShowResults] = useState(false);

  const formatDiffs = (diffs) => {
    const lines = [];
    let index = 0;
    for(const diff of diffs) {
      if(diff.added) {
        lines.push(<span className="diff__added" key={index++}>{diff.value}</span>);
      } else if(diff.removed) {
        lines.push(<span className="diff__removed" key={index++}>{diff.value}</span>);
      } else {
        lines.push(<span className="diff__correct" key={index++}>{diff.value}</span>);
      }
    }
    return lines;
  };

  const formatTranscription = (json, includeSpeakerLabels, includeAccuracy) => {
    if(json) {
      const hashItem = (item) => {
        return item.alternatives[0].confidence + '#' + item.alternatives[0].content + '#' + item.start_time + '#' + item.end_time;
      };

      const channels = {};
      for(const channel of json.channel_labels.channels) {
        for(const item of channel.items) {
          if(item.start_time) {
            channels[hashItem(item)] = channel.channel_label;
          }
        }
      }

      let idIndex = 0;
      let totalWords = 0;
      let totalConfidence = 0;
      const speakers = [];
      let currentSpeaker;
      let previousHashKey;
      let holdChannel;
      let hashKey;
      for(const item of json.items) {
        hashKey = hashItem(item);

        if(hashKey in channels) {
          if(holdChannel !== channels[hashKey]) {
            holdChannel = channels[hashKey];
            if(previousHashKey) {
              if(includeSpeakerLabels) {
                speakers.push(<div key={idIndex++}>{channels[previousHashKey]}: {currentSpeaker}</div>);
              } else {
                speakers.push(currentSpeaker);
              }
            }
            currentSpeaker = [];
            previousHashKey = hashKey;
          }
        }

        const confidence = Number(item.alternatives[0].redactions ? item.alternatives[0].redactions[0].confidence : item.alternatives[0].confidence);
        if(item.type === 'pronunciation') {
          totalWords++;
          totalConfidence += confidence;
        }
        currentSpeaker.push(<span key={idIndex++} className={'conf' + Math.round(confidence * 10)}>{item.type === 'pronunciation' ? ' ' : ''}{item.alternatives[0].content}</span>);
      }
      if(includeSpeakerLabels) {
        speakers.push(<div>{channels[previousHashKey]}: {currentSpeaker}</div>);
      } else {
        speakers.push(currentSpeaker);
      }

      if(includeAccuracy) {
        speakers.push(<div key={idIndex++} className="language-test__content">Transcription Accuracy: {(totalConfidence / totalWords).toFixed(2)}</div>);
      }
      return speakers;
    }
  };

  const downloadAndPlay = (key) => {
    audioFetch('GET', '/agents/{user.id}/recordings' + key.substring(key.indexOf('/', 11)), null, null, 'application/octet-stream');
  }

  const renderResults = () => {
    if(testResultsLoading) {
      return <div className="processing"></div> ;
    } else if(testResults) {
      return ( 
        <div className="language-test__results">
          <div className="language-test__content"><span className="language-test__label">Test:</span> {testResults.content}</div>
          <div className="language-test__content"><span className="language-test__label">Answer:</span>{typeof testResults.results === 'string' ? ' ' + testResults.results : formatTranscription(testResults.results.results)}</div>
          <div className="language-test__content"><span className="language-test__label">Diffs:</span> {formatDiffs(testResults.diffs)}</div>
          {testResults.recordingKey && !audio && !audioLoading ? 
            <div className="language-test__content"><span className="language-test__label">Listen:</span> <span className="fa fa-play language-test__icon" onClick={() => downloadAndPlay(testResults.recordingKey)}></span></div> 
            : ''
          }
          {audioLoading ? 
            <div className="processing"></div>
            : ''
          }
          {audio ? 
            <audio controls autoPlay>
              <source type="audio/mp3" src={URL.createObjectURL(audio)}/>
            </audio>
            : ''
          }
          {audioError ? 
            <div className="error-message">{formatErrorMessage(testResultsError)}</div>
            : ''
          }
      </div>);
    } else if(testResultsError) {
      return <div className="error-message">{formatErrorMessage(testResultsError)}</div>;
    }
  }

  const getResults = (practice, testId, attempt) => {
    setShowResults(true);
    testResultsFetch('GET', '/agents/{user.id}/tests/' + testId + '/results/' + (attempt - 1) + '?practice=' + practice);
  };

  const reset = () => {
    setShowResults(false);
    setAudio(null);
  };

  const rows = [];
  for(const userTest of userTests) {
    rows.push(<UserTest userTest={userTest} key={userTest.id} getResults={getResults}/>)
  }
  return (
    <>
      <div className="content__main tests">{rows}</div>
      {showResults ? <InfoWindow content={renderResults()} close={reset}/> : ''}
    </>
  );
};

export default UserTests;
