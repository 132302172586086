'use strict'

module.exports = {
  HOURLY: 1,
  PER_MINUTE: 2,
  PERCENT_REVENUE_SHARE: 3,
  FLAT_REVENUE_SHARE: 4,
  PER_TASK: 5,
  ids : {
    '1' : 'Hourly',
    '2' : 'Per Minute',
    '3' : 'Percent Revenue Share',
    '4' : 'Flat Revenue Share',
    '5' : 'Per Task',
  },
  shortDescriptionIds : {
    '1' : '/ hr',
    '2' : '/ min',
    '3' : '% Rev',
    '4' : 'Rev',
    '5' : '/ task',
  },
};
