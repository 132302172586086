import React, {useState} from 'react';
import { useField } from 'formik';
import { titleToDash } from 'agents-remotely-commons/src/formatter';
import TextInput from './TextInput';
import 'react-phone-number-input/style.css'
import PhoneInput, {getCountryCallingCode} from 'react-phone-number-input'

const PhoneNumberInput = ({ label, formName, defaultPhoneNumber, defaultPhoneDialCode, defaultPhoneCountryCode, ...props }) => {
  const [phoneCountryCode, setPhoneCountryCode] = useState(defaultPhoneCountryCode || 'US');
  const [phoneDialCode, setPhoneDialCode] = useState(defaultPhoneDialCode || '+1');
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);
  const onCountryChange = (val) => {
    console.log('change' + val + getCountryCallingCode(val));
    props.setFieldValue('phoneDialCode', getCountryCallingCode(val));
    props.setFieldValue('phoneCountryCode', val);
    setPhoneCountryCode(val);
    setPhoneDialCode(getCountryCallingCode(val));
  };
 // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
 // which we can spread on <input>. We can use field meta to show an error
 // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta, { setValue}] = useField(props);
  const classPrefix = formName + '__' + titleToDash(field.name);
  return (
    <>
      <TextInput
        formName={formName}
        name={field.name + "DialCode"}
        type="hidden"
        autoComplete="off"
        required="required"
        value={phoneDialCode}
      />
      <TextInput
        formName={formName}
        name={field.name + "CountryCode"}
        type="hidden"
        autoComplete="tel-country-code"
        required="required"
        value={phoneCountryCode}
      />
      {label ? <label className={'form__label ' + classPrefix + '-label'} htmlFor={props.id || props.name}>{label}</label> : ''}
      <PhoneInput
        name={field.name}
        {...field} {...props}
        className={'form__text-input ' + classPrefix}
        autoComplete="tel-national"
        value={phoneNumber}
        onChange={(val) => {setPhoneNumber(val);setValue(val);}}
        initialValueFormat="national"
        addInternationalOption={false}
        onCountryChange={onCountryChange}
        defaultCountry={phoneCountryCode}
      />
      {meta.touched && meta.error ? (
        <div className={'form__error ' + classPrefix + '-error'}>{meta.error}</div>
      ) : null}
    </>
  );
};

export default PhoneNumberInput;
