'use strict'

const ADMINISTRATOR = 'administrator';
const VERIFIED = 'verified';
const HIRED = 'hired';
const WORKABLE_AGENT = 'workableAgent';
const AGENT = 'agent';

module.exports = {
  PREFIX : 'role-',
  ADMINISTRATOR : ADMINISTRATOR,
  AGENT : AGENT,
  HIRED : HIRED,
  VERIFIED : VERIFIED,
  WORKABLE_AGENT : WORKABLE_AGENT,
  administrator : [ADMINISTRATOR, VERIFIED, HIRED, WORKABLE_AGENT, AGENT],
  verified : [VERIFIED, AGENT],
  hired : [HIRED, AGENT],
  workableAgent : [WORKABLE_AGENT, AGENT],
  agent : [AGENT],
  ids : {
    '1' : ADMINISTRATOR,
    '2' : VERIFIED,
    '3' : WORKABLE_AGENT,
    '4' : AGENT,
    '5' : HIRED,
  },
};
