import React, { useState } from 'react';
import useFetch from '../../hooks/useFetch';
import { url } from 'agents-remotely-commons/src/formatter';
import {formatErrorMessage} from '../../helper';
import { PieChart, Pie, Sector, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { AgGridReact } from 'ag-grid-react';
import CheckBoxInput from '../../components/CheckBoxInput';
import SelectInput from '../../components/SelectInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Dates from '../../components/Dates';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const PaymentReport = () => {
  const now = new Date();
  const {apiFetch, data, error, loading} = useFetch();
  const {apiFetch: compareApiFetch, data: compareData, error: compareError, loading: compareLoading} = useFetch();
  const [ offset, setOffset ] = useState(0);
  const [ pageSize, setPageSize ] = useState(100);
  const [startDate, setStartDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999));
  const [compareStartDate, setCompareStartDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  const [compareEndDate, setCompareEndDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999));
  const [compare, setCompare] = useState(false);

  const submit = () => {
    apiFetch('GET', '/reports/agentPayment', {
      startedAt: startDate.getTime(),
      endedAt: endDate.getTime(),
      offset: offset,
      limit: pageSize,
    });
    if(compare) {
      compareApiFetch('GET', '/reports/agentPayment', {
        startedAt: compareStartDate.getTime(),
        endedAt: compareEndDate.getTime(),
        offset: offset,
        limit: pageSize,
      });
    }
  };

  React.useEffect(() => {
  }, []);


  const getChartData = () => {
    const types = {};
    for(const item of data) {
      if(!types[item.paymentType]) {
        types[item.paymentType] = {name: item.paymentType, value: 0};
      }
      types[item.paymentType].value += parseFloat(item.amount);
    }
    return Object.values(types);
  };

  const getRowData = () => {
    const rows = [];
    for(const item of data) {
      rows.push({paymentType: item.paymentType,
        rate: item.rate,
        unit: item.unit,
        amount: item.amount,
        details: item.details,
        createdAt: new Date(item.createdAt)});
    }
    return rows;
  };

  const getColumnData = () => {
    return [
      {field: 'paymentType'},
      {field: 'rate'},
      {field: 'unit'},
      {field: 'amount'},
      {field: 'details', cellRenderer: (params) => params.value.callId ? <a href={url('callDetailReport', {callId: params.value.callId})}>{params.value.callId}</a> : ''},
      {field: 'createdAt'},
    ];
  }

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onPieEnter = () => {
  };

  const customLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    return Math.round(100 * percent) + '%';
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Payment</h1>
      </div>
      <div className="content__main content__main--padded">
        <Formik
          validateOnChange={false}
          initialValues={{
            compare: compare ? '1' : '0',
            startDate: startDate,
            endDate: endDate,
            compareStartDate: compareStartDate,
            compareEndDate: compareEndDate,
            date: null,
            compareDate: null,
          }}
          validationSchema={Yup.object({
          })}
          initialTouched={{
          }}
          onSubmit={(values) => {
          }}
        >
          {({ setFieldValue , isSubmitting }) => (
          <Form className="form report-form">
            <Dates startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
            {compare ? 
              <Dates startDate={compareStartDate} setStartDate={setCompareStartDate} endDate={compareEndDate} setEndDate={setCompareEndDate}/>
            : ''}
            <div className="report-header__line report-header__line--submit">
              <button className="button" onClick={submit}>Submit</button>
              <remove style={{display: "none"}}>
                <CheckBoxInput formName="report" label="Compare" name="compare" value="1" checked={compare} onChange={event => {setCompare(!compare); setFieldValue('compare', event.target.checked ? '1' : '0')}}/>
              </remove>
            </div>
          </Form>
          )}
        </Formik>
        {loading ? <div className="processing"></div> : ''}
        {data ? 
          (data.length > 0 ? 
            <>
              <PieChart width={320} height={320} onMouseEnter={onPieEnter}>
                <Pie
                  data={getChartData()}
                  cx={160}
                  cy={160}
                  innerRadius={80}
                  outerRadius={100}
                  fill="#8884d8"
                  paddingAngle={2}
                  dataKey="value"
                  label={customLabel}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
               <div className="report-data-grid ag-theme-alpine">
                 <AgGridReact
                   rowData={getRowData()}
                   columnDefs={getColumnData()}
                   onFirstDataRendered={onFirstDataRendered}
                   onGridSizeChanged={onGridSizeChanged}>
                 </AgGridReact>
              </div>
            </>
            : <div className="info-message">No results</div>)
          : (!error && !loading ? <div className="info-message">Submit dates to see results</div> : '')
        }
        {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
      </div>
    </section>
  )
}

export default PaymentReport;
