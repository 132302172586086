import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Layout from './components/Layout';
import AuthenticatedLayout from './components/AuthenticatedLayout';
import Home from './pages/index';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import SignIn from './pages/sign-in';
import SignUp from './pages/sign-up';
import Mfa from './pages/mfa';
import AccountMfa from './pages/account/mfa';
import AccountSettings from './pages/account/settings';
import Availability from './pages/account/availability';
import Verification from './pages/account/verification';
import ChangePassword from './pages/account/change-password';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import ConfirmAccount from './pages/confirm-account';
import Dashboard from './pages/dashboard';
import Work from './pages/work';
import Jobs from './pages/jobs';
import PersonalInfo from './pages/account/personal-info';
import WorkSettings from './pages/account/work-settings';
import Badges from './pages/badges';
import PaymentCenter from './pages/payment/index';
import PaymentMethods from './pages/payment/methods';
import PaymentWithdrawal from './pages/payment/withdrawal';
import TestingCenter from './pages/tests/index';
import AptitudeTest from './pages/tests/aptitude';
import TypingTest from './pages/tests/typing';
import TokenCenter from './pages/tokens/index';
import ScratchOff from './pages/tokens/scratch-off';
import SlotMachine from './pages/tokens/slot-machine';
import WheelOfPrizes from './pages/tokens/wheel';
import CallDetailReport from './pages/reports/call-detail';
import CallReport from './pages/reports/call';
import SummaryReport from './pages/reports/summary';
import PaymentReport from './pages/reports/payment';
import { url } from 'agents-remotely-commons/src/formatter';
import './sass/main.scss';

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path={url('home')} element={<Home/>} />
          <Route path={url('terms')} element={<Terms/>} />
          <Route path={url('privacy')} element={<Privacy/>} />
          <Route path={url('signIn')} element={<SignIn/>} />
          <Route path={url('verifySignIn')} element={<Mfa/>} />
          <Route path={url('signUp')} element={<SignUp/>} />
          <Route path={url('forgotPassword')} element={<ForgotPassword/>} />
          <Route path={url('resetPassword')} element={<ResetPassword/>} />
          <Route path={url('confirmAccount')} element={<ConfirmAccount/>} />
        </Route>
        <Route path='' element={<AuthenticatedLayout />}>
          <Route path={url('dashboard')} element={<Dashboard/>} />
          <Route path={url('work')} element={<Work/>} />
          <Route path={url('jobs')} element={<Jobs/>} />
          <Route path={url('accountSettings')} element={<AccountSettings/>} />
          <Route path={url('availability')} element={<Availability/>} />
          <Route path={url('callDetailReport')} element={<CallDetailReport/>} />
          <Route path={url('callReport')} element={<CallReport/>} />
          <Route path={url('changePassword')} element={<ChangePassword/>} />
          <Route path={url('mfa')} element={<AccountMfa/>} />
          <Route path={url('personalInfo')} element={<PersonalInfo/>} />
          <Route path={url('workSettings')} element={<WorkSettings/>} />
          <Route path={url('paymentCenter')} element={<PaymentCenter/>} />
          <Route path={url('paymentMethods')} element={<PaymentMethods/>} />
          <Route path={url('paymentReport')} element={<PaymentReport/>} />
          <Route path={url('paymentWithdrawal')} element={<PaymentWithdrawal/>} />
          <Route path={url('tests')} element={<TestingCenter/>} />
          <Route path={url('aptitudeTest')} element={<AptitudeTest/>} />
          <Route path={url('typingTest')} element={<TypingTest/>} />
          <Route path={url('tokenCenter')} element={<TokenCenter/>} />
          <Route path={url('scratchOff')} element={<ScratchOff/>} />
          <Route path={url('slotMachine')} element={<SlotMachine/>} />
          <Route path={url('wheelOfPrizes')} element={<WheelOfPrizes/>} />
          <Route path={url('badges')} element={<Badges/>} />
          <Route path={url('summaryReport')} element={<SummaryReport/>} />
          <Route path={url('paymentReport')} element={<PaymentReport/>} />
          <Route path={url('emailVerification')} element={<Verification verificationType="email"/>} />
          <Route path={url('phoneVerification')} element={<Verification verificationType="phone"/>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

