import React from 'react';
import {Outlet} from 'react-router-dom';
import Navbar from './Navbar';
import { agentMessages } from '../graphql/subscriptions';
import { userEventType } from 'agents-remotely-commons/src/model';
import { useWebSocket } from '../hooks/useWebSocket';
import { useAuth } from '../hooks/useAuth';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { url } from 'agents-remotely-commons/src/formatter';
import { useDispatch } from 'react-redux';
import { update as updateMoney } from '../features/moneySlice';
import { update as updateTokens } from '../features/tokensSlice';
import { increment as incrementBadges } from '../features/badgesSlice';
import { set as setGoal } from '../features/goalsSlice';
import { set as setTest } from '../features/testsSlice';

const AuthenticatedLayout = () => {
  const {apiFetch: agentFetch, data: agent, error: agentError, loading: agentLoading} = useFetch(null);
  const {apiFetch: goalFetch, data: goal, error: goalError, loading: goalLoading} = useFetch(null);
  const {apiFetch: testFetch, data: test, error: testError, loading: testLoading} = useFetch(null);
  const navigate = useNavigate();
  const { reauthorize, user } = useAuth();
  const {connected, connectionError, error, client} = useWebSocket();
  const { subscribe, unsubscribe } = useWebSocket();
  const dispatch = useDispatch();

  const blinkGreen = (selector) => {
    const el = document.querySelector(selector);
    if(el) {
      el.classList.remove('blink-green');
      void el.offsetWidth;
      el.classList.add('blink-green');
    }
  };

  React.useEffect(() => {
    if(!user) {
      navigate(url('home'));
    } else {
      if(connected) {
        client.subscribe({
          id: user.userId,
          query: agentMessages,
          variables: {
            agentId: user.userId,
          },
          messageHandler: async (data) => {
            const message = JSON.parse(data.agentMessages.message);
            switch(data.agentMessages.eventTypeId) {
              case userEventType.UPDATED_MONEY:
                dispatch(updateMoney(message.to.money));
                blinkGreen('.money-icon');
                break;
              case userEventType.UPDATED_TOKENS:
                console.log(message);
                dispatch(updateTokens(message.to.tokens));
                blinkGreen('.token-icon');
                break;
              case userEventType.UPDATED_BADGE:
                const badgeEl = document.querySelector('.nav-badge');
                blinkGreen('.badge-icon');
                dispatch(incrementBadges(message.amount));
                break;
              case userEventType.ADDED_ROLE:
              case userEventType.REMOVED_ROLE:
                reauthorize();
                //dont think this is needed but TODO think I do need to force component refresh for new roles
                //apiFetch('GET', '/agents/{user.id}/roles');
                break;
              case userEventType.ADDED_GOAL:
              case userEventType.UPDATED_GOAL:
              case userEventType.COMPLETED_GOAL:
                dispatch(setGoal(await goalFetch('GET', '/agents/{user.id}/goals/' + message.goalId)));
                break;
              case userEventType.STARTED_VERBAL_TEST:
              case userEventType.STARTED_TRANSCRIPTION_TEST:
              case userEventType.STARTED_TYPING_TEST:
              case userEventType.COMPLETED_VERBAL_TEST:
              case userEventType.COMPLETED_TRANSCRIPTION_TEST:
              case userEventType.COMPLETED_TYPING_TEST:
              case userEventType.STARTED_PRACTICE_VERBAL_TEST:
              case userEventType.STARTED_PRACTICE_TRANSCRIPTION_TEST:
              case userEventType.STARTED_PRACTICE_TYPING_TEST:
              case userEventType.COMPLETED_PRACTICE_VERBAL_TEST:
              case userEventType.COMPLETED_PRACTICE_TRANSCRIPTION_TEST:
              case userEventType.COMPLETED_PRACTICE_TYPING_TEST:
                dispatch(setTest(await testFetch('GET', '/agents/{user.id}/tests/' + message.testId)));
                break;
            }
          },
          errorHandler: (err) => {
            console.log(err);
          }
        });

        (async () => {
          const a = await agentFetch('GET', '/agents/{user.id}', {
            select: ['badges', 'money', 'tokens'],
          });
          dispatch(updateMoney(a.money));
          dispatch(updateTokens(a.tokens));
        })();
        return () => {
          client.unsubscribe(user.userId);
        };
      }
    }
  }, [user, connected]);

  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
};

export default AuthenticatedLayout;
