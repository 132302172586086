'use strict'

module.exports = {
  ids : {
    '1' : {name : 'Afghanistan', abbreviation : 'AF', dialCode : 93},
    '2' : {name : 'Albania', abbreviation : 'AL', dialCode : 355},
    '3' : {name : 'Algeria', abbreviation : 'DZ', dialCode : 213},
    '4' : {name : 'American Samoa', abbreviation : 'AS', dialCode : 1},
    '5' : {name : 'Andorra', abbreviation : 'AD', dialCode : 376},
    '6' : {name : 'Angola', abbreviation : 'AO', dialCode : 244},
    '7' : {name : 'Anguilla', abbreviation : 'AI', dialCode : 1},
    '8' : {name : 'Antarctica', abbreviation : 'AQ', dialCode : 672},
    '9' : {name : 'Antigua and Barbuda', abbreviation : 'AG', dialCode : 1},
    '10' : {name : 'Argentina', abbreviation : 'AR', dialCode : 54},
    '11' : {name : 'Armenia', abbreviation : 'AM', dialCode : 374},
    '12' : {name : 'Aruba', abbreviation : 'AW', dialCode : 297},
    '13' : {name : 'Australia', abbreviation : 'AU', dialCode : 61},
    '14' : {name : 'Austria', abbreviation : 'AT', dialCode : 43},
    '15' : {name : 'Azerbaijan', abbreviation : 'AZ', dialCode : 994},
    '16' : {name : 'Bahamas', abbreviation : 'BS', dialCode : 1},
    '17' : {name : 'Bahrain', abbreviation : 'BH', dialCode : 973},
    '18' : {name : 'Bangladesh', abbreviation : 'BD', dialCode : 880},
    '19' : {name : 'Barbados', abbreviation : 'BB', dialCode : 1},
    '20' : {name : 'Belarus', abbreviation : 'BY', dialCode : 375},
    '21' : {name : 'Belgium', abbreviation : 'BE', dialCode : 32},
    '22' : {name : 'Belize', abbreviation : 'BZ', dialCode : 501},
    '23' : {name : 'Benin', abbreviation : 'BJ', dialCode : 229},
    '24' : {name : 'Bermuda', abbreviation : 'BM', dialCode : 1},
    '25' : {name : 'Bhutan', abbreviation : 'BT', dialCode : 975},
    '26' : {name : 'Bolivia', abbreviation : 'BO', dialCode : 591},
    '27' : {name : 'Bosnia and Herzegovina', abbreviation : 'BA', dialCode : 387},
    '28' : {name : 'Botswana', abbreviation : 'BW', dialCode : 267},
    '29' : {name : 'Brazil', abbreviation : 'BR', dialCode : 55},
    '30' : {name : 'British Indian Ocean Territory', abbreviation : 'IO', dialCode : 246},
    '31' : {name : 'British Virgin Islands', abbreviation : 'VG', dialCode : 1},
    '32' : {name : 'Brunei', abbreviation : 'BN', dialCode : 673},
    '33' : {name : 'Bulgaria', abbreviation : 'BG', dialCode : 359},
    '34' : {name : 'Burkina Faso', abbreviation : 'BF', dialCode : 226},
    '35' : {name : 'Burundi', abbreviation : 'BI', dialCode : 257},
    '36' : {name : 'Cambodia', abbreviation : 'KH', dialCode : 855},
    '37' : {name : 'Cameroon', abbreviation : 'CM', dialCode : 237},
    '38' : {name : 'Canada', abbreviation : 'CA', dialCode : 1},
    '39' : {name : 'Cape Verde', abbreviation : 'CV', dialCode : 238},
    '40' : {name : 'Cayman Islands', abbreviation : 'KY', dialCode : 1},
    '41' : {name : 'Central African Republic', abbreviation : 'CF', dialCode : 236},
    '42' : {name : 'Chad', abbreviation : 'TD', dialCode : 235},
    '43' : {name : 'Chile', abbreviation : 'CL', dialCode : 56},
    '44' : {name : 'China', abbreviation : 'CN', dialCode : 86},
    '45' : {name : 'Christmas Island', abbreviation : 'CX', dialCode : 61},
    '46' : {name : 'Cocos Islands', abbreviation : 'CC', dialCode : 61},
    '47' : {name : 'Colombia', abbreviation : 'CO', dialCode : 57},
    '48' : {name : 'Comoros', abbreviation : 'KM', dialCode : 269},
    '49' : {name : 'Cook Islands', abbreviation : 'CK', dialCode : 682},
    '50' : {name : 'Costa Rica', abbreviation : 'CR', dialCode : 506},
    '51' : {name : 'Croatia', abbreviation : 'HR', dialCode : 385},
    '52' : {name : 'Cuba', abbreviation : 'CU', dialCode : 53},
    '53' : {name : 'Curacao', abbreviation : 'CW', dialCode : 599},
    '54' : {name : 'Cyprus', abbreviation : 'CY', dialCode : 357},
    '55' : {name : 'Czech Republic', abbreviation : 'CZ', dialCode : 420},
    '56' : {name : 'Democratic Republic of the Congo', abbreviation : 'CD', dialCode : 243},
    '57' : {name : 'Denmark', abbreviation : 'DK', dialCode : 45},
    '58' : {name : 'Djibouti', abbreviation : 'DJ', dialCode : 253},
    '59' : {name : 'Dominica', abbreviation : 'DM', dialCode : 1},
    '60' : {name : 'Dominican Republic', abbreviation : 'DO', dialCode : 1},
    '61' : {name : 'East Timor', abbreviation : 'TL', dialCode : 670},
    '62' : {name : 'Ecuador', abbreviation : 'EC', dialCode : 593},
    '63' : {name : 'Egypt', abbreviation : 'EG', dialCode : 20},
    '64' : {name : 'El Salvador', abbreviation : 'SV', dialCode : 503},
    '65' : {name : 'Equatorial Guinea', abbreviation : 'GQ', dialCode : 240},
    '66' : {name : 'Eritrea', abbreviation : 'ER', dialCode : 291},
    '67' : {name : 'Estonia', abbreviation : 'EE', dialCode : 372},
    '68' : {name : 'Ethiopia', abbreviation : 'ET', dialCode : 251},
    '69' : {name : 'Falkland Islands', abbreviation : 'FK', dialCode : 500},
    '70' : {name : 'Faroe Islands', abbreviation : 'FO', dialCode : 298},
    '71' : {name : 'Fiji', abbreviation : 'FJ', dialCode : 679},
    '72' : {name : 'Finland', abbreviation : 'FI', dialCode : 358},
    '73' : {name : 'France', abbreviation : 'FR', dialCode : 33},
    '74' : {name : 'French Polynesia', abbreviation : 'PF', dialCode : 689},
    '75' : {name : 'Gabon', abbreviation : 'GA', dialCode : 241},
    '76' : {name : 'Gambia', abbreviation : 'GM', dialCode : 220},
    '77' : {name : 'Georgia', abbreviation : 'GE', dialCode : 995},
    '78' : {name : 'Germany', abbreviation : 'DE', dialCode : 49},
    '79' : {name : 'Ghana', abbreviation : 'GH', dialCode : 233},
    '80' : {name : 'Gibraltar', abbreviation : 'GI', dialCode : 350},
    '81' : {name : 'Greece', abbreviation : 'GR', dialCode : 30},
    '82' : {name : 'Greenland', abbreviation : 'GL', dialCode : 299},
    '83' : {name : 'Grenada', abbreviation : 'GD', dialCode : 1},
    '84' : {name : 'Guam', abbreviation : 'GU', dialCode : 1},
    '85' : {name : 'Guatemala', abbreviation : 'GT', dialCode : 502},
    '86' : {name : 'Guernsey', abbreviation : 'GG', dialCode : 44},
    '87' : {name : 'Guinea', abbreviation : 'GN', dialCode : 224},
    '88' : {name : 'Guinea-Bissau', abbreviation : 'GW', dialCode : 245},
    '89' : {name : 'Guyana', abbreviation : 'GY', dialCode : 592},
    '90' : {name : 'Haiti', abbreviation : 'HT', dialCode : 509},
    '91' : {name : 'Honduras', abbreviation : 'HN', dialCode : 504},
    '92' : {name : 'Hong Kong', abbreviation : 'HK', dialCode : 852},
    '93' : {name : 'Hungary', abbreviation : 'HU', dialCode : 36},
    '94' : {name : 'Iceland', abbreviation : 'IS', dialCode : 354},
    '95' : {name : 'India', abbreviation : 'IN', dialCode : 91},
    '96' : {name : 'Indonesia', abbreviation : 'ID', dialCode : 62},
    '97' : {name : 'Iran', abbreviation : 'IR', dialCode : 98},
    '98' : {name : 'Iraq', abbreviation : 'IQ', dialCode : 964},
    '99' : {name : 'Ireland', abbreviation : 'IE', dialCode : 353},
    '100' : {name : 'Isle of Man', abbreviation : 'IM', dialCode : 44},
    '101' : {name : 'Israel', abbreviation : 'IL', dialCode : 972},
    '102' : {name : 'Italy', abbreviation : 'IT', dialCode : 39},
    '103' : {name : 'Ivory Coast', abbreviation : 'CI', dialCode : 225},
    '104' : {name : 'Jamaica', abbreviation : 'JM', dialCode : 1},
    '105' : {name : 'Japan', abbreviation : 'JP', dialCode : 81},
    '106' : {name : 'Jersey', abbreviation : 'JE', dialCode : 44},
    '107' : {name : 'Jordan', abbreviation : 'JO', dialCode : 962},
    '108' : {name : 'Kazakhstan', abbreviation : 'KZ', dialCode : 7},
    '109' : {name : 'Kenya', abbreviation : 'KE', dialCode : 254},
    '110' : {name : 'Kiribati', abbreviation : 'KI', dialCode : 686},
    '111' : {name : 'Kosovo', abbreviation : 'XK', dialCode : 383},
    '112' : {name : 'Kuwait', abbreviation : 'KW', dialCode : 965},
    '113' : {name : 'Kyrgyzstan', abbreviation : 'KG', dialCode : 996},
    '114' : {name : 'Laos', abbreviation : 'LA', dialCode : 856},
    '115' : {name : 'Latvia', abbreviation : 'LV', dialCode : 371},
    '116' : {name : 'Lebanon', abbreviation : 'LB', dialCode : 961},
    '117' : {name : 'Lesotho', abbreviation : 'LS', dialCode : 266},
    '118' : {name : 'Liberia', abbreviation : 'LR', dialCode : 231},
    '119' : {name : 'Libya', abbreviation : 'LY', dialCode : 218},
    '120' : {name : 'Liechtenstein', abbreviation : 'LI', dialCode : 423},
    '121' : {name : 'Lithuania', abbreviation : 'LT', dialCode : 370},
    '122' : {name : 'Luxembourg', abbreviation : 'LU', dialCode : 352},
    '123' : {name : 'Macau', abbreviation : 'MO', dialCode : 853},
    '124' : {name : 'Macedonia', abbreviation : 'MK', dialCode : 389},
    '125' : {name : 'Madagascar', abbreviation : 'MG', dialCode : 261},
    '126' : {name : 'Malawi', abbreviation : 'MW', dialCode : 265},
    '127' : {name : 'Malaysia', abbreviation : 'MY', dialCode : 60},
    '128' : {name : 'Maldives', abbreviation : 'MV', dialCode : 960},
    '129' : {name : 'Mali', abbreviation : 'ML', dialCode : 223},
    '130' : {name : 'Malta', abbreviation : 'MT', dialCode : 356},
    '131' : {name : 'Marshall Islands', abbreviation : 'MH', dialCode : 692},
    '132' : {name : 'Mauritania', abbreviation : 'MR', dialCode : 222},
    '133' : {name : 'Mauritius', abbreviation : 'MU', dialCode : 230},
    '134' : {name : 'Mayotte', abbreviation : 'YT', dialCode : 262},
    '135' : {name : 'Mexico', abbreviation : 'MX', dialCode : 52},
    '136' : {name : 'Micronesia', abbreviation : 'FM', dialCode : 691},
    '137' : {name : 'Moldova', abbreviation : 'MD', dialCode : 373},
    '138' : {name : 'Monaco', abbreviation : 'MC', dialCode : 377},
    '139' : {name : 'Mongolia', abbreviation : 'MN', dialCode : 976},
    '140' : {name : 'Montenegro', abbreviation : 'ME', dialCode : 382},
    '141' : {name : 'Montserrat', abbreviation : 'MS', dialCode : 1},
    '142' : {name : 'Morocco', abbreviation : 'MA', dialCode : 212},
    '143' : {name : 'Mozambique', abbreviation : 'MZ', dialCode : 258},
    '144' : {name : 'Myanmar', abbreviation : 'MM', dialCode : 95},
    '145' : {name : 'Namibia', abbreviation : 'NA', dialCode : 264},
    '146' : {name : 'Nauru', abbreviation : 'NR', dialCode : 674},
    '147' : {name : 'Nepal', abbreviation : 'NP', dialCode : 977},
    '148' : {name : 'Netherlands', abbreviation : 'NL', dialCode : 31},
    '149' : {name : 'Netherlands Antilles', abbreviation : 'AN', dialCode : 599},
    '150' : {name : 'New Caledonia', abbreviation : 'NC', dialCode : 687},
    '151' : {name : 'New Zealand', abbreviation : 'NZ', dialCode : 64},
    '152' : {name : 'Nicaragua', abbreviation : 'NI', dialCode : 505},
    '153' : {name : 'Niger', abbreviation : 'NE', dialCode : 227},
    '154' : {name : 'Nigeria', abbreviation : 'NG', dialCode : 234},
    '155' : {name : 'Niue', abbreviation : 'NU', dialCode : 683},
    '156' : {name : 'North Korea', abbreviation : 'KP', dialCode : 850},
    '157' : {name : 'Northern Mariana Islands', abbreviation : 'MP', dialCode : 1},
    '158' : {name : 'Norway', abbreviation : 'NO', dialCode : 47},
    '159' : {name : 'Oman', abbreviation : 'OM', dialCode : 968},
    '160' : {name : 'Pakistan', abbreviation : 'PK', dialCode : 92},
    '161' : {name : 'Palau', abbreviation : 'PW', dialCode : 680},
    '162' : {name : 'Palestine', abbreviation : 'PS', dialCode : 970},
    '163' : {name : 'Panama', abbreviation : 'PA', dialCode : 507},
    '164' : {name : 'Papua New Guinea', abbreviation : 'PG', dialCode : 675},
    '165' : {name : 'Paraguay', abbreviation : 'PY', dialCode : 595},
    '166' : {name : 'Peru', abbreviation : 'PE', dialCode : 51},
    '167' : {name : 'Philippines', abbreviation : 'PH', dialCode : 63},
    '168' : {name : 'Pitcairn', abbreviation : 'PN', dialCode : 64},
    '169' : {name : 'Poland', abbreviation : 'PL', dialCode : 48},
    '170' : {name : 'Portugal', abbreviation : 'PT', dialCode : 351},
    '171' : {name : 'Puerto Rico', abbreviation : 'PR', dialCode : 1},
    '172' : {name : 'Qatar', abbreviation : 'QA', dialCode : 974},
    '173' : {name : 'Republic of the Congo', abbreviation : 'CG', dialCode : 242},
    '174' : {name : 'Reunion', abbreviation : 'RE', dialCode : 262},
    '175' : {name : 'Romania', abbreviation : 'RO', dialCode : 40},
    '176' : {name : 'Russia', abbreviation : 'RU', dialCode : 7},
    '177' : {name : 'Rwanda', abbreviation : 'RW', dialCode : 250},
    '178' : {name : 'Saint Barthelemy', abbreviation : 'BL', dialCode : 590},
    '179' : {name : 'Saint Helena', abbreviation : 'SH', dialCode : 290},
    '180' : {name : 'Saint Kitts and Nevis', abbreviation : 'KN', dialCode : 1},
    '181' : {name : 'Saint Lucia', abbreviation : 'LC', dialCode : 1},
    '182' : {name : 'Saint Martin', abbreviation : 'MF', dialCode : 590},
    '183' : {name : 'Saint Pierre and Miquelon', abbreviation : 'PM', dialCode : 508},
    '184' : {name : 'Saint Vincent and the Grenadines', abbreviation : 'VC', dialCode : 1},
    '185' : {name : 'Samoa', abbreviation : 'WS', dialCode : 685},
    '186' : {name : 'San Marino', abbreviation : 'SM', dialCode : 378},
    '187' : {name : 'Sao Tome and Principe', abbreviation : 'ST', dialCode : 239},
    '188' : {name : 'Saudi Arabia', abbreviation : 'SA', dialCode : 966},
    '189' : {name : 'Senegal', abbreviation : 'SN', dialCode : 221},
    '190' : {name : 'Serbia', abbreviation : 'RS', dialCode : 381},
    '191' : {name : 'Seychelles', abbreviation : 'SC', dialCode : 248},
    '192' : {name : 'Sierra Leone', abbreviation : 'SL', dialCode : 232},
    '193' : {name : 'Singapore', abbreviation : 'SG', dialCode : 65},
    '194' : {name : 'Sint Maarten', abbreviation : 'SX', dialCode : 1},
    '195' : {name : 'Slovakia', abbreviation : 'SK', dialCode : 421},
    '196' : {name : 'Slovenia', abbreviation : 'SI', dialCode : 386},
    '197' : {name : 'Solomon Islands', abbreviation : 'SB', dialCode : 677},
    '198' : {name : 'Somalia', abbreviation : 'SO', dialCode : 252},
    '199' : {name : 'South Africa', abbreviation : 'ZA', dialCode : 27},
    '200' : {name : 'South Korea', abbreviation : 'KR', dialCode : 82},
    '201' : {name : 'South Sudan', abbreviation : 'SS', dialCode : 211},
    '202' : {name : 'Spain', abbreviation : 'ES', dialCode : 34},
    '203' : {name : 'Sri Lanka', abbreviation : 'LK', dialCode : 94},
    '204' : {name : 'Sudan', abbreviation : 'SD', dialCode : 249},
    '205' : {name : 'Suriname', abbreviation : 'SR', dialCode : 597},
    '206' : {name : 'Svalbard and Jan Mayen', abbreviation : 'SJ', dialCode : 47},
    '207' : {name : 'Swaziland', abbreviation : 'SZ', dialCode : 268},
    '208' : {name : 'Sweden', abbreviation : 'SE', dialCode : 46},
    '209' : {name : 'Switzerland', abbreviation : 'CH', dialCode : 41},
    '210' : {name : 'Syria', abbreviation : 'SY', dialCode : 963},
    '211' : {name : 'Taiwan', abbreviation : 'TW', dialCode : 886},
    '212' : {name : 'Tajikistan', abbreviation : 'TJ', dialCode : 992},
    '213' : {name : 'Tanzania', abbreviation : 'TZ', dialCode : 255},
    '214' : {name : 'Thailand', abbreviation : 'TH', dialCode : 66},
    '215' : {name : 'Togo', abbreviation : 'TG', dialCode : 228},
    '216' : {name : 'Tokelau', abbreviation : 'TK', dialCode : 690},
    '217' : {name : 'Tonga', abbreviation : 'TO', dialCode : 676},
    '218' : {name : 'Trinidad and Tobago', abbreviation : 'TT', dialCode : 1},
    '219' : {name : 'Tunisia', abbreviation : 'TN', dialCode : 216},
    '220' : {name : 'Turkey', abbreviation : 'TR', dialCode : 90},
    '221' : {name : 'Turkmenistan', abbreviation : 'TM', dialCode : 993},
    '222' : {name : 'Turks and Caicos Islands', abbreviation : 'TC', dialCode : 1},
    '223' : {name : 'Tuvalu', abbreviation : 'TV', dialCode : 688},
    '224' : {name : 'U.S. Virgin Islands', abbreviation : 'VI', dialCode : 1},
    '225' : {name : 'Uganda', abbreviation : 'UG', dialCode : 256},
    '226' : {name : 'Ukraine', abbreviation : 'UA', dialCode : 380},
    '227' : {name : 'United Arab Emirates', abbreviation : 'AE', dialCode : 971},
    '228' : {name : 'United Kingdom', abbreviation : 'GB', dialCode : 44},
    '229' : {name : 'United States', abbreviation : 'US', dialCode : 1},
    '230' : {name : 'Uruguay', abbreviation : 'UY', dialCode : 598},
    '231' : {name : 'Uzbekistan', abbreviation : 'UZ', dialCode : 998},
    '232' : {name : 'Vanuatu', abbreviation : 'VU', dialCode : 678},
    '233' : {name : 'Vatican', abbreviation : 'VA', dialCode : 379},
    '234' : {name : 'Venezuela', abbreviation : 'VE', dialCode : 58},
    '235' : {name : 'Vietnam', abbreviation : 'VN', dialCode : 84},
    '236' : {name : 'Wallis and Futuna', abbreviation : 'WF', dialCode : 681},
    '237' : {name : 'Western Sahara', abbreviation : 'EH', dialCode : 212},
    '238' : {name : 'Yemen', abbreviation : 'YE', dialCode : 967},
    '239' : {name : 'Zambia', abbreviation : 'ZM', dialCode : 260},
    '240' : {name : 'Zimbabwe', abbreviation : 'ZW', dialCode : 263},
  },
};
