import React from 'react';
import './Incoming.scss';
import Handlebars from 'handlebars';

const Incoming = ({ caller, userJob, user, tags, agentGreeting, acceptCall, declineCall }) => {

  React.useEffect(() => {
    let ringAudio = new Audio('/audio/ring.mp3');
    ringAudio.loop = true;
    ringAudio.play();

    return () => {
      ringAudio.pause();
      ringAudio.currentTime = 0;
      ringAudio = null;
    };
  }, []);

  return (
    <div className="incoming-call">
      <div className="customer-info">
        {caller.name ? <div>Caller Name: {caller.name}</div> : ''}
        <div>
          Caller Id: {caller.phoneNumber}
        </div>
      </div>
      <div className="company">
        <img className="company__logo" src={userJob.company.logo} alt="company logo"/>
        <div className="company__name">{userJob.company.name}</div>
      </div>
      <div className="job__company-and-title">{userJob.title}</div>
      <div className="agent-greeting">{Handlebars.compile(agentGreeting)({agent: {firstName: user.firstName, lastName: user.lastName}, tags: tags})}</div>
      <button className="fa fa-ban call__decline" onClick={declineCall}>&nbsp;&nbsp;Decline</button>
      <button className="fa fa-phone call__accept" onClick={acceptCall}>&nbsp;&nbsp;Accept</button>
    </div>
  );
};

export default Incoming;
