'use strict'

module.exports = {
  SIP : 1,
  PHONE_NUMBER : 2,
  HTTP : 3,
  COMPONENT : 4,
  NAIVE_COMPONENT : 5,
  ids : {
    '1' : 'SIP',
    '2' : 'Phone Number',
    '3' : 'HTTP',
    '4' : 'Component',
    '5' : 'Naive Component',
  },
};
