import React, { useState } from 'react';
import useFetch from '../hooks/useFetch';
import Dialer from '../Dialer';
import { agentComponentStatus, callDetailType, componentType } from 'agents-remotely-commons/src/model';
import { notifiedComponent } from '../graphql/subscriptions';
import { useWebSocket } from '../hooks/useWebSocket';
import { currency } from 'agents-remotely-commons/src/formatter';

const UserJobComponent = (props) => {
  const {connected, connectionError, error, client} = useWebSocket();
  const [number, setNumber] = useState('');
  const [component, setComponent] = useState();

  React.useEffect(() => {
    setComponent(props.component);

    if(!props.jobActive) {
      //handled below because component reloaded when state changes
      //client.unsubscribe(props.component.id);
    } else {
      client.subscribe({
        id: props.component.id,
        query: notifiedComponent,
        variables: {
          id: component.id
        },
        messageHandler: (data) => {
          setComponent(current => {
            const newComponent = {...current};
            if(data.notifiedComponent.agentStatuses) {
              newComponent.agentStatuses = newComponent.agentStatuses || {};
              for(const agentStatus of data.notifiedComponent.agentStatuses) {
                newComponent.agentStatuses[agentComponentStatus.ids[agentStatus.id]] = agentStatus.count;
              }
            }
            if(data.notifiedComponent.callStatuses) {
              newComponent.callStatuses = newComponent.callStatuses || {};
              for(const callStatus of data.notifiedComponent.callStatuses) {
                newComponent.callStatuses[callDetailType.ids[callStatus.id]] = callStatus.count;
              }
            }

            return newComponent;
          });
        },
        errorHandler: (err) => {
          console.log(err);
        },
      });
    }

    return () => {
      client.unsubscribe(props.component.id);
    };
  }, [props.jobActive]);

  if(component) {
    const handleCall = () => {
    //  device.connect({ To: number });
    };

    return (
      <div className="component work-component">
        <div>
          <span className="component__name">{component.name}</span>&nbsp;@&nbsp;<span className="component__agent-payment-amount">{currency(component.payDetails[0].details[0].amount)}</span>&nbsp;<span className="component__agent-payment-type-short-description">{component.payDetails[0].details[0].shortDescription}</span>
        </div>
        {component.type.id !== componentType.PUSH_QUEUE && component.type.id !== componentType.PULL_QUEUE ?  
          <Dialer number={number} setNumber={setNumber} handleCall={handleCall}></Dialer>
          : ''
        }
        <div className="component__icon-line">
          <span className="component__icon fa fa-users"></span><span className="component__agent-statuses component__agent-statuses-online" title="Agents Online">{component.agentStatuses?.[agentComponentStatus.ids[agentComponentStatus.ONLINE]]||0}</span>
          <span className="component__icon fa fa-users-cog"></span><span className="component__agent-statuses component__agent-statuses-actively-working" title="Agents On Call">{component.agentStatuses?.[agentComponentStatus.ids[agentComponentStatus.PENDING_WORK]]||0 + component.agentStatuses?.[agentComponentStatus.ids[agentComponentStatus.ACTIVELY_WORKING]]||0}</span>
          <span className="component__icon fa fa-sort-amount-up"></span><span className="component__agent-statuses component__agent-statuses-queued" title="Queued Calls">{component.callStatuses?.[callDetailType.ids[callDetailType.QUEUED]]||0 + component.callStatuses?.[callDetailType.ids[callDetailType.REQUEUED]]||0}</span>
          <span className="component__icon-history component__icon fa fa-history" title="Toggle History"></span>
          {component.type.id === componentType.PULL_QUEUE ? 
            <span className="component__icon fa fa-phone-alt">Retrieve</span>
            : ''
          }
        </div>
        {component.calls ? 
          <div className="component__calls is-hidden"></div>
          : ''
        }
      </div>
    );
  }
};

const UserJob = ({userJob, active, toggleUserJob, minimalView}) => {
  const [enabled, setEnabled] = useState(false);

  React.useEffect(() => {
    setEnabled(active);
  }, []);

  const toggleEnabled = (e) => {
    setEnabled(!enabled);
    toggleUserJob(e, userJob.id);
  };

  return (
    <div className={enabled ? "job work-job work-job--enabled" : "job work-job"}>
      <div className="company">
        <img className="company__logo" src={userJob.company.logo} alt="company logo"/>
        <div className="company__name">{userJob.company.name}</div>
      </div>
      <div className="job__company-and-title">{userJob.title}</div>
      {!minimalView ?
        <>
          <div className="job__icon-line">
            <span className="job__icon fa fa-users"></span><span className="job__agent-statuses job__agent-statuses-online" title="Agents Online">{userJob.agentStatuses.Online}</span>
            <span className="job__icon fa fa-users-cog"></span><span className="job__agent-statuses job__agent-statuses-actively-working" title="Agents Busy">0</span>
          </div>
          <div className="components work-components">
            {userJob.components.map(component => <UserJobComponent component={component} jobActive={enabled} key={component.id}/>)}
          </div>
        </>
        : ''
      }
      <label className="switch"><input className="job__status-online" type="checkbox" checked={enabled} onChange={toggleEnabled}/><span className="slider"></span></label>
    </div>
  );
};

const UserJobs = ({userJobs, activeJobs, addActiveJob, removeActiveJob, minimalView}) => {
  const {apiFetch: userJobFetch, data: userJob, error: userJobError, loading: userJobLoading} = useFetch(null);

  const toggleUserJob = (e, id) => {
    if(e.currentTarget.checked) {
      userJobFetch('PUT', '/agents/{user.id}/jobs/' + id + '/online');
      addActiveJob(id);
    } else {
      userJobFetch('PUT', '/agents/{user.id}/jobs/' + id + '/offline');
      removeActiveJob(id);
    }
  };

  const rows = [];
  for(const userJob of userJobs) {
    rows.push(<UserJob userJob={userJob} key={userJob.id} toggleUserJob={toggleUserJob} active={activeJobs[userJob.id] || false} minimalView={minimalView} />)
  }
  return (
    <div className="content__main jobs">{rows}</div>
  );
};

export default UserJobs;
