import React from 'react';
import TextInput from '../components/TextInput';
import { url } from 'agents-remotely-commons/src/formatter';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { formatErrorMessage } from '../helper';

const ChangePasswordForm = () => {
  const {apiFetch, data: user, error, loading} = useFetch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if(user) {
      navigate(url('accountSettings'))
    }
  }, [user]);

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        newPassword: '',
        oldPassword: '',
      }}
      validationSchema={Yup.object({
        oldPassword: Yup.string()
          .required('Old password is required')
          .matches(/^.{8,}$/, 'Old password is invalid'),
        newPassword: Yup.string()
          .required('New password is required')
          .matches(/^.{8,}$/, 'New password is invalid'),
      })}
      onSubmit={async (values) => {
        apiFetch('PUT', '/agents/{user.id}/password', {...values, accessToken: '{user.accessToken}'});
      }}
    >
      <Form className="form change-password">
        {error ? <div className="form__error">{formatErrorMessage(error)}</div> : ''}
        <div className="form__line">
          <TextInput
            formName="change-password"
            label="Old Password"
            name="oldPassword"
            type="password"
            autoComplete="old-password"
            autoCorrect="off"
            spellCheck="false"
            pattern="^.{8,}$"
            required="required"
          />
        </div>
        <div className="form__line">
          <TextInput
            formName="change-password"
            label="New Password"
            name="newPassword"
            type="password"
            autoComplete="new-password"
            autoCorrect="off"
            spellCheck="false"
            pattern="^.{8,}$"
            required="required"
          />
        </div>
        <div className="form__line">
          {loading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Change Password</button>}
        </div>
      </Form>
    </Formik>
  );
}

export default ChangePasswordForm;
