'use strict'

const ids = {
  '1' : 'USD',
  '2' : 'AED',
  '3' : 'ARS',
  '4' : 'AUD',
  '5' : 'BDT',
  '6' : 'BGN',
  '7' : 'BRL',
  '8' : 'CAD',
  '9' : 'CHF',
  '10' : 'CLP',
  '11' : 'CNY',
  '12' : 'CZK',
  '13' : 'DKK',
  '14' : 'EGP',
  '15' : 'EUR',
  '16' : 'GBP',
  '17' : 'GEL',
  '18' : 'GHS',
  '19' : 'HKD',
  '20' : 'HRK',
  '21' : 'HUF',
  '22' : 'IDR',
  '23' : 'ILS',
  '24' : 'INR',
  '25' : 'JPY',
  '26' : 'KES',
  '27' : 'KRW',
  '28' : 'LKR',
  '29' : 'MAD',
  '30' : 'MXN',
  '31' : 'MYR',
  '32' : 'NGN',
  '33' : 'NOK',
  '34' : 'NPR',
  '35' : 'NZD',
  '36' : 'PHP',
  '37' : 'PKR',
  '38' : 'PLN',
  '39' : 'RON',
  '40' : 'RUB',
  '41' : 'SEK',
  '42' : 'SGD',
  '43' : 'THB',
  '44' : 'TRY',
  '45' : 'UAH',
  '46' : 'VND',
  '47' : 'ZAR',
};
const suffixes = JSON.parse(JSON.stringify(ids));
delete suffixes['1'];

module.exports = {
  USD : 1,
  AED : 2,
  ARS : 3,
  AUD : 4,
  BDT : 5,
  BGN : 6,
  BRL : 7,
  CAD : 8,
  CHF : 9,
  CLP : 10,
  CNY : 11,
  CZK : 12,
  DKK : 13,
  EGP : 14,
  EUR : 15,
  GBP : 16,
  GEL : 17,
  GHS : 18,
  HKD : 19,
  HRK : 20,
  HUF : 21,
  IDR : 22,
  ILS : 23,
  INR : 24,
  JPY : 25,
  KES : 26,
  KRW : 27,
  LKR : 28,
  MAD : 29,
  MXN : 30,
  MYR : 31,
  NGN : 32,
  NOK : 33,
  NPR : 34,
  NZD : 35,
  PHP : 36,
  PKR : 37,
  PLN : 38,
  RON : 39,
  RUB : 40,
  SEK : 41,
  SGD : 42,
  THB : 43,
  TRY : 44,
  UAH : 45,
  VND : 46,
  ZAR : 47,
  ids : ids,
  prefixes: {
    '1': '$',
  },
  suffixes: suffixes,
};
