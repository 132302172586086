import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getQueryStringParameter, sendReceive } from '../../helper';
import { url } from 'agents-remotely-commons/src/formatter';
import useFetch from '../../hooks/useFetch';
import { useAuth } from '../../hooks/useAuth';
import TypingTestForm from '../../components/TypingTestForm';

const TypingTest = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [error, setError] = useState();
  const {apiFetch: testFetch, data: test, error: testError, loading: testLoading} = useFetch();
  const isPractice = useRef(false);

  React.useEffect(() => {
    if(getQueryStringParameter('practice') === 'true') {
      isPractice.current = true;
      testFetch('GET', '/tests/' + getQueryStringParameter('id'), {
        select: ['id', 'typeId', 'companyId', 'description', 'practiceComponent.id', 'practiceComponent.name', 'practiceComponent.companyId', 'practiceComponent.content']
      });
    } else {
      testFetch('GET', '/tests/' + getQueryStringParameter('id'), {
        select: ['id', 'typeId', 'companyId', 'description', 'component.id', 'component.name', 'component.companyId', 'component.content'],
      });
    }
  }, []);

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>{test ? test.company.name + ' ' + test.type.description + ' Typing Test' : ''}</h1>
      </div>
      <div className="content__container-full">
        {!test ? <div className="processing"></div> :
          <TypingTestForm test={test} practice={isPractice.current}/>
        }
      </div>
    </section>
  );
}

export default TypingTest;
