'use strict'
const eventCategory = require('./event-category');
const userEventType = require('./user-event-type');
const callDetailType = require('./call-detail-type');
const aggregatorType = require('./aggregator-type');
const operatorType = require('./operator-type');
const timeIntervalType = require('./time-interval-type');
const timeIntervalUnitType = require('./time-interval-unit-type');
const conditionRequirementType = require('./condition-requirement-type');
const calendar = require('./calendar');
const rewardType = require('./reward-type');
const agentRole = require('./agent-role');
const badges = require('./badge');

const SECURE_ACCOUNT = {
  id: '82db7000-0ea3-11ec-9e54-69afbee345c0',
  createdAt: 1640995200000,
  startedAt: 1640995200000,
  requiredAt: 1640995200000,
  description: 'Secure Your Account',
  streak: {
    target: 1,
  },
  repeat: {
    limit: 1,
  },
  rewards: [{
    typeId: rewardType.ROLE,
    role: agentRole.VERIFIED,
  }, {
    typeId: rewardType.TOKENS,
    amount: 10,
  }, {
    typeId: rewardType.BADGE,
    badgeId: '367d50a0-1b50-11ee-9cbc-1b9caf86fabf',
  }, {
    typeId: rewardType.GOAL,
    goalId: 'fea46860-44bc-11eb-b163-8b97bd2e8da8',
  }, {
    typeId: rewardType.GOAL,
    goalId: 'd954dd10-44bc-11eb-b2fd-d958c6ec919d',
  }, {
    typeId: rewardType.TEST,
    testId: 'b879d340-b074-11ed-b030-f167d2e6b894',
  }, {
    typeId: rewardType.TEST,
    testId: '018fb020-a564-11ed-9456-dfedb744a15b',
  }, {
    typeId: rewardType.TEST,
    testId: '01907370-a564-11ed-9456-dfedb744a15b',
  }, {
    typeId: rewardType.TEST,
    testId: '01907371-a564-11ed-9456-dfedb744a15b',
  }, {
    typeId: rewardType.TEST,
    testId: '01907372-a564-11ed-9456-dfedb744a15b',
  }, {
    typeId: rewardType.TEST,
    testId: '01907373-a564-11ed-9456-dfedb744a15b',
  }, {
    typeId: rewardType.TEST,
    testId: '01907374-a564-11ed-9456-dfedb744a15b',
  }, {
    typeId: rewardType.TEST,
    testId: '01907375-a564-11ed-9456-dfedb744a15b',
  }, {
    typeId: rewardType.TEST,
    testId: '01907376-a564-11ed-9456-dfedb744a15b',
  }, {
    typeId: rewardType.TEST,
    testId: '01907377-a564-11ed-9456-dfedb744a15b',
  }, {
    typeId: rewardType.TEST,
    testId: '01909a80-a564-11ed-9456-dfedb744a15b',
  }, {
    typeId: rewardType.TEST,
    testId: 'ef1a58a0-a563-11ed-a5ce-619ec3aebdda',
  }],
  conditions: [{
    id: 'dc046e50-959f-11ed-ba51-1309d7d810b7',
    description: 'Verify Your Email',
    actionUrl: {name: 'emailVerification'},
    estimatedTimeRange: [1,2],
    streak: {
      target: 1,
    },
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.VERIFIED_EMAIL],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.EQUAL,
      target: 1,
    }],
  }, {
    id: 'dc050a90-959f-11ed-ba51-1309d7d810b7',
    description: 'Verify Your Phone',
    actionUrl: {name: 'phoneVerification'},
    estimatedTimeRange: [1,2],
    streak: {
      target: 1,
    },
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.VERIFIED_PHONE_NUMBER],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.EQUAL,
      target: 1,
    }],
  }, {
    id: 'dc050a91-959f-11ed-ba51-1309d7d810b7',
    description: 'Setup Multi-Factor Authentication',
    estimatedTimeRange: [1,2],
    actionUrl: {name: 'mfa'},
    streak: {
      target: 1,
    },
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.ADDED_MFA],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.EQUAL,
      target: 1,
    }],
  }],
};

const COMPLETE_PROFILE = {
  id: 'fea46860-44bc-11eb-b163-8b97bd2e8da8',
  createdAt: 1640995200000,
  startedAt: 1640995200000,
  requiredAt: 1640995200000,
  description: 'Finish Setting Up Your Account',
  streak: {
    target: 1,
  },
  repeat: {
    limit: 1,
  },
  rewards: [{
    typeId: rewardType.TOKENS,
    amount: 10,
  }, {
    typeId: rewardType.BADGE,
    badgeId: '367e3b01-1b50-11ee-9cbc-1b9caf86fabf',
  }],
  conditions: [{
    id: 'dc050a92-959f-11ed-ba51-1309d7d810b7',
    description: 'Update your profile',
    actionUrl: {name: 'personalInfo'},
    estimatedTimeRange: [1,2],
    streak: {
      target: 1,
    },
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.UPDATED_ADDRESS],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.EQUAL,
      target: 1,
    }],
  }, {
    id: 'dc050a93-959f-11ed-ba51-1309d7d810b7',
    description: 'Provide your experience & interests',
    actionUrl: {name: 'workSettings'},
    estimatedTimeRange: [1,2],
    streak: {
      target: 1,
    },
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.UPDATED_RESUME],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.EQUAL,
      target: 1,
    }],
  }, {
    id: '21094ed0-0c4c-11ee-bdca-b1aacc5fde24',
    description: 'Set your time and availability',
    actionUrl: {name: 'availability'},
    estimatedTimeRange: [1,2],
    streak: {
      target: 1,
    },
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.UPDATED_TIME_ZONE],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.EQUAL,
      target: 1,
    }],
  }, {
    id: 'dc050a94-959f-11ed-ba51-1309d7d810b7',
    description: 'Add an account to get paid',
    actionUrl: {name: 'paymentMethods'},
    estimatedTimeRange: [1,2],
    streak: {
      target: 1,
    },
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.ADDED_PAYMENT_METHOD],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.EQUAL,
      target: 1,
    }],
  }],
  preRequisiteGoalIds: [
    SECURE_ACCOUNT.id,
  ],
};

const SHOW_YOUR_SKILLS = {
  id: 'd954dd10-44bc-11eb-b2fd-d958c6ec919d',
  createdAt: 1640995200000,
  startedAt: 1640995200000,
  requiredAt: 1640995200000,
  description: 'Show Your Skills',
  streak: {
    target: 1,
  },
  repeat: {
    limit: 1,
  },
  rewards: [{
    typeId: rewardType.TOKENS,
    amount: 10,
  }, {
    typeId: rewardType.BADGE,
    badgeId: '367e3b00-1b50-11ee-9cbc-1b9caf86fabf',
  }],
  conditions: [{
    id: 'dc050a95-959f-11ed-ba51-1309d7d810b7',
    description: 'Complete the interview',
    actionUrl: {name: 'tests'},
    estimatedTimeRange: [3,6],
    streak: {
      target: 1,
    },
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.COMPLETED_INTERVIEW],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.EQUAL,
      target: 1,
    }],
  }, {
    id: 'dc050a96-959f-11ed-ba51-1309d7d810b7',
    description: 'Complete a verbal language test',
    actionUrl: {name: 'tests'},
    estimatedTimeRange: [1,2],
    streak: {
      target: 1,
    },
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.COMPLETED_VERBAL_TEST],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.EQUAL,
      target: 1,
    }],
  }, {
    id: 'dc050a97-959f-11ed-ba51-1309d7d810b7',
    description: 'Complete a transcription test',
    actionUrl: {name: 'tests'},
    estimatedTimeRange: [1,2],
    streak: {
      target: 1,
    },
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.COMPLETED_TRANSCRIPTION_TEST],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.EQUAL,
      target: 1,
    }],
  }, {
    id: 'dc0531a0-959f-11ed-ba51-1309d7d810b7',
    description: 'Complete a typing speed test',
    actionUrl: {name: 'tests'},
    estimatedTimeRange: [1,2],
    streak: {
      target: 1,
    },
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.COMPLETED_TYPING_TEST],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.EQUAL,
      target: 1,
    }],
  }],
  preRequisiteGoalIds: [
    COMPLETE_PROFILE.id,
  ],
};

const SOME_STREAK = {
  id: 'TODO',
  createdAt: 1640995200000,
  startedAt: 1640995200000,
  description: 'Consistent',
  streak: {
    target: 1,
  },
  repeat: {
    limit: 1,
  },
  conditions: [{
    description: 'Log in 5 days in a row',
    streak: {
      timeInterval: {
        typeId: timeIntervalType.PINNED,
        unitTypeId: timeIntervalUnitType.DAY,
        unit: 1,
      },
      target: 5,
    },
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.LOGIN],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.EQUAL,
      target: 1,
    }],
  }],
  preRequisiteGoalIds: [
    COMPLETE_PROFILE.id,
  ],
};

const SOME_AGGREGATOR = {
  id: 'TODO',
  createdAt: 1640995200000,
  startedAt: 1640995200000,
  description: 'Avg Sales',
  streak: {
    target: 1,
  },
  repeat: {
    timeInterval: {
      typeId: timeIntervalType.PINNED,
      unitTypeId: timeIntervalUnitType.DAY,
      unit: 1,
    },
    limit: 5,
  },
  conditions: [{
    description: 'Maintain avg rev/call with min 10 calls on a weekend',
    streak: {
      timeInterval: {
        typeId: timeIntervalType.PINNED,
        unitTypeId: timeIntervalUnitType.DAY,
        unit: 2,
      },
      target: 5,
    },
    requirements: [{
      categoryId: eventCategory.USER,
      operatorTypeId: operatorType.IN,
      target: [calendar.SATURDAY, calendar.SUNDAY],
    }],
    positives: [{
      categoryId: eventCategory.USER,
      typeIds: [userEventType.AVG_REV_CALL],
      aggregatorTypeId: aggregatorType.AVG,
      operatorTypeId: operatorType.GTE,
      target: 40,
    }, {
      categoryId: eventCategory.USER,
      typeIds: [userEventType.CALLS],
      aggregatorTypeId: aggregatorType.COUNT,
      operatorTypeId: operatorType.GTE,
      target: 10,
    }],
  }],
  preRequisiteGoalIds: [
    COMPLETE_PROFILE.id,
  ],
};

module.exports = {
  COMPLETE_PROFILE,
  SECURE_ACCOUNT,
  SHOW_YOUR_SKILLS,
};
