import { useState } from 'react';
import { useAuth } from './useAuth';
const DEBUG = true;
const API_URL = process.env.REACT_APP_AGENTS_REMOTELY_API_HOST || 'https://api.agentsremotely.com';

const sendReceive = (method, path, params, headers, responseType, attempt) => {
  params = params || {};
  responseType = responseType || 'application/json';
  attempt = attempt || 0;
  headers = headers || {};
  headers.Accept = headers.Accept || responseType;

  if(DEBUG) {
    console.log(params);
  }
  let url = path.indexOf('://') < 0 ? API_URL + path : path.substring(0); //do substring to clone
  let body = null;

  if(method === 'GET' || method === 'HEAD') {
    if(Object.keys(params).length > 0)  {
      url += '?' + Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
    }
  } else if(params instanceof FormData || params instanceof File || params instanceof Blob) {
    body = params;
  } else {
    body = JSON.stringify(params);
  }

  return fetch(url, {
    body: body,
    headers : headers,
    method: method,
    mode: 'cors',
    //credentials: 'include'
  }).then((response) => {
    if(responseType === 'application/json') {
      return response.json();
    } else if(responseType === 'application/octet-stream') {
      return response.blob();
    } else if(responseType !== 'text/plain' || responseType !== 'text/html' || responseType !== 'text/xml' || responseType !== 'javascript/text') {
      return response.arrayBuffer();
    } else {
      return response.text();
    }
  }).then(async (res) => {
    if(res && res.error) {
      if(res.error === 'Database down') {
        return sendReceive(method, path, params, headers, responseType, ++attempt);
      } else {
        throw res.error;
      }
    } else if(res && res.message === 'Endpoint request timed out') {
      return sendReceive(method, path, params, headers, responseType, ++attempt);
    } else {
      return res;
    }
  });
};

const useFetch = (initialValue) => {
  const [ data, setData ] = useState(initialValue);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState();
  const { reauthorize, signOut, user } = useAuth();

  const autoRetryFetch = async (method, path, params, headers, responseType) => {
    try {
      const res = await sendReceive(method, path, params, headers, responseType);
      if(res.refreshTokens) {
        await reauthorize();
      }
      return res;
    } catch(err) {
      if(err === 'The incoming token has expired') {
        await reauthorize();
        headers.Authorization = user.idToken;
        return autoRetryFetch(method, path, params, headers, responseType);
      } else if(err === 'Unauthorized') {
        signOut();
      } else {
        if(err.refreshTokens) {
          await reauthorize();
        }
        throw err;
      }
    }
  };

  const apiFetch = async (method, path, params, headers, responseType) => {
    setLoading(true);
    setData(null);
    setError(null);
    try {
      headers = headers || {};

      let convertedPath = path;
      if(user) {
        if((path.indexOf('agentsremotely.com') >= 0 || path.indexOf('https:') !== 0) && user.idToken) {
          headers.Authorization = user.idToken;
        }
        convertedPath = path.replace(/{user.id}/g, user.userId);

        if(params) {
          if(params.accessToken === '{user.accessToken}') {
            params.accessToken = user.accessToken;
          }
        }
      }

      const fetchData = await autoRetryFetch(method, convertedPath, params, headers, responseType);
      setData(fetchData);
      setLoading(false);
      return fetchData;
    } catch(err) {
      setError(err.message || err);
      setLoading(false);
    }
  };

  return {apiFetch, data, error, loading, setData};
};

export default useFetch;
