'use strict'

module.exports = {
  ACCEPTANCE_REQUIRED : 1,
  INVITE_ONLY : 2,
  PUBLIC : 3,
  ids : {
    '1' : 'Acceptance Required',
    '2' : 'Invite Only',
    '3' : 'Public',
  },
};
