import React, {useState} from "react";
import SelectInput from './SelectInput';
import { calendar } from 'agents-remotely-commons/src/model';

const Range = (props) => {
  const [startOptions, setStartOptions] = useState(props.filteredOptions);
  const [endOptions, setEndOptions] = useState(props.filteredOptions);
  const [startSelected, setStartSelected] = useState(props.range.startIndex);
  const [endSelected, setEndSelected] = useState(props.range.endIndex);

  const startUpdated = (value) => {
    const index = startOptions.findIndex(item => item.value === value);
    setEndOptions([...startOptions.slice(index + 1 < startOptions.length ? index + 1 : index)]);
    setStartSelected(props.options.findIndex(item => item.value === value));
    props.range.startIndex = props.options.findIndex(item => item.value === value);
    props.range.startTime = value;
    props.updateRanges();
  };

  const endUpdated = (value) => {
    setEndSelected(props.options.findIndex(item => item.value === value));
    props.range.endIndex = props.options.findIndex(item => item.value === value);
    props.range.endTime = value;
    props.updateRanges();

    if(props.lastRange) {
      props.setCanAdd(true);
    }
  };

  return (
    <div className="multirange__range">
      <div className="multirange__range-start">
        <SelectInput placeholder="Start" formName="" label="" name={props.name + "['" + props.id + "'][" + props.index + "].startTime"} options={startOptions} onChange={(e) => startUpdated(e)}/>
      </div>
      <div className="multirange__range-seperator">
        -
      </div>
      <div className="multirange__range-end">
        <SelectInput isDisabled={startSelected === -1} placeholder="End" formName="" label="" name={props.name + "['" + props.id + "'][" + props.index + "].endTime"} options={endOptions} onChange={(e) => endUpdated(e)}/>
      </div>
      {props.lastRange ? 
        <span className="multirange__remove button-circle-reverse" onClick={() => props.removeRow(props.index)}>x</span>: ''
      }
    </div>
  );
};

const MultiRange = (props) => {
  const [ranges, setRanges] = useState(props.initialRanges || []);
  const [options, setOptions] = useState(props.options);
  const [canAdd, setCanAdd] = useState(true);
  const [filteredOptions, setFilteredOptions] = useState(Array(props.options.length).fill(false));

  const addRow = () => {
    setRanges(current => [
      ...current,
      {startIndex: -1, endIndex: -1},
    ]);
    setCanAdd(false);
  };

  const removeRow = (index) => {
    props.updateFormValue(ranges.filter((_, i) => i !== index));
    setRanges(current => {
      const newRanges = current.filter((_, i) => i !== index)
      updateRanges(newRanges);
      return newRanges;
    });

    if(index === 0) {
      setCanAdd(true);
    }
  };

  const width = 287 / props.options.length;
  const updateRanges = (currentRanges) => {
    currentRanges = currentRanges || ranges;
    const fOptions = Array(props.options.length).fill(false);
    for(const range of currentRanges) {
      if(range.startIndex >= 0 && range.endIndex >= 0) {
        for(let i = range.startIndex; i < range.endIndex; i++) {
          fOptions[i] = true;
        }
      }
    }
    setFilteredOptions([...fOptions]);
  };

  React.useEffect(() => {
    if(ranges && ranges.length > 0) {
      for(const range of ranges) {
        range.startIndex = options.findIndex(item => item.value === range.startTime); 
        range.endIndex = options.findIndex(item => item.value === range.endTime); 
      }
      updateRanges();
    }
  }, []);

  return (
    <div className="multirange">
      <div className="multirange__label">
        {props.label}
      </div>
      <div className="multirange__ranges">
        <div className="multirange__bar">
          {filteredOptions.map((block, index) => index !== filteredOptions.length - 1 ? <div key={index} className={block ? "multirange__bar-segment--full" : "multirange__bar-segment"} style={{width: width}}></div> : '')}
        </div>
        {canAdd ? <span className="multirange__add button-circle-reverse" onClick={addRow}>+</span> : ''}
        {ranges.map((range, index) => {
          return <Range options={options} filteredOptions={options} id={props.id} name={props.name} index={index} key={index} lastRange={index === ranges.length - 1} removeRow={removeRow} setCanAdd={setCanAdd} range={range} updateRanges={updateRanges}/>;
        })}
      </div>
    </div>
  );
};

const DowMultiRange = (props) => {

  const hourAndMinutes = [];
  for(const id in calendar.hourMinute.ids) {
    hourAndMinutes.push({value: id, label: calendar.hourMinute.ids[id]});
  }
  hourAndMinutes.push({value: '24.00', label: calendar.hourMinute.ids['0.00']});

  const dows = [];
  for(const dowId in calendar.daysOfWeek.ids) {
    dows.push(<MultiRange key={dowId} id={dowId} name={props.name} label={calendar.daysOfWeek.ids[dowId]} options={hourAndMinutes} initialRanges={props.dowRanges?.[dowId]} updateFormValue={range => { if(props.dowRanges) {props.dowRanges[dowId] = range;} props.setFieldValue(props.dowRanges);}} />);
  }

  return (
    <>
      {dows}
    </>
  )
};

export default DowMultiRange;
