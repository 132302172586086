'use strict'

module.exports = {
  HOUR: 1,
  DAY: 2,
  WEEK: 3,
  MONTH: 4,
  YEAR: 5,
  DOW: 6,
};
