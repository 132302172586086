import React from 'react';
import TextInput from '../components/TextInput';
import PhoneNumberInput from '../components/PhoneNumberInput';
import { NavLink } from 'react-router-dom';
import { url } from 'agents-remotely-commons/src/formatter';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { formatErrorMessage } from '../helper';

const AccountSettingsForm = (props) => {
  const {apiFetch, data: user, error, loading} = useFetch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if(user) {
      navigate(url('dashboard'))
    }
  }, [user]);

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        email: props.agent.email,
        phoneNumber: props.agent.phone.number,
        phoneDialCode: props.agent.phone.dialCode,
        phoneCountryCode: props.agent.phone.countryCode,
      }}
      validationSchema={Yup.object({
        email: Yup.string().email('Email is invalid').required('Email is required'),
        phoneNumber: Yup.string()
          .required('Phone number is required')
          .min(10, 'Phone number must be at least 10 digits'),
      })}
      onSubmit={async (values) => {
        if(values.phoneNumber.indexOf('+') === 0) {
          values.phoneNumber = values.phoneNumber.substring(values.phoneDialCode.length + 1);
        }
        apiFetch('PUT', '/agents/{user.id}', {...values, accessToken: '{user.accessToken}'});
      }}
    >
      <Form className="form account-settings">
        {error ? <div className="form__error">{formatErrorMessage(error)}</div> : ''}
        <div className="form__line">
          <TextInput
            formName="account-settings"
            label="Email"
            name="email"
            type="email"
            autoComplete="email"
            autoCorrect="off"
            spellCheck="false"
            required="required"
          />
        </div>
        {!props.agent.emailVerified ? <div className="form__line"><NavLink className="form__link" to={url('emailVerification')}>Verify Email</NavLink></div> : ''}
        <div className="form__line">
          <PhoneNumberInput
            formName="account-settings"
            label="Phone Number"
            name="phoneNumber"
            defaultPhoneNumber={'+' + props.agent.phone.dialCode + props.agent.phone.number}
            defaultPhoneDialCode={'+' + props.agent.phone.dialCode}
            defaultPhoneCountryCode={props.agent.phone.countryCode}
          />
        </div>
        {!props.agent.phoneNumberVerified ? <div className="form__line"><NavLink className="form__link" to={url('phoneVerification')}>Verify Phone Number</NavLink></div> : ''}
        <div className="form__line">
          <NavLink className="form__link" to={url('mfa')}>Change MFA Device</NavLink>
        </div>
        <div className="form__line">
          <NavLink className="form__link" to={url('changePassword')}>Change Password</NavLink>
        </div>
        {loading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Update Info</button>}
      </Form>
    </Formik>
  );
}

export default AccountSettingsForm;
