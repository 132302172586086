import React from 'react';
import TextInput from './TextInput';
import HiddenInput from './HiddenInput';

const PayoneerForm = (props) => {
  return (
    <div className="payoneer-form">
      <div className="form__line">
        <HiddenInput
          formName="payoneer"
          name="currencyId"
          type="hidden"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          required="required"
        />
        <TextInput
          formName="payoneer"
          label="Payoneer Id"
          name="payoneerId"
          type="text"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          required="required"
        />
      </div>
    </div>
  );
};

export default PayoneerForm;
