import { sendReceive } from './helper';
class CarrierDevice {
  constructor(params) {
    this.metaInfo = params?.metaInfo || {};
    this.muted = false;
    this.earMuffed = {};
  };

  onReady() {
    console.log('todo implement');
  };
  onConnect() {
    console.log('todo implement');
  };
  onDisconnect() {
    console.log('todo implement');
  };
  onError() {
    console.log('todo implement');
  };
  onIncomingCall() {
    console.log('todo implement');
  };
  onCancel() {
    console.log('todo implement');
  };
  onOffline() {
    console.log('todo implement');
  };
  sendDigits(digits) {
    console.log('todo implement');
  };
  onAuthorization() {
    console.log('todo implement');
  };
  onDestroy() {
    console.log('todo implement');
  };
  toggleMute() {
    console.log('todo implement');
  };
  toggleEarMuff(user) {
    console.log('todo implement');
  };
  hangUp() {
    console.log('todo implement');
  };
  stopRecording() {
    console.log('todo implement');
  };
  startRecording() {
    console.log('todo implement');
  };

  connect() {
  };

  destroy() {
  };

  initialize() {
  };

  async authorize(params) {
    const authorizeResponse = await sendReceive('POST', this.getAuthorizationUrl(), {componentId:  this.metaInfo.component.id});
    if(!authorizeResponse.error) {
      await this.initialize(authorizeResponse.token, params);

      if(!localStorage.getItem('agentOnline')) {
        const updateStatusResponse = await sendReceive('PUT', '/agents/' + JSON.parse(localStorage.getItem('user')).userId + '/online');
        if(!updateStatusResponse.error) {
          localStorage.setItem('agentOnline', 'true');
        } else {
          console.log(updateStatusResponse.error);
        }
      }
      this.metaInfo.component.content = authorizeResponse.component.content;
    }
    this.onAuthorization(authorizeResponse);
  };
};

export {
  CarrierDevice,
};
