import React, { useRef, useState } from 'react';
import GeneralInfo from '../components/GeneralInfo';
import PhoneTest from '../components/PhoneTest';
import UserJobs from '../components/UserJobs';
import useFetch from '../hooks/useFetch';
import { useAuth } from '../hooks/useAuth';
import { useWebSocket } from '../hooks/useWebSocket';
import { softphoneStatus, workStatus, componentType, partyConnectionType } from 'agents-remotely-commons/src/model';
import { useNavigate } from 'react-router-dom';
import { url } from 'agents-remotely-commons/src/formatter';
import { notifiedCallStream, notifiedPushQueue } from '../graphql/subscriptions';
import {formatErrorMessage} from '../helper';
import { VonageDevice } from '../vonage-device';
import Dialer from '../Dialer';
import KeypadButton from '../KeypadButton';
import Incoming from '../Incoming';
import OnCall from '../OnCall';
import PostCall from '../PostCall';
import states from '../states';
import '../Phone.css';
import '../sass/dialer.scss';

const Work = () => {
  const navigate = useNavigate();
  const {connected, connectionError, error, client} = useWebSocket();
  const {user} = useAuth();
  const {apiFetch: userJobsFetch, data: userJobs, error: userJobsError, loading: userJobsLoading} = useFetch();
  const {apiFetch: statusChangeFetch, data: statusChange, error: statusChangeError, loading: statusChangeLoading, setData: setStatusChange} = useFetch();
  const {apiFetch: agentStatusFetch, data: agentStatus, error: agentStatusError, loading: agentStatusLoading} = useFetch();
  const {apiFetch: agentFetch, data: agent, error: agentError, loading: agentLoading} = useFetch();
  const [number, setNumber] = useState('');
  const [state, setState] = useState(states.OFFLINE);
  const stateRef = useRef({});
  stateRef.current = state;
  const device = useRef(null);
  const [activeComponent, setActiveComponent] = useState();
  const [customerSentiment, setCustomerSentiment] = useState('NEUTRAL');
  const [agentSentiment, setAgentSentiment] = useState('NEUTRAL');
  const [transcript, setTranscript] = useState([]);
  const [activeJobs, setActiveJobs] = useState({});
  const listenerFunctions = useRef({});
  
  React.useEffect(() => {
    if(!userJobs) {
      agentFetch('GET', '/agents/{user.id}', {
        select: ['callId', 'componentId'],
      });
      userJobsFetch('GET', '/agents/{user.id}/jobs');
    } else if(client) {
      const components = {};
      userJobs.forEach(userJob => userJob.components.forEach(component => components[component.id] = component));
      const subscriptionId = client.subscribe({
        query: notifiedPushQueue,
        variables: {
          agentId: user.userId,
        },
        messageHandler: async (data) => {
          if(data.notifiedPushQueue.componentId) {
            if(data.notifiedPushQueue.agentStatusId === workStatus.ONLINE) {
              if(device.current && device.current?.metaInfo.partyConnectionTypeId !== partyConnectionType.ONE_WAY) {
                device.current.destroy();
              }
              /*
              toggleCall(false);
              */
            } else if(data.notifiedPushQueue.agentStatusId === workStatus.ACTIVELY_WORK) {
              /*
              if(data.notifiedPushQueue.recordingUrl) {
                let transcription = '';
                if(data.notifiedPushQueue.transcriptionUrl) {
                  const transcriptionResponse = await sendReceive('GET', '/companies/' + device.current.metaInfo.component.companyId + '/' + data.notifiedPushQueue.transcriptionUrl, null, 'application/octet-stream');
                  transcription = transcriptionFormatter(JSON.parse(String.fromCharCode.apply(null, new Uint8Array(transcriptionResponse))).results);
                }

                document.querySelector('.call__caller-id').innerHTML = data.notifiedPushQueue.phoneNumberCalledFrom;
                initCallLayer();
                toggleCall(true);
                callAnswerButton.classList.add('is-hidden');
                microphoneButton.classList.add('is-hidden');
                recordingFormatter('/companies/' + device.current.metaInfo.component.companyId + '/' + data.notifiedPushQueue.recordingUrl, transcription);
              } else {
                //something went wrong
                toggleCall(false);
              }
              */
            } else if(data.notifiedPushQueue.agentStatusId === workStatus.PENDING_WORK) {
              console.log('Incoming call');
              device.current?.destroy();

              device.current = new VonageDevice({
                metaInfo: {
                  callId: data.notifiedPushQueue.callId,
                  callComponentId: data.notifiedPushQueue.callComponentId,
                  partyConnectionTypeId: data.notifiedPushQueue.partyConnectionTypeId,
                  phoneNumberCalledFrom: data.notifiedPushQueue.phoneNumberCalledFrom,
                  component: components[data.notifiedPushQueue.componentId],
                  phoneCampaignNumberAssignmentTags: data.notifiedPushQueue.phoneCampaignNumberAssignmentTags.reduce((map, obj) => {
                    map[obj.key] = JSON.parse(obj.value);
                    return map;
                  }, {}),
                },
              });

              device.current.onConnect = () => {
                setTranscript([]);
                setCustomerSentiment('NEUTRAL');
                setAgentSentiment('NEUTRAL');
                /* transcriptions
                client.subscribe({
                  id: 'test',
                  query: notifiedCallStream,
                  variables: {
                    callId: 'test',
                  },
                  messageHandler: async (data) => {
                    if(data.notifiedCallStream.sentiment) {
                      if(data.notifiedCallStream.role === 'CUSTOMER' && data.notifiedCallStream.sentiment !== customerSentiment) {
                        setCustomerSentiment(data.notifiedCallStream.sentiment);
                      } else if(data.notifiedCallStream.role === 'AGENT' && data.notifiedCallStream.sentiment !== agentSentiment) {
                        setAgentSentiment(data.notifiedCallStream.sentiment);
                      }
                    }
                    if(data.notifiedCallStream.transcript) {
                      setTranscript(previousState => [...previousState, {role: data.notifiedCallStream.role, text: data.notifiedCallStream.transcript}]);
                    }
                    console.log(data);
                  },
                  errorHandler: (err) => {
                    console.log('call wss err');
                    console.log(err);
                  },
                });
                */
                device.current.call.answer();
                setState(states.ON_CALL);
              };

              device.current.onDestroy = (wasConnected) => {
                //client.unsubscribe('test');  //used for transcriptions
                console.log('current state for destroy ' + stateRef.current);
                if(stateRef.current == states.POST_CALL || wasConnected) {  //can be called twice so avoid setting to ready if already in post call
                  setState(states.POST_CALL);
                } else {
                  setReadyState();
                }
              };

              device.current.onReady = () => {
                device.current.metaInfo.ready = true;
                if(device.current.metaInfo.status) {
                  updateSoftphoneStatus(device.current.metaInfo.status);
                }
              };

              device.current.authorize({
                closeProtection : true,
              });

              setActiveComponent(device.current.metaInfo.component);
              setState(states.INCOMING);
            } else if(data.notifiedPushQueue.agentStatusId === workStatus.DIALING) {
              const deviceParams = {
                phoneNumberCalled: device.current.metaInfo.endpoint,
                endpoint: device.current.metaInfo.endpoint,
                componentId: device.current.metaInfo.component.id,
                agentId: user.userId,
                companyId: device.current.metaInfo.component.companyId,
              };
              if(device.current.metaInfo.component.phoneNumber) {
                deviceParams.phoneNumberCalledFrom = device.current.metaInfo.component.phoneNumber;
              }
              device.current.connect(deviceParams);
            } else if(data.notifiedPushQueue.agentStatusId === workStatus.OFFLINE) {
              device.current?.destroy();
              navigate(url('dashboard'));
            }
          }
        },
        errorHandler: (err) => {
          console.log('componentwss err');
          console.log(err);
        },
      });

      listenerFunctions.current.presenceInterval = setInterval(() => {
        agentStatusFetch('PUT', '/agents/{user.id}/present');
      }, 10000);

      listenerFunctions.current.message = (event) => {
        if(event.messageType == 'device') {
          if(event.action === 'stopRecording') {
            device.current.stopRecording();
          } else if(event.action === 'startRecording') {
            device.current.startRecording();
          }
        }
        if(event.messageType) {
          console.log("Message received from the child: " + JSON.stringify(event.data)); // Message received from child
        }
      };
      window.addEventListener('message', listenerFunctions.current.message);

      return () => {
        agentStatusFetch('PUT', '/agents/{user.id}/offline');
        client.unsubscribe(subscriptionId);
        clearInterval(listenerFunctions.current.presenceInterval);
        window.removeEventListener('message', listenerFunctions.current.message);
      };
    }
  }, [client, userJobs]);

  const setReadyState = () => {
    console.log('ready state set');
    setActiveComponent(null);
    setState(states.READY);
  };

  const acceptCall = async () => {
    document.querySelector('.call__decline').classList.add('is-hidden');
    document.querySelector('.call__accept').classList.add('is-hidden');
    device.current.metaInfo.status = softphoneStatus.ACCEPT;
    if(device.current.metaInfo.ready) {
      updateSoftphoneStatus(softphoneStatus.ACCEPT);
    }
  };

  const declineCall = () => {
    document.querySelector('.call__decline').classList.add('is-hidden');
    document.querySelector('.call__accept').classList.add('is-hidden');
    device.current.metaInfo.status = softphoneStatus.DECLINE;
    if(device.current.metaInfo.ready) {
      updateSoftphoneStatus(softphoneStatus.DECLINE);
    }
  };

  const updateSoftphoneStatus = async (statusId) => {
    const params = {
      componentId : device.current.metaInfo.component.id,
      softphoneStatusId : statusId,
    };
    if(device.current.metaInfo.partyConnectionTypeId) {
      params.partyConnectionTypeId = device.current.metaInfo.partyConnectionTypeId;
    }
    if(device.current.metaInfo.callId) {
      params.callId = device.current.metaInfo.callId;
    }
    if(device.current.metaInfo.callComponentId) {
      params.callComponentId = device.current.metaInfo.callComponentId;
    }
    statusChangeFetch('POST', '/vonage/clientStatusChange', params);

    if(statusId === softphoneStatus.DECLINE) {
      device.current.decline();
    }
  };

  const handleCall = () => {
  //  device.current.connect({ To: number });
  };

  if(statusChange?.error) {
    console.log(statusChange.error);
    //showLog('Could not update status, see console.log');

    if(statusChange.error.description === 'Call no longer available') {
      device.current.destroy();
    }
    setStatusChange(statusChange);
  }

  const addActiveJob = (id) => {
    setActiveJobs({
      ...activeJobs,
      [id]: true,
    });
  };

  const removeActiveJob = (id) => {
    setActiveJobs(current => {
      const rest = {...current};
      delete rest[id];
      return rest;
    });
  };

  if(userJobs) {
    if(stateRef.current === states.OFFLINE) {
      if(agent?.callId) {
        let found = false;
        for(const userJob of userJobs) {
          for(const component of userJob.components) {
            if(component.id == agent.componentId) {
              setActiveComponent(component);
              found = true;
              break;
            }
          }
          if(found) {
            break;
          }
        }
        setState(states.POST_CALL);
      } else {
        return (
          <>
            {agentLoading ? <div className="processing"></div> : ''}
            {agent ?
              <section className="content content__auth">
                <div className="title">
                  <h1>Work</h1>
                </div>
                <div className="content__container">
                  <GeneralInfo contentGroups={[
                    {
                      text: 'Welcome',
                      content: [
                        {text: 'To start receiving calls/tasks simply flip the switch in the top right of any of your jobs you want. You can work multiple at once, and at any point you want to stop receiving calls/tasks flip it back. You can flip them off even while on a call and it will not disconnect you from the current call.'},
                      ]
                    }
                  ]}/>
                  <div className="content__form">
                    <PhoneTest showWrapper={true}>
                      <div className="general-section__line">You can begin working whenever you would like</div>
                      <button className="button" onClick={setReadyState}>Start Working</button>
                    </PhoneTest>
                  </div>
                </div>
              </section>
              : ''
            }
            {agentError ? <div className="error-message">{formatErrorMessage(agentError)}</div> : ''}
          </>
        );
      }
    } else if(stateRef.current === states.READY) {
      return (
        <section className="content content__auth">
          {userJobsLoading ? <div className="processing"></div> : ''}
          {userJobs ? <UserJobs userJobs={userJobs} activeJobs={activeJobs} addActiveJob={addActiveJob} removeActiveJob={removeActiveJob} minimalView={false} /> : ''}
          {userJobsError ? <div className="error-message">{formatErrorMessage(userJobsError)}</div> : ''}
        </section>
      );
    } else if(stateRef.current === states.INCOMING) {
      if(activeComponent.type.id === componentType.PULL_QUEUE) {
        /*
          device.currentReady = true;
          acceptCallEventListener(null);
          document.querySelector('.work-component-' + component.id + ' .component__pull-from-queue-processing').classList.add('is-hidden');
          if(document.querySelector('.work-component-' + component.id + ' .component__call-statuses-queued').innerHTML != '0') {
            document.querySelector('.work-component-' + component.id + ' .component__pull-from-queue').classList.remove('is-hidden');
          }
          */
      } else {
        return (
          <Incoming acceptCall={acceptCall} declineCall={declineCall} caller={{phoneNumber: device.current.metaInfo.phoneNumberCalledFrom}} userJob={userJobs[0]} user={user} tags={device.current.metaInfo?.phoneCampaignNumberAssignmentTags} agentGreeting={device.current.metaInfo?.phoneCampaignNumberAssignmentTags?.agentGreeting || activeComponent.agentGreeting}></Incoming>
        );
      }
    } else if(stateRef.current === states.POST_CALL) {
      return (
        <PostCall component={activeComponent} postDispositionHandler={setReadyState}></PostCall>
      );
    } else {
      const renderSentiment = (sentiment) => {
        switch(sentiment) {
          case 'ANGRY':
            return <div className="sentiment fas fa-angry"></div>
            break;
          case 'NEGATIVE':
            return <div className="sentiment fas fa-frown"></div>
            break;
          case 'NEUTRAL':
            return <div className="sentiment fas fa-meh"></div>
            break;
          case 'POSITVE':
            return <div className="sentiment fas fa-smile"></div>
            break;
          case 'HAPPY':
            return <div className="sentiment fas fa-smile-beam"></div>
            break;
        }
      };

      const renderTranscript = () => {
        return transcript.map((item, index) =>  <div className={`transcript-line--${item.role}`} key={index}>{item.text}</div>);
      }
      const formatScreenPopUrl = () => {
        const url = device.current.metaInfo?.phoneCampaignNumberAssignmentTags?.screenPopUrl || activeComponent.screenPopUrl;
        const hashLocation = url.indexOf('#') > -1 ? url.indexOf('#') : url.length;
        return url.substring(0, hashLocation) + (url.indexOf('?') < 0 ? '?' : '&') + 'callId=' + device.current.metaInfo.callId + '&agentId=' + user.userId + url.substring(hashLocation); 
      }
      const addScreenPop = () => {
        document.querySelector('.company-site').src = formatScreenPopUrl();
      };

      const iframeLoaded = (event) => {
        console.log('sending' + JSON.stringify(device.current.metaInfo?.phoneCampaignNumberAssignmentTags));
        event.currentTarget.contentWindow.postMessage({
          messageType: 'assignmentTags',
          data: device.current.metaInfo?.phoneCampaignNumberAssignmentTags,
        }, '*');
      };

            /*
              <Dialer number={number} setNumber={setNumber} handleCall={handleCall}></Dialer>
            <div className="player">
              <p className="player__message"></p>
              <div className="player__controls"><button className="player__button fa fa-play"></button>
                <div className="player__track">
                  <div className="player__progress"></div>
                  <div className="player__scrubber"></div>
                </div>
              </div>
              <p className="player__link">Add Disposition</p>
              <p className="player__transcription"></p>
            </div>
            <div className="component__call">
              <div className="component__call-phone-number"></div>
              <div className="component__call-created-at"></div>
            </div>
            <div className="past-call">
              <div className="past-call__phone-number-called-from"></div>
              <div className="past-call__phone-number-called"></div>
              <div className="past-call__created-at"></div>
              <div className="past-call__ended-at"></div>
            </div>
            */
      /*
          <section className="content content__auth">
            {userJobsLoading ? <div className="processing"></div> : ''}
            {userJobs ? <UserJobs userJobs={userJobs} activeJobs={activeJobs} addActiveJob={addActiveJob} removeActiveJob={removeActiveJob} minimalView={true} /> : ''}
            {userJobsError ? <div className="error-message">{formatErrorMessage(userJobsError)}</div> : ''}
          </section>
          */
      return (
        <>
          <div className="call-container">
            <div className="active-call">
              <div className="active-call__row call-real-time">
                <div className="customer-sentiment">
                  {renderSentiment(customerSentiment)}
                </div>
                <div className="agent-sentiment">
                  {renderSentiment(agentSentiment)}
                </div>
                <div className="transcription">
                  {renderTranscript()}
                </div>
              </div>
            </div>
            <section className="content content__auth">
              <div className="active-call__header">
                <OnCall device={device.current} addScreenPop={addScreenPop}></OnCall>
              </div>
              <iframe className="company-site" src={formatScreenPopUrl()} onLoad={iframeLoaded}/>
            </section>
          </div>
        </>
      );
    }
  }
}

export default Work;
