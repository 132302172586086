import React from 'react';
import GeneralInfo from '../../components/GeneralInfo';
import ChangePasswordForm from '../../components/ChangePasswordForm';
import {formatErrorMessage} from '../../helper';

const ChangePassword = (props) => {
  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Change Password</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Requirements',
            content: [
              {text: 'Minimum length 8 characters'},
              {text: 'Must include numeric, special, lower and uppercase characters'},
            ],
          },
        ]}/>
        <div className="change-password-form content__form">
          <ChangePasswordForm formName="change-password"/>
        </div>
      </div>
    </section>
  );
}

export default ChangePassword;
