import React from 'react';
import { NavLink } from 'react-router-dom';
import { url } from 'agents-remotely-commons/src/formatter';

const Header = (props) => {
  return (
    <section className="header">
      <div className="header__background"></div>
      <NavLink className="logo" to={url('home')}>
        <div className="logo__icon">
          <span className="logo__name">Agents</span>
          <span className="logo__name">Remotely</span>
        </div>
      </NavLink>
      <div className="header__links">
        <NavLink className="button-reverse" to={url('signIn')}>Login</NavLink>
        <NavLink className="button" to={url('signUp')}>Sign Up</NavLink>
      </div>
    </section>
  );
}

export default Header;
