'use strict'

const DAYS = {};
for(let i = 1; i < 32; i++) {
  DAYS[i] = i;
}

const YEARS = {};
const year = new Date().getUTCFullYear() - 16;
for(let i = 0; i < 100; i++) {
  YEARS[year - i] = year - i;
}

module.exports = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
  daysOfWeek : {
    ids : { 
      '1' : 'Monday',
      '2' : 'Tuesday',
      '3' : 'Wednesday',
      '4' : 'Thursday',
      '5' : 'Friday',
      '6' : 'Saturday',
      '7' : 'Sunday',
    },
  },
  month : {
    ids : { 
      '1' : 'January',
      '2' : 'February',
      '3' : 'March',
      '4' : 'April',
      '5' : 'May',
      '6' : 'June',
      '7' : 'July',
      '8' : 'August',
      '9' : 'September',
      '10' : 'October',
      '11' : 'November',
      '12' : 'December',
    },
  },
  hour : {
    ids : {
      '0' : '12 am',
      '1' : '1 am',
      '2' : '2 am',
      '3' : '3 am',
      '4' : '4 am',
      '5' : '5 am',
      '6' : '6 am',
      '7' : '7 am',
      '8' : '8 am',
      '9' : '9 am',
      '10' : '10 am',
      '11' : '11 am',
      '12' : '12 pm',
      '13' : '1 pm',
      '14' : '2 pm',
      '15' : '3 pm',
      '16' : '4 pm',
      '17' : '5 pm',
      '18' : '6 pm',
      '19' : '7 pm',
      '20' : '8 pm',
      '21' : '9 pm',
      '22' : '10 pm',
      '23' : '11 pm',
    },
  },
  minute : {
    ids15 : {
      '0' : 0,
      '15' : 15,
      '30' : 30,
      '45' : 45,
    },
  },
  hourMinute : {
    ids : {
      '0.00' : '12:00 AM',
      '0.15' : '12:15 AM',
      '0.30' : '12:30 AM',
      '0.45' : '12:45 AM',
      '1.00' : '1:00 AM',
      '1.15' : '1:15 AM',
      '1.30' : '1:30 AM',
      '1.45' : '1:45 AM',
      '2.00' : '2:00 AM',
      '2.15' : '2:15 AM',
      '2.30' : '2:30 AM',
      '2.45' : '2:45 AM',
      '3.00' : '3:00 AM',
      '3.15' : '3:15 AM',
      '3.30' : '3:30 AM',
      '3.45' : '3:45 AM',
      '4.00' : '4:00 AM',
      '4.15' : '4:15 AM',
      '4.30' : '4:30 AM',
      '4.45' : '4:45 AM',
      '5.00' : '5:00 AM',
      '5.15' : '5:15 AM',
      '5.30' : '5:30 AM',
      '5.45' : '5:45 AM',
      '6.00' : '6:00 AM',
      '6.15' : '6:15 AM',
      '6.30' : '6:30 AM',
      '6.45' : '6:45 AM',
      '7.00' : '7:00 AM',
      '7.15' : '7:15 AM',
      '7.30' : '7:30 AM',
      '7.45' : '7:45 AM',
      '8.00' : '8:00 AM',
      '8.15' : '8:15 AM',
      '8.30' : '8:30 AM',
      '8.45' : '8:45 AM',
      '9.00' : '9:00 AM',
      '9.15' : '9:15 AM',
      '9.30' : '9:30 AM',
      '9.45' : '9:45 AM',
      '10.00' : '10:00 AM',
      '10.15' : '10:15 AM',
      '10.30' : '10:30 AM',
      '10.45' : '10:45 AM',
      '11.00' : '11:00 AM',
      '11.15' : '11:15 AM',
      '11.30' : '11:30 AM',
      '11.45' : '11:45 AM',
      '12.00' : '12:00 PM',
      '12.15' : '12:15 PM',
      '12.30' : '12:30 PM',
      '12.45' : '12.45 PM',
      '13.00' : '1:00 PM',
      '13.15' : '1:15 PM',
      '13.30' : '1:30 PM',
      '13.45' : '1.45 PM',
      '14.00' : '2:00 PM',
      '14.15' : '2:15 PM',
      '14.30' : '2:30 PM',
      '14.45' : '2.45 PM',
      '15.00' : '3:00 PM',
      '15.15' : '3:15 PM',
      '15.30' : '3:30 PM',
      '15.45' : '3.45 PM',
      '16.00' : '4:00 PM',
      '16.15' : '4:15 PM',
      '16.30' : '4:30 PM',
      '16.45' : '4.45 PM',
      '17.00' : '5:00 PM',
      '17.15' : '5:15 PM',
      '17.30' : '5:30 PM',
      '17.45' : '5.45 PM',
      '18.00' : '6:00 PM',
      '18.15' : '6:15 PM',
      '18.30' : '6:30 PM',
      '18.45' : '6.45 PM',
      '19.00' : '7:00 PM',
      '19.15' : '7:15 PM',
      '19.30' : '7:30 PM',
      '19.45' : '7.45 PM',
      '20.00' : '8:00 PM',
      '20.15' : '8:15 PM',
      '20.30' : '8:30 PM',
      '20.45' : '8.45 PM',
      '21.00' : '9:00 PM',
      '21.15' : '9:15 PM',
      '21.30' : '9:30 PM',
      '21.45' : '9.45 PM',
      '22.00' : '10:00 PM',
      '22.15' : '10:15 PM',
      '22.30' : '10:30 PM',
      '22.45' : '10.45 PM',
      '23.00' : '11:00 PM',
      '23.15' : '11:15 PM',
      '23.30' : '11:30 PM',
      '23.45' : '11.45 PM',
    },
  },
  day : {
    ids : DAYS,
  },
  year : {
    ids : YEARS,
  },
};
