import React from 'react';
import { NavLink } from 'react-router-dom';
import { url } from 'agents-remotely-commons/src/formatter';
import { gameType } from 'agents-remotely-commons/src/model';
import GeneralInfo from '../../components/GeneralInfo';
import useFetch from '../../hooks/useFetch';
import {formatErrorMessage} from '../../helper';
import { update as updateTokens } from '../../features/tokensSlice';
import { useDispatch } from 'react-redux';

const Tokens = () => {
  const {apiFetch: agentFetch, data: agent, error: agentError, loading: agentLoading} = useFetch();
  const {apiFetch: gamesFetch, data: games, error: gamesError, loading: gamesLoading} = useFetch();
  const dispatch = useDispatch();

  React.useEffect(() => {
    agentFetch('GET', '/agents/{user.id}', {
      select: ['tokens'],
    });
    gamesFetch('GET', '/games');
  }, []);

  const renderGames = () => {
    const rows = [];
    for(const game of games) {
      const gameUrl = game.type.id === gameType.WHEEL ? 'wheelOfPrizes' : (game.type.id === gameType.SCRATCH_OFF ? 'scratchOff' : 'slotMachine');
      const gameDescription = game.type.id === gameType.WHEEL ? 'Spin the wheel and always win.' : (game.type.id === gameType.SCRATCH_OFF ? 'Scratch your way to some great prizes.' : 'Take your chance at winning the biggest amounts.');
      rows.push(
        <div className="card">
          <div className="card__title">
            <NavLink className="card__link" to={url(gameUrl, {id: game.id})}>{game.name}</NavLink>
          </div>
          <div className="card__description">
            {gameDescription}
          </div>
        </div>
      );
    }
    return rows;
  };

  if(agent) {
    dispatch(updateTokens(agent.tokens));
  }

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Token Center</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Welcome',
            content: [
              {text: 'After putting in all that hard work, take a well-deserved break and indulge in some excitement as you use your tokens to win incredible cash and prizes. Get ready for an exhilarating experience with our amazing game options'},
              {text: 'Wheel of Prizes: Spin the wheel of fortune and guarantee yourself a win every time.'},
              {text: 'Slot Machine: Try your luck and seize the chance to win the grandest rewards.'},
              {text: 'Scratch Off: Uncover fantastic prizes as you scratch your way to victory.'},
              {text: 'Get ready to have a blast and reap the rewards'},
            ]
          }
        ]}/>
        {agentLoading || gamesLoading ? <div className="processing"></div> : ''}
        {agent ? 
          <div className="token-center">
            <div className="token-center__title">
              You have <span className="token-center__amount">{agent.tokens}</span> tokens.
            </div>
            {games && agent.tokens > 0 ? 
              <div className="cards">
                {renderGames()}
              </div>
              : ''
            }
          </div>
          : ''
        }
        {agentError ? <div className="error-message">{formatErrorMessage(agentError)}</div> : ''}
        {gamesError ? <div className="error-message">{formatErrorMessage(gamesError)}</div> : ''}
      </div>
    </section>
  )
}

export default Tokens;
