'use strict'

module.exports = {
  SALES : 1,
  SERVICE : 2,
  INBOUND_CALLS : 3,
  OUTBOUND_CALLS : 4,
  CHAT : 5,
  INBOUND_VIDEO_CALLS : 5,
  ids : {
    '1' : 'Sales',
    '2' : 'Service',
    '3' : 'Inbound Calls',
    '4' : 'Outbound Calls',
    '5' : 'Chat',
    '6' : 'Inbound Video Calls',
  },
};
