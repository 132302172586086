import React from 'react';
import ScratchOffComponent from '../../components/ScratchOff';

const ScratchOff = () => {
  
  const generateComponents = () => {
    const rows = [];
    for(let i = 0; i < 16; i++) {
      const secret = Math.random().toString(16).slice(2, 7).toUpperCase();
      rows.push(
        <ScratchOffComponent
          allowClickToScratch={true}
          containerStyleClassname="scratch-off-container"
          canvasStyleClassName="scratch-off-canvas"
          finishPercent={90}
        >
          <div className="secret-content">SECRET CODE: {secret}</div>
        </ScratchOffComponent>
      );
    }
    return rows;
  };

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Scratch-Off</h1>
      </div>
      <div className="error-message"></div>
      <div className="scratcher-ticket token-game">
        <div className="scratcher-ticket__border token-game__board">
          <div className="scratcher">
            {generateComponents()}
          </div>
        </div>
        <div className="token-game__legend">
        </div>
        <div className="token-game__controls">
        </div>
      </div>
    </section>
  );
}

export default ScratchOff;
