'use strict'

module.exports = {
  ids : {
    '1' : 'Conversion Per Call',
    '2' : 'Revenue Per Call',
    '3' : 'Revenue Per Minute',
    '4' : 'Round Robin',
  },
};
