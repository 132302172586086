import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFetch from './hooks/useFetch';
import './PostCall.scss';
import SelectInput from './components/SelectInput';
import HiddenInput from './components/HiddenInput';
import {formatErrorMessage} from './helper';

const PostCall = ({ component, postDispositionHandler }) => {
  const {apiFetch, data, error, loading} = useFetch();

  return (
    <div className="dispositions">
      <Formik
        validateOnChange={false}
        initialValues={{
          disposition: null,
          componentId: component.id,
        }}
        validationSchema={Yup.object({
          disposition: Yup.string()
            .required('Disposition is required')
            .oneOf(component.dispositions, 'Disposition is invalid'),
        })}
        onSubmit={async (values) => {
          await apiFetch('POST', '/vonage/disposition', values);
          postDispositionHandler();
        }}
      >
        <Form className="form dispositions-form">
          <HiddenInput
            formName="dispositions"
            name="componentId"
            type="hidden"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            required="required"
          />
          <SelectInput formName="dispositions" label="Call Disposition" name="disposition" options={component.dispositions.map(disposition => ({value: disposition, label: disposition}))}/>
          {loading ? <div className="processing"></div> : <button type="submit" className="dispositions__submit">Submit</button>}
          {error ? <div className="form__error">{formatErrorMessage(error)}</div> : ''}
        </Form>
      </Formik>
    </div>
  );
};

export default PostCall;
