import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneTest from '../../components/PhoneTest';
import Interview from '../../components/Interview';
import VerbalTest from '../../components/VerbalTest';
import TranscriptionTest from '../../components/TranscriptionTest';
import GeneralInfo from '../../components/GeneralInfo';
import { formatErrorMessage, getQueryStringParameter } from '../../helper';
import { testType } from 'agents-remotely-commons/src/model';
import { url } from 'agents-remotely-commons/src/formatter';
import { VonageDevice } from '../../vonage-device';
import useFetch from '../../hooks/useFetch';
import { useAuth } from '../../hooks/useAuth';

const AptitudeTest = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [error, setError] = useState();
  const [passedPhoneTest, setPassedPhoneTest] = useState(false);
  const [testState, setTestState] = useState(0);
  const [device, setDevice] = useState();
  const {apiFetch: testFetch, data: test, error: testError, loading: testLoading} = useFetch();
  const {apiFetch: testStatusFetch, data: testStatus, error: testStatusError, loading: testStatusLoading} = useFetch();
  const isPractice = useRef(false);

  React.useEffect(() => {
    if(getQueryStringParameter('practice') === 'true') {
      isPractice.current = true;
      testFetch('GET', '/tests/' + getQueryStringParameter('id'), {
        select: ['id', 'typeId', 'companyId', 'description', 'practiceComponent.id', 'practiceComponent.name', 'practiceComponent.companyId']
      });
    } else {
      testFetch('GET', '/tests/' + getQueryStringParameter('id'), {
        select: ['id', 'typeId', 'companyId', 'description', 'component.id', 'component.name', 'component.companyId'],
      });
    }
  }, []);

  const getTestStateContent = (test, testState) => {
    switch(testState) {
      case 1:
        return <div className="processing"></div>;
      case 2:
        if(test.type.id === testType.VERBAL) {
          return <VerbalTest test={test} practice={isPractice.current} onFinish={() => device.sendDigits('#')}/>;
        } else if(test.type.id === testType.TRANSCRIPTION) {
          return <TranscriptionTest test={test} practice={isPractice.current} />;
        } else {
          return <Interview test={test} practice={isPractice.current} onNext={() => device.sendDigits('#')}/>;
        }
      case 3:
        return <div>That completes the test. You will automatically be redirected back to the tests page in a few seconds.</div>;
      default:
        return <>
          <div className="general-section__line">When you are ready click the button below to begin your test.</div>
          <button className="language-test__start button--quaternary" onClick={() => beginTest()}>{test.type.id === testType.INTERVIEW ? 'Start Interview' : 'Start Test'}</button>
        </>;
    }
  };

  const beginTest = () => {
    setError(null);
    setTestState(1);

    const toggleCall = async (activeCall) => {
      if(activeCall) {
        testStatusFetch('PUT', '/agents/{user.id}/tests/' + test.id + '/start' + (isPractice.current ? '?practice=true' : ''));
      } else {
        testStatusFetch('PUT', '/agents/{user.id}/tests/' + test.id + '/end' + (isPractice.current ? '?practice=true' : ''), {content: document.querySelector('.language-test__text-area')?.value || ''});

        setTestState(3);

        if(device) {
          device.destroy();
        }

        navigate(url('tests'), {replace: true});
      }
    };

    const initDevice = async (device) => {
      device.onReady = () => {
        device.connect({
          componentId: device.metaInfo.component.id,
          companyId: device.metaInfo.test.company.id,
          agentId: user.userId,
        });
      };

      device.onDisconnect = () => {
        console.log('disconnect');
        toggleCall(false);
      };

      device.onCancel = () => {
        console.log('cancel');
        toggleCall(false);
        //TODO cange call below. Cancel is called when missed call both by no answer of agent or caller hangsup first
        //Consider not putting timeout on call and instead use a js timer to decline call
      };

      device.onConnect = () => {
        toggleCall(true);
        setTestState(2);
      };

      device.onError = () => {
        //handle {"code":31205,"message":"JWT Token Expired"}
        console.log('error');
        toggleCall(false);
        device.destroy();
        setError('You have been disconnected due to not being able to connect to the call properly.');
      };

      device.onAuthorization = (authorizeResponse) => {
        if(authorizeResponse.error) {
          console.log(authorizeResponse.error);
          setError('Could not authorize');
        }
      };

      device.authorize({
        closeProtection : true,
      });

      setDevice(device);
    };

    const device = new VonageDevice({
      metaInfo: {
        test: test,
        component: isPractice.current ? test.practiceComponent : test.component,
      },
    });
    initDevice(device);
  };

  console.log(device);
  return (
    <section className="content content__auth">
      <div className="title">
        <h1>{test ? test.company.name + ' ' + test.type.description + (test.type.description !== 'Interview' ? (isPractice.current ? ' Practice Aptitude Test' : ' Aptitude Test') : '') : ''}</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Directions',
            content: [
              {text: 'Before taking the language aptitude test, please ensure the following:'},
              {text: 'Make sure your internet connection is uninterrupted throughout the duration of the test.'},
              {text: 'Ensure that your device\'s battery is fully charged or connected to a power source.'},
              {text: 'Any interruptions in the internet connection or low battery levels during the test may result in an inaccurate score.'},
              {text: 'Using a headset or headphones with a microphone is highly recommended as it can greatly improve the accuracy of your results.'},
              {text: 'Please note that the test cannot be taken more than once every three months.'},
            ],
          },
          {
            text: 'Cheating',
            content: [
              {text: 'It is important to note that we utilize metrics and voice-matching technology to ensure the authenticity of your results. Additionally, we may conduct random tests to further verify the validity of your work. Any indication of cheating will result in removal from the platform without compensation.'},
            ],
          }
        ]}/>
        {testLoading ? <div className="processing"></div> : ''}
        {test ?
          <div className="language-test content__form">
            <div className="general-section__line">{test.description}</div>
            {error ? <div className="form__error">{formatErrorMessage(error)}</div> : ''}
            {testStatusError ? <div className="form__error">{testStatusError}</div> : ''}
            <PhoneTest showWrapper={testState === 0}>
              {getTestStateContent(test, testState)}
            </PhoneTest>
          </div>
          : ''
        }
        {testError ? <div className="error-message">{testError}</div> : ''}
      </div>
    </section>
  );
}

export default AptitudeTest;
