import React from "react";

const TranscriptionTest = (props) => {
  return (
    <>
      <div className="language-test__content">Enter the words in the box below as they are spoken. Keep up the best you can, if you miss a word continue on with the next one.</div>
      <textarea className="language-test__text-area" autoFocus></textarea>
    </>
  );
};

export default TranscriptionTest;
