'use strict'
const GraphQLSubscriptionClient = ({ host, authToken, onConnect, onFailure, onError, onClose, reauthorize }) => {
  let authorizationToken = authToken;
  const GRAPHQL_HTTP_URL = process.env.REACT_GRAPHQL_URL;
  const MAX_ATTEMPTS = 10;
  const genHeaders = () => {
    return {
      host: host.replace(/https:\/\//, '').replace(/\/graphql/, ''),
      Authorization: authorizationToken,
    };
  };

  const uuidv4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  };

  let connectionAttempts = 0;
  let connectionAcked = false;
  let needsAuthorized = false;
  const subscriptionComponent = [];
  const subscriptions = {};

  const startSubscription = (subscription) => {
    subscriptions[subscription.id] = subscription;
    socket.send(JSON.stringify({
      id : subscription.id,
      payload : {
        data : JSON.stringify({
          query: subscription.query,
          variables: subscription.variables,
        }),
        extensions : {
          authorization : genHeaders(),
        },
      },
      type: 'start',
    }));
  };

  const subscribe = (subscription) => {
    subscription.id = subscription.id || uuidv4();
    if(!subscriptions[subscription.id]) {
      if(connectionAcked) {
        startSubscription(subscription);
      } else {
        subscriptionComponent.push(subscription);
      }
    }
    return subscription.id;
  };

  const unsubscribe = (subscriptionId) => {
    if(connectionAcked && subscriptions[subscriptionId]) {
      socket.send(JSON.stringify({
        id : subscriptionId,
        type : 'stop',
      }));
      delete subscriptions[subscriptionId];
    } else {
      for(let i = 0; i < subscriptionComponent.length; i++) {
        if(subscriptionComponent[i].id == subscriptionId) {
          subscriptionComponent.splice(i, 1);
          break;
        }
      }
    }
  };

  let socket;

  const connect = async () => {
    if(connectionAttempts < MAX_ATTEMPTS) {
      if(needsAuthorized) { //do this here so reconnect always waits
        authorizationToken = await reauthorize();
        needsAuthorized = false;
        
        if(!authorizationToken) {
          onFailure?.('Could not authorize');
          return;
        }
      }
      const GRAPHQL_WS_URL = host.replace(/https/, 'wss').replace(/appsync-api/, 'appsync-realtime-api');
      const WEBSOCKET_ENDPOINT = GRAPHQL_WS_URL + '?header=' + btoa(JSON.stringify(genHeaders())) + '&payload=e30='; //e30= is {} in b64

      setTimeout(() => {
        socket = new WebSocket(WEBSOCKET_ENDPOINT, ['graphql-ws']);
        bindEvents(socket);
        for(const id in subscriptions) {
          subscribe(subscriptions[id]);
        }
      }, connectionAttempts * 1000);
    } else {
      onFailure?.('Connection attempts exhausted');
    }
    connectionAttempts++;
  };

  const bindEvents = (socket) => {
    socket.onopen = (event) => {
      console.log('[open] Connection established');
      socket.send(JSON.stringify({type : 'connection_init'}));
    };

    socket.onmessage = (event) => {
      console.log(`[message] Data received from server: ${event.data}`);

      const data = JSON.parse(event.data);
      switch(data.type) {
        case 'data':
          if(subscriptions[data.id]) {
            subscriptions[data.id].messageHandler(data.payload.data);
          } else {
            console.log('Bad subscription ' + data.id);
          }
          break;
        case 'connection_ack':
          onConnect?.(event);
          connectionAcked = true;
          connectionAttempts = 0;
          while(subscriptionComponent.length > 0) {
            startSubscription(subscriptionComponent.shift());
          }
          break;
        case 'connection_error':
        case 'error':
          if(data.payload.errors && data.payload.errors.length > 0 && data.payload.errors[0].errorCode === 401 && data.payload.errors[0].message === 'Token has expired.') {
            needsAuthorized = true;
          }
          onFailure?.(data.payload);
          break;
      }
    };

    socket.onclose = (event) => {
      connectionAcked = false;
      if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        onClose?.(event);
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        console.log('[close] Connection died');
        connect();
      }
    };

    socket.onerror = (error) => {
      onError?.(error);
      console.log(`[error] ${error.message}`);
    };
  };

  connect();

  return {
    close: () => socket?.close(),
    subscribe: subscribe,
    unsubscribe: unsubscribe,
  };
};

export default GraphQLSubscriptionClient;
