import React from 'react';
import SlotMachineComponent from '../../components/SlotMachine';

const SlotMachine = () => {
  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Slot</h1>
      </div>
      <SlotMachineComponent/>
    </section>
  )
}

export default SlotMachine;
