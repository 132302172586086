'use strict'

module.exports = {
  SAY : 1,
  PLAY : 2,
  RECORD : 3,
  GATHER : 4,
  HANGUP : 5,
  DIAL : 6,
  REDIRECT : 7,
  SINGLE_PARTY_QUEUE : 8,
  TWO_PARTY_QUEUE : 9,
  CONFERENCE_QUEUE : 10,
  PAUSE : 11,
  SMS_QUEUE : 12,
  MMS_QUEUE : 13,
  EMAIL_QUEUE : 14,
  CONFERENCE_DIAL : 15,
  PARTY_SMS_QUEUE : 16,
  PARTY_MMS_QUEUE : 17,
  ids : {
    '1' : 'Say',
    '2' : 'Play',
    '3' : 'Record',
    '4' : 'Gather',
    '5' : 'Hangup',
    '6' : 'Dial',
    '7' : 'Redirect',
    '8' : 'Single Party Queue',
    '9' : 'Two Party Queue',
    '10' : 'Conference Queue',
    '11' : 'Pause',
    '12' : 'SMS Queue',
    '13' : 'MMS Queue',
    '14' : 'Email Queue',
    '15' : 'Conference Dial',
    '16' : 'Party SMS Queue',
    '17' : 'Party MMS Queue',
  },
};
