const states = {
  CONNECTING: 'Connecting',
  READY: 'Ready',
  INCOMING: 'Incoming',
  ON_CALL: 'On call',
  POST_CALL: 'Post Call',
  OFFLINE: 'Offline'
};

export default states;
