import React, {useState} from 'react';
import FileUploadInput from './FileUploadInput';
import SelectInput from './SelectInput';
import TextInput from './TextInput';
import Tooltip from './Tooltip';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { industry, jobAttribute } from 'agents-remotely-commons/src/model';
import useFetch from '../hooks/useFetch';
import {formatErrorMessage} from '../helper';
import { url } from 'agents-remotely-commons/src/formatter';
import { useNavigate } from 'react-router-dom';

const WorkSettingsForm = (props) => {
  const [resumeUrl, setResumeUrl] = useState({name: props.agent.resumeUrl || ''});
  const {apiFetch, data: user, error, loading} = useFetch();
  const {apiFetch: uploadResumeFetch, data: uploadedResume, error: uploadedResumeError, loading: uploadedResumeLoading} = useFetch();
  const [industriesState, setIndustriesState] = useState(props.agent.industries || {});
  const [jobAttributesState, setJobAttributesState] = useState(props.agent.jobAttributes || {});
  const navigate = useNavigate();

  React.useEffect(() => {
    if(user) {
      navigate(new URLSearchParams(window.location.search).get('redirect') || url('dashboard'));
    }
  }, [user]);

  const updateIndustry = (e, id) => {
    const isChecked = e.currentTarget.checked;
    setIndustriesState(previousState => {
      if(isChecked) {
        return {
          ...previousState,
          [id]: '0',
        }
      } else {
        delete previousState[id];
        return {
          ...previousState,
        };
      }
    });
  };

  const updateIndustryValue = (value, id) => {
    setIndustriesState(previousState => {
      return {
        ...previousState,
        [id]: value,
      };
    });
  };

  const updateJobAttribute = (e, id) => {
    const isChecked = e.currentTarget.checked;
    setJobAttributesState(previousState => {
      if(isChecked) {
        return {
          ...previousState,
          [id]: '0'
        }
      } else {
        delete previousState[id];
        return {
          ...previousState,
        };
      }
    });
  };

  const updateJobAttributeValue = (value, id) => {
    setJobAttributesState(previousState => {
      return {
        ...previousState,
        [id]: value,
      };
    });
  };

  const industries = [];
  const sortedIndustries = Object.entries(industry.ids).sort(([,a],[,b]) => a > b).map(arr => arr[0]).flat();
  for(const key of sortedIndustries) {
    industries.push(
      <div className="form__line interest-item" key={key}>
        <label className="interest-item__label"><input type="checkbox" onChange={(e) => {updateIndustry(e, key)}} defaultChecked={key in industriesState}/>{industry.ids[key]}</label>
        <TextInput disabled={key in industriesState ? false : true} formName="work-settings" className="work-settings__industries" name={"industries['" + key + "']"} onChange={(e) => updateIndustryValue(e, key)} value={key in industriesState ? industriesState[key] : ''} type='number' min='0' max='50' step='1' required='required'/> 
      </div>
    );
  }
  const jobAttributes = [];
  const sortedJobAttributes = Object.entries(jobAttribute.ids).sort(([,a],[,b]) => a > b).map(arr => arr[0]).flat();
  for(const key of sortedJobAttributes) {
    jobAttributes.push(
      <div className="form__line interest-item" key={key}>
        <label><input type="checkbox" onChange={(e) => {updateJobAttribute(e, key)}} defaultChecked={key in jobAttributesState}/>{jobAttribute.ids[key]}</label>
        <TextInput disabled={key in jobAttributesState ? false : true} formName="work-settings" className="work-settings__job-attributes" name={"jobAttributes['" + key + "']"} onChange={(e) => updateJobAttributeValue(e, key)} value={key in jobAttributesState ? jobAttributesState[key] : ''} type='number' min='0' max='50' step='1' required='required'/>
      </div>
    );
  }
  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        industries: {
          ...Object.keys(industry.ids).reduce((a, v) => ({ ...a, [v]: ''}), {}),
          ...props.agent.industries,
        },
        jobAttributes: {
          ...Object.keys(jobAttribute.ids).reduce((a, v) => ({ ...a, [v]: ''}), {}),
          ...props.agent.jobAttributes,
        },
        resumeUrl: resumeUrl.name || '',
      }}
      validationSchema={Yup.object({
        resumeUrl: Yup.string()
          .required('Resume is required'),
      })}
      onSubmit={(values) => {
        apiFetch('PUT', '/agents/{user.id}', values);
      }}
    >
      {({ setFieldValue , isSubmitting }) => (
        <Form className="form work-settings">
          {error ? <div className="form__error">{formatErrorMessage(error)}</div> : ''}
          <div className="form__sub-heading">
            Resume/Curriculum Vitae
          </div>
          <div className="form__sub-section">
            <div className="form__line">
              <FileUploadInput formName="work-settings" label="Upload Resume/CV" uploadName="resume" name="resumeUrl" uploadUrl={resumeUrl} setUploadUrl={setResumeUrl} accept=".pdf,.doc,.docx" setFieldValue={setFieldValue}/>
            </div>
          </div>
          <div className="form__sub-heading">
            Industries <Tooltip text="Put years of experience as 0 if you are interested."/>
          </div>
          <div className="form__sub-section">
            {industries}
          </div>
          <div className="form__sub-heading">
            Contact Center <Tooltip text="Put years of experience as 0 if you are interested."/>
          </div>
          <div className="form__sub-section">
            {jobAttributes}
          </div>
          <div className="form__line">
            {loading ? <div className="processing"></div>  : <button className="form__submit" type="submit" onClick={() => {setFieldValue('industries', industriesState); setFieldValue('jobAttributes', jobAttributesState)}}>Update Settings</button>}
          </div>
        </Form>
      )}
  </Formik>
  );
};

export default WorkSettingsForm;
