import React, { useState } from 'react';

const InfoWindow = ({content, close}) => {
  return (
    <div className="info-window" onClick={close}>
      <div className="info-window__background"></div>
      <div className="info-window__content" onClick={(e) => {e.stopPropagation();}}>
        <div className="info-window__body">{content}</div>
        <span className="info-window__close" onClick={close}>X</span>
      </div>
    </div>
  );
}

export default InfoWindow;
