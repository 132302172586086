import React, {useState} from 'react';
import FileUploadInput from './FileUploadInput';
import SelectInput from './SelectInput';
import TextInput from './TextInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { calendar, country, gender, stateProvince } from 'agents-remotely-commons/src/model';
import useFetch from '../hooks/useFetch';
import {formatErrorMessage} from '../helper';
import { url } from 'agents-remotely-commons/src/formatter';
import { useNavigate } from 'react-router-dom';

const ProfileForm = (props) => {
  const [photoUrl, setPhotoUrl] = useState({name: props.agent.photoUrl || ''});
  const {apiFetch: updateProfileFetch, data: agent, error: updateProfileError, loading: updateProfileLoading} = useFetch();
  const {apiFetch: downloadPhotoFetch, data: downloadedPhoto, error: downloadedPhotoError, loading: downloadedPhotoLoading} = useFetch();
  const {apiFetch: uploadPhotoFetch, data: uploadedPhoto, error: uploadedPhotoError, loading: uploadedPhotoLoading} = useFetch();
  const navigate = useNavigate();

  if(agent) {
    navigate(new URLSearchParams(window.location.search).get('redirect') || url('dashboard'));
  }

  React.useEffect(() => {
    if(photoUrl.name) {
      downloadPhotoFetch('GET', '/agents/{user.id}/files/' + photoUrl.name, null, null, getMimeType(photoUrl.name.split('.').pop()));
    }
  }, [photoUrl]);

  const getMimeType = (extension) => {
    switch(extension) {
      case 'png':
        return 'image/png';
      case 'jpg':
        return 'image/jpeg';
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'pdf':
        return 'application/pdf';
      default:
        return 'plain/text';
    }
  };
  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b) => {
      binary += String.fromCharCode(b)
    });

    return window.btoa(binary);
  };

  const stateProvinces = [{value: ''}];
  for(const key in stateProvince.ids) {
    stateProvinces.push({value: parseInt(key), label: stateProvince.ids[key].name});
  }
  const countries = [{value: ''}];
  for(const key in country.ids) {
    countries.push({value: parseInt(key), label: country.ids[key].name});
  }
  const genders = [{value: ''}];
  for(const key in gender.ids) {
    genders.push({value: parseInt(key), label: gender.ids[key]});
  }
  const years = [{value: ''}];
  for(const key in calendar.year.ids) {
    years.push({value: parseInt(key), label: calendar.year.ids[key]});
  }
  years.reverse();
  const months = [{value: ''}];
  for(const key in calendar.month.ids) {
    months.push({value: parseInt(key), label: calendar.month.ids[key]});
  }
  const days = [{value: ''}];
  for(const key in calendar.day.ids) {
    days.push({value: parseInt(key), label: calendar.day.ids[key]});
  }
  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        firstName: props.agent.firstName || '',
        middleName: props.agent.middleName || '',
        lastName: props.agent.lastName || '',
        street1: props.agent.street1 || '',
        street2: props.agent.street2 || '',
        city: props.agent.city || '',
        stateProvinceId: props.agent.stateProvinceId || '',
        countryId: props.agent.countryId || '',
        postalCode: props.agent.postalCode || '',
        genderId: props.agent.genderId || '',
        birthdayYear: props.agent.birthday?.year || '',
        birthdayMonth: props.agent.birthday?.month || '',
        birthdayDay: props.agent.birthday?.day || '',
        photoUrl: photoUrl.name || '',
      }}
      validationSchema={Yup.object({
        photoUrl: Yup.string()
          .required('Photo is required'),
        firstName: Yup.string()
          .required('First name is required')
          .matches(/^([A-z0-9À-ž '-]){2,}$/, 'First name is invalid'),
        middleName: Yup.string()
          .matches(/^([A-z0-9À-ž '-]){2,}$/, 'Middle name is invalid'),
        lastName: Yup.string()
          .required('Last name is required')
          .matches(/^([A-z0-9À-ž '-]){2,}$/, 'Last name is invalid'),
        street1: Yup.string()
          .required('Address line 1 is required'),
        street2: Yup.string(),
        city: Yup.string()
          .required('City is required'),
        zip: Yup.string(),
        stateProvinceId: Yup.number().integer()
          .oneOf([...(Object.keys(stateProvince.ids).map(item => parseInt(item))), null], 'State is invalid'),
        countryId: Yup.number().integer()
          .required('Country is required')
          .oneOf(Object.keys(country.ids).map(item => parseInt(item)), 'Country is invalid'),
        genderId: Yup.number().integer()
          .required('Gender is required')
          .oneOf(Object.keys(gender.ids).map(item => parseInt(item)), 'Gender is invalid'),
        birthdayYear: Yup.number().integer()
          .required('Birth year is required')
          .oneOf(Object.keys(calendar.year.ids).map(item => parseInt(item)), 'Birth year is invalid'),
        birthdayMonth: Yup.number().integer()
          .required('Birth month is required')
          .oneOf(Object.keys(calendar.month.ids).map(item => parseInt(item)), 'Birth month is invalid'),
        birthdayDay: Yup.number().integer()
          .required('Birth day is required')
          .oneOf(Object.keys(calendar.day.ids).map(item => parseInt(item)), 'Birth day is invalid'),
      })}
      initialTouched={{
        photoUrl: photoUrl.name ? true : false,
      }}
      onSubmit={(values) => {
        updateProfileFetch('PUT', '/agents/{user.id}', values);
      }}
    >
      {({ isValid, submitCount, setFieldValue , isSubmitting }) => (
      <Form className="form personal-settings">
        {!isValid && submitCount > 0 ? <div className="form__error">Please correct the errors below before submitting</div> : ''}
        {updateProfileError ? <div className="form__error">{formatErrorMessage(updateProfileError)}</div> : ''}
        <img className="profile__img" src={downloadedPhoto ? 'data:image/jpeg;base64,' + arrayBufferToBase64(downloadedPhoto) : '/images/defaultprofile.png'} alt="profile pic"/>
        <div className="form__line">
          <FileUploadInput formName="personal-settings" label="Upload Photo" uploadName="photo" name="photoUrl" uploadUrl={photoUrl} setUploadUrl={setPhotoUrl} accept=".jpg,.jpeg,.png" setFieldValue={setFieldValue}/>
        </div>
        <div className="form__line">
          <TextInput
            formName="personal-settings"
            label="First Name"
            name="firstName"
            type="text"
            autoComplete="given-name"
            pattern="^([A-z0-9À-ž '-]){2,}$"
            autoCorrect="off"
            spellCheck="false"
            required="required"
          />
        </div>
        <div className="form__line">
          <TextInput
            formName="personal-settings"
            label="Middle Name"
            name="middleName"
            type="text"
            autoComplete="family-name"
            pattern="^([A-z0-9À-ž '-]){2,}$"
            autoCorrect="off"
            spellCheck="false"
          />
        </div>
        <div className="form__line">
          <TextInput
            formName="personal-settings"
            label="Last Name"
            name="lastName"
            type="text"
            autoComplete="family-name"
            pattern="^([A-z0-9À-ž '-]){2,}$"
            autoCorrect="off"
            spellCheck="false"
            required="required"
          />
        </div>
        <div className="form__line">
          <TextInput
            formName="personal-settings"
            label="Address Line 1"
            name="street1"
            type="text"
            autoComplete="address-line-1"
            autoCorrect="off"
            spellCheck="false"
            required="required"
          />
        </div>
        <div className="form__line">
          <TextInput
            formName="personal-settings"
            label="Address Line 2"
            name="street2"
            type="text"
            autoComplete="address-line-2"
            autoCorrect="off"
            spellCheck="false"
          />
        </div>
        <div className="form__line">
          <TextInput
            formName="personal-settings"
            label="City"
            name="city"
            type="text"
            autoComplete="address-level2"
            required="required"
          />
        </div>
        <div className="form__line">
          <SelectInput formName="personal-settings" label="State/Province" name="stateProvinceId" autoComplete="address-level1" options={stateProvinces}/>
        </div>
        <div className="form__line">
          <SelectInput formName="personal-settings" label="Country" name="countryId" autoComplete="country-name" options={countries}/>
        </div>
        <div className="form__line">
          <TextInput
            formName="personal-settings"
            label="Postal Code"
            name="postalCode"
            type="text"
            autoComplete="postal-code"
          />
        </div>
        <div className="form__line">
          <SelectInput formName="personal-settings" label="Gender" name="genderId" autoComplete="sex" required="required" options={genders}/>
        </div>
        <div className="form__line">
          <label> Birthday</label>
          <div className="form__line--flex">
            <div className="personal-settings__birthday">
              <SelectInput formName="personal-settings" name="birthdayYear" autoComplete="bday-year" required="required" options={years}/>
            </div>
            <div className="personal-settings__birthday">
              <SelectInput formName="personal-settings" name="birthdayMonth" autoComplete="bday-month" required="required" options={months}/>
            </div>
            <div className="personal-settings__birthday">
              <SelectInput formName="personal-settings" name="birthdayDay" autoComplete="bday-day" required="required" options={days}/>
            </div>
          </div>
        </div>
        <div className="form__line">
          {!isValid && submitCount > 0 ? <div className="form__error">Please correct the errors above before submitting</div> : ''}
          {updateProfileError ? <div className="form__error">{formatErrorMessage(updateProfileError)}</div> : ''}
          {updateProfileLoading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Update Profile</button>}
        </div>
      </Form>
      )}
  </Formik>
  );
};

export default ProfileForm;
