'use strict'

module.exports = {
  ROLE: 1,
  GOAL: 2,
  TEST: 3,
  MONEY: 4,
  TOKENS: 5,
  BADGE: 6,
  UNSETTLED_MONEY: 7,
  UNSETTLED_TOKENS: 8,
  ids: {
    '1': 'Role',
    '2': 'Goal',
    '3': 'Test',
    '4': 'Money',
    '5': 'Tokens',
    '6': 'Badge',
    '7': 'Unsettled Money',
    '8': 'Unsettled Tokens',
  },
};
