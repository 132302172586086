import React from 'react';

const Terms = () => {
  return (
    <section className="content content__no-auth">
      <div className="title">
        <h1>Terms & Services</h1>
      </div>
      <div className="terms">
        <div>The following describes Agents Remotely's requirements for using our software, services, websites, and mobile services:</div>
        <div>
          <div>You agree to provide accurate information</div>
          <div>You agree to use our services in an ethical, responsible, and lawful manner</div>
          <div>You are responsible for your password and user ID, and you need to keep track of who is using your account</div>
          <div>Agents Remotely respects intellectual property rights, requires our users to do the same, and has a mechanism in place to respond to the concerns of copyright holders</div>
          <div>Please read the complete agreement that follows:</div>
        </div>
        <div>
          <div>Acceptance</div>
          <div>Welcome to Agents Remotely Technologies Inc's website. These Terms of Service govern your use of agentsremotely.com, other properties in the agentsremotely.com domain and co-branded websites (collectively, the "Site"), any Agents Remotely software, including co-branded versions, downloaded from this Site or obtained elsewhere (the "Software"), and the services made available on the Site or through use of the Software (the "Services"). By using the Services or Software or visiting or browsing the Site, you acknowledge that you have read, understood, and agreed to be bound by these Terms of Service and any modifications that may be made to the Terms of Service from time to time. In addition, to the extent our Services require you to set up a family account with a shared password, if you are the person who first registers for a Service, you agree to be responsible for ensuring that you comply with these Terms of Service and any modifications that may be made to the Terms of Service from time to time. If you do not agree to these Terms of Service, you should not use the Services or Software or visit or browse the Site. These Terms of Service constitute a binding legal agreement between you and Agents Remotely Technologies Inc. ("Agents Remotely," "we," "us," and "our"). Please read them carefully before accessing or using the Site, the Software, or the Services.</div>
        </div>
        <div>
          <div>Modifications</div>
          <div>We reserve the right to modify these Terms of Service at any time and in any manner at our sole discretion, including the right to charge for the Software or Services. Notice of any modification of these Terms of Service will be posted in this section of the Site, and any such modifications will be effective upon the posting of such notice. Your continued use of the Site or the Services constitutes your binding acceptance of such modifications. Please check this section of the Site before using the Site, the Services, or the Software to determine whether a change has been made to these Terms of Service. If you do not agree to any changes in the Terms of Service as they may occur, please arrange to terminate your registration with the Site immediately and discontinue your use of the Service, Software, and the Site. You agree that we are not liable to you or to any third party for any modification of the Terms of Service.</div>
        </div>
        <div>
          <div>Registration</div>
          <div>By registering for any Service or maintaining an account, you agree that you are at least 18 years old. You agree to provide true, accurate, current, and complete information about yourself as prompted by any registration form for the Site, Services, or the Software (such information being the "Registration Data"). You agree to maintain and promptly update the Registration Data to keep it true, accurate, current, and complete. If you provide any information that is untrue, inaccurate, not current, or incomplete, or we have any reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, we may suspend or terminate your account and refuse to offer you any and all current or future use of the Services, the Site, or the Software. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You are solely responsible for any activity related to your account. If you suspect any unauthorized use of your account, notify us immediately. You acknowledge and agree that we may preserve user information and may also disclose user information if required to do so by law or if we believe in good faith that such preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce these Terms of Service; (c) respond to claims that any Content (as defined below in Section 4) violates the rights of third parties; or (d) protect the rights, property, or personal safety of Agents Remotely, its users, or the public.</div>
        </div>
        <div>
          <div>You may become a registered user of the Site, Software, and Services at no cost. As a registered user, you will have the ability to participate in some, but perhaps not all, of the features and functionality available at the Site or as part of the Software and Services.</div>
        </div>
        <div>
          <div>Data Protection</div>
          <div>Agents Remotely Technologies Inc and Agents Remotely are subject to Canadian data protection law.</div>
          <div>Data related to Agents Remotely including Personal Data (hereinafter "Data") is on the User's Smartphone and in the Amazon Cloud of Amazon Web Services, Inc. and other Amazon companies (hereafter "Amazon") in the United States of America (USA) saved and edited (hereinafter "Amazon Cloud"), as well as Google's Firebase Firestore database. The transmission of the corresponding data is encrypted. Details of the Amazon Cloud can be found in the relevant privacy policy of Amazon. Details of the Firebase can be found in the relevant privacy policy of Firebase. The user expressly agrees with the data processing in the USA.</div>
        </div>
        <div>
          <div>In the Amazon Cloud and on Firebase, the following data in particular can be stored and edited in connection with Agents Remotely: bank information, information about the user account such as username, e-mail address, profile photo, country, language, user ID and - in encrypted form - password; Information on the smartphone used and the Internet connection used, such as device ID ("device token") and IP addresses; User Settings; Information about invitations to other Agents Remotely users, email notifications, and other messages such as sender and recipient email addresses, and the status of messages; Information about the current location of the smartphone used, such as current coordinates.</div>
        </div>
        <div>
          <div>For continuous improvement, Agents Remotely analyzes the use of Agents Remotely with Google Analytics, an offer by Google, Inc. (hereafter "Google") in the United States, as well as Firebase for stability reports, and Amplitude for usage analysis and download attribution. The analysis is done anonymously and aggregated, which means it is done by Agents Remotely with no Assignment to individual users. The transmission of the corresponding data, including personal data, including IP addresses, is encrypted. Details of each service can be found in their privacy policies. The user expressly agrees to the data processing in the USA.</div>
        </div>
        <div>
          <div>Amazon, Google, Firebase, and Amplitude have all committed themselves under EU standard contractual clauses to ensure an adequate level of data protection, which is comparable to EU data protection law.</div>
        </div>
        <div>
          <div>Content</div>
          <div>The Site, the Software, and the Services may allow you and other third parties to post data, text, code, help, opinions, advice, statements, reviews, comments, photographs, and other materials and information that will be accessible by visitors to and members of the Site ("Public Content"). The Site and Services may also allow you to post data, text, code, help, opinions, advice, statements, reviews, comments, photographs, and other materials and information that will be accessible by visitors to and members of the Site ("User Content"). Public Content and User Content are collectively referred to herein as "Content."</div>
          <div>You retain all rights in, and are solely responsible for, the Content you post to the Site, the Software, and the Services. You agree, represent, and warrant that any Content you post is original to you and that you exclusively own the rights to such Content, including the right to grant all of the rights and licenses in these Terms of Service without our obtaining any third-party permissions or licenses, and without the need for payment to you or any other person or entity.</div>
        </div>
        <div>
          <div>You also represent and warrant that the Posting of your Content on or through the Site, the Software, and the Services does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. You agree to pay for all royalties, fees, and any other monies owing any person or entity by reason of any Content posted by you to or through the Site, the Software, or the Services.</div>
        </div>
        <div>
          <div>Acceptable Use</div>
          <div>You agree not to use the Site, the Software, or the Services to take any action or actions that:</div>
          <div>Are contrary to Agents Remotely's public image, goodwill or reputation;</div>
          <div>Infringe on our or any third party's copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy;</div>
          <div>Express or imply that any of your statements, activities or causes are endorsed by us, without our prior written consent in each instance;</div>
          <div>Violate any applicable law, statute, ordinance or regulation, or encourage any conduct that could constitute a criminal offense or give rise to civil liability;</div>
          <div>Libel, defame, disparage, threaten, harass, or intimidate anyone;</div>
          <div>Are undertaken by automated means (such as scripts, bots, crawlers or scrapers) without our express written consent;</div>
          <div>Transmit any viruses, worms, defects, Trojan horses, or other items of a destructive nature;</div>
          <div>Modify, adapt, sublicense, translate, sell, reverse engineer, decompile or disassemble any portion of the Site, Software, or Services;</div>
          <div>Remove any copyright, trademark, or other proprietary rights notices contained in or on the Site, Software, or Services;</div>
          <div>Reformat or frame any portion of the web pages that are part of the Site's or Software's administration display;</div>
          <div>Use the Site, Software, or Services to post any false or misleading information, or do anything that is fraudulent or involves the sale of counterfeit or stolen items;</div>
          <div>Use the Site, Software, or Services to violate the security of any computer network, crack passwords or security encryption codes, transfer or store illegal material including material that are deemed threatening or obscene, or engage in any kind of illegal activity;</div>
          <div>Undertake any activity that violates Agents Remotely's then-current Privacy Policy, available for viewing on the Site, as may be revised from time to time;</div>
          <div>Collect or store personal data about anyone;</div>
          <div>Violate the privacy rights, publicity rights, or other rights of any third party;</div>
          <div>Exploit minors in any way;</div>
          <div>Violate any representation or warranty that we provide you in these Terms of Service.</div>
          <div>You are responsible for all of your activity in connection with the Site, the Software, and the Services. You shall not, and shall not permit any third party using your account to, take any action, or submit content, that:</div>
        </div>
        <div>
          <div>Infringes any patent, trademark, trade secret, copyright, right of publicity, or other right of any other person or entity, or violates any law or contract;</div>
          <div>You know is false, misleading, or inaccurate;</div>
          <div>Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, tortious, obscene, offensive, profane, or invasive of another's privacy;</div>
          <div>Constitutes unsolicited or unauthorized advertising or promotional material or any junk mail, spam, or chain letters;</div>
          <div>Contains software viruses or any other computer codes, files, or programs that are designed or intended to disrupt, damage, limit, or interfere with the proper function of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any system, data, password, or other information of ours or of any third party;</div>
          <div>Is made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence;</div>
          <div>Impersonates any person or entity, including any of our employees or representatives; or</div>
          <div>Advocates, promotes, or assists any unlawful act such as (by way of example only) copyright infringement or computer misuse.</div>
          <div>Agents Remotely Software and Services</div>
          <div>Subject to your compliance with these Terms of Service, Agents Remotely hereby grants you a limited, personal, non-exclusive, non-transferable, freely revocable license to use the Software and Services as we intend for them to be used. These rights are subject to your use of the Software and Services in compliance with these Terms of Service.</div>
          <div>The Software and Services, including, but not limited to, all intellectual property rights in the Software and Services (including any derivative works of the Software or Services), are owned by Agents Remotely and its licensors. You agree not to reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Site, Software, or Services, except as it is expressly authorized by us in writing in advance.</div>
          </div>
        <div>
          <div>You are prohibited from using the Site, the Software, or the Services to post or send any unlawful, infringing, threatening, defamatory, libelous, obscene, pornographic, or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense or give rise to civil liability, or otherwise violate any law. In addition to any remedies that we may have at law or in equity, if we determine, in our sole discretion, that you have violated or are likely to violate the foregoing prohibitions, we may take any action we deem necessary to cure or prevent the violation, including without limitation, the immediate removal of the related materials from this Site. We will fully cooperate with any law enforcement authorities or court order or subpoena requesting or directing us to disclose the identity of anyone posting such materials.</div>
        </div>
        <div>
          <div>DISCLAIMER OF WARRANTIES</div>
          <div>YOUR USE OF THE SITE, THE SOFTWARE, AND THE SERVICES IS AT YOUR OWN RISK. THE SITE, THE SOFTWARE, AND THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE," AND AGENTS REMOTELY, ITS AFFILIATES, LICENSORS, EMPLOYEES, AGENTS AND SUPPLIERS HEREBY DISCLAIM ANY AND ALL REPRESENTATIONS, WARRANTIES, AND GUARANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, OR NON-INFRINGEMENT. NEITHER AGENTS REMOTELY, NOR ANY OF ITS AFFILIATES, LICENSORS, EMPLOYEES, AGENTS, OR SUPPLIERS WARRANT THAT THE SITE, THE SOFTWARE, OR THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SITE, THE SOFTWARE, OR THE SERVICES, OR AS TO THE ACCURACY, RELIABILITY, OR CONTENT OF ANY INFORMATION, SERVICE, OR MERCHANDISE PROVIDED THROUGH THE SITE, THE SOFTWARE, OR THE SERVICES.</div>
        </div>
        <div>
          <div>LIMITATION OF LIABILITY</div>
          <div>IN NO EVENT SHALL AGENTS REMOTELY, ITS AFFILIATES, LICENSORS, EMPLOYEES, AGENTS, SUPPLIERS, OR ANY THIRD PARTIES MENTIONED ON THE SITE, SOFTWARE, OR SERVICES BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, PERSONAL INJURY/WRONGFUL DEATH, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OF OR INABILITY TO USE THE SITE, SOFTWARE, OR SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT AGENTS REMOTELY, ITS AFFILIATES, LICENSORS, EMPLOYEES, AGENTS, SUPPLIERS, OR ANY THIRD PARTIES MENTIONED ON THE SITE, SOFTWARE, OR SERVICES ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. AGENTS REMOTELY, ITS AFFILIATES, LICENSORS, EMPLOYEES, AGENTS, SUPPLIERS, OR ANY THIRD PARTIES MENTIONED ON THE SITE, SOFTWARE, OR SERVICES SHALL BE LIABLE ONLY TO THE EXTENT OF ACTUAL DAMAGES INCURRED BY YOU, NOT TO EXCEED U.S. $1000. AGENTS REMOTELY, ITS AFFILIATES, LICENSORS, EMPLOYEES, AGENTS, SUPPLIERS, OR ANY THIRD PARTIES MENTIONED ON THE SITE, SOFTWARE, OR SERVICES ARE NOT LIABLE FOR ANY PERSONAL INJURY, INCLUDING DEATH, CAUSED BY YOUR USE OR MISUSE OF THE SITE, SOFTWARE, OR SERVICES.</div>
        </div>
        <div>
          <div>Indemnification</div>
          <div>You hereby agree to indemnify, defend, and hold harmless Agents Remotely, its successors, assigns, affiliates, agents, directors, officers, employees, and shareholders from and against any and all claims, obligations, damages, losses, expenses, and costs, including reasonable attorneys' fees, resulting from:</div>
        </div>
        <div>
          <div>Your use of the Site, the Software, and the Services;</div>
          <div>Content you submit, post, transmit, or make available through the Site, the Software, and the Services;</div>
          <div>Your violation of these Terms of Service;</div>
          <div>Your violation of any rights of another;</div>
          <div>Your violation of any applicable law; or</div>
          <div>Your willful misconduct.</div>
          <div>Privacy Policy</div>
          <div>Your privacy is important to Agents Remotely. All information submitted by you in connection with your use of the Site, the Software, or the Services is subject to our Privacy Policy, which is available at [Privacy Policy Link]. By using the Site, the Software, and the Services, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</div>
        </div>
        <div>
          <div>Termination</div>
          <div>Agents Remotely reserves the right to suspend or terminate these Terms of Service, the Site, the Software, or the Services, in whole or in part, at any time and from time to time, with or without notice to you, for any reason or no reason at all, including but not limited to your violation of these Terms of Service or any unlawful or inappropriate use of the Site, the Software, or the Services. If you wish to terminate your account, you may simply discontinue using the Site, the Software, and the Services. All provisions of these Terms of Service that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</div>
        </div>
        <div>
          <div>Changes to Terms of Service</div>
          <div>Agents Remotely reserves the right, at its sole discretion, to modify or replace these Terms of Service at any time. If a revision is material, we will provide at least [30 days'] notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use the Site, the Software, or the Services after those revisions become effective, you agree to be bound by the revised terms. If you disagree with the new terms, please stop using the Site, the Software, and the Services.</div>
        </div>
        <div>
          <div>Governing Law</div>
          <div>These Terms of Service and your use of the Site, the Software, and the Services shall be governed by and construed in accordance with the laws of [Jurisdiction], without resorting to its conflict of law provisions. You and Agents Remotely agree to submit to the personal and exclusive jurisdiction of the courts located within [Jurisdiction].</div>
        </div>
        <div>
          <div>Entire Agreement</div>
          <div>These Terms of Service, including the Privacy Policy and any other policies that may be posted on the Site from time to time, constitute the entire agreement between you and Agents Remotely with respect to the subject matter hereof and supersedes any other agreements, terms, and conditions applicable to the subject matter hereof. These Terms of Service may only be modified by a written amendment signed by an authorized executive of Agents Remotely or by the unilateral amendment of these Terms of Service by Agents Remotely and by the posting by Agents Remotely of such amended version.</div>
        </div>
        <div>
          <div>Miscellaneous</div>
          <div>The failure of Agents Remotely to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision. If any provision of these Terms of Service is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of these Terms of Service shall remain in full force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to the use of the Site, the Software, or the Services or these Terms of Service must be filed within one (1) year after such claim or cause of action arose or be forever barred. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</div>
        </div>
        <div>
          [End of Terms of Service]
        </div>
      </div>
    </section>

  );
}

export default Terms;
