import React, { useState } from 'react';
import useFetch from '../../hooks/useFetch';
import {formatErrorMessage} from '../../helper';
import { url } from 'agents-remotely-commons/src/formatter';
import { AgGridReact } from 'ag-grid-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Dates from '../../components/Dates';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const CallReport = () => {
  const now = new Date();
  const {apiFetch, data, error, loading} = useFetch();
  const [ offset, setOffset ] = useState(0);
  const [ pageSize, setPageSize ] = useState(100);
  const [startDate, setStartDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999));

  const submit = () => {
    apiFetch('GET', '/reports/call', {
      startedAt: startDate.getTime(),
      endedAt: endDate.getTime(),
      offset: offset,
      limit: pageSize,
    });
  };

  React.useEffect(() => {
    submit();
  }, []);

  const getRowData = () => {
    const rows = [];
    for(const item of data) {
      rows.push({
        id: item.id,
        createdAt: new Date(item.createdAt),
        talkTime: item.talkTime,
      });
    }
    return rows;
  };

  const getColumnData = () => {
    return [
      {field: 'id', cellRenderer: (params) => <a href={url('callDetailReport', {callId: params.value})}>{params.value}</a>},
      {field: 'createdAt'},
      {field: 'talkTime'},
    ];
  }

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };
  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Calls</h1>
      </div>
      <div className="content__main content__main--padded">
        <Formik
          validateOnChange={false}
          initialValues={{
            startDate: startDate,
            endDate: endDate,
            date: null,
          }}
          validationSchema={Yup.object({
          })}
          initialTouched={{
          }}
          onSubmit={(values) => {
          }}
        >
          {({ setFieldValue , isSubmitting }) => (
          <Form className="form report-form">
            <Dates startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
            <div className="report-header__line">
              <button className="button" onClick={submit}>Submit</button>
            </div>
          </Form>
          )}
        </Formik>
        {loading ? <div className="processing"></div> : ''}
        {data ? 
          (data.length > 0 ? 
          <>
             <div className="report-data-grid ag-theme-alpine">
               <AgGridReact
                 rowData={getRowData()}
                 columnDefs={getColumnData()}
                 onFirstDataRendered={onFirstDataRendered}
                 onGridSizeChanged={onGridSizeChanged}>
               </AgGridReact>
            </div>
          </>
            : <div className="info-message">No results</div>)
          : (!error && !loading ? <div className="info-message">Submit dates to see results</div> : '')
        }
        {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
      </div>
    </section>
  )
}

export default CallReport;
