import React from 'react';
import useKeyPress from '../hooks/useKeyPress';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { url } from 'agents-remotely-commons/src/formatter';

const Character = React.memo(({ chr, id, chrsTyped, seconds }) => {
  const classes = {};//useStyles()
  let className = '';

  if(id === chrsTyped.length) {
    className = Math.round(seconds * 10) % 10 < 5 ? 'typing-speed__current-char' : 'typing-speed__current-char--off';//NextCharTypography
  } else if(id >= chrsTyped.length) {
    className = '';//Typography
  } else if(chrsTyped[id] === chr) {
    className = 'typing-speed__good-char';//CorrectTextTypography
  } else {
    className = 'typing-speed__bad-char';//IncorrectTextTypography
  }

  return (<span className={className}>
    {chr}
  </span>)
}, (props, nextProps) => {
  /** Significant pref boost avoiding render 
   * We only needs re-render a sliding window of 3 characters 
   * from where user needs to type
  */
  if(props.id == nextProps.chrsTyped.length - 1 ||
    (props.id == nextProps.chrsTyped.length) ||
    (props.id == nextProps.chrsTyped.length + 1)) {
    // Return false to re-render
    return false;
  } else {
    // Return true to avoid re-render
    return true
  }
})

const TypingTestForm = ({test, practice}) => {
  /** States: */
  const navigate = useNavigate();
  const [chrsTyped, setChrsTyped] = React.useState([]);
  const [charsToType, setCharsToType] = React.useState([]);
  const [seconds, setSeconds] = React.useState(0);
  const {apiFetch: testStatusFetch, data: testStatus, error: testStatusError, loading: testStatusLoading} = useFetch();
  
  /** Refs: */
  const timer = React.useRef();

  React.useEffect(() => {
    const chars = test[practice ? 'practiceComponent' : 'component'].content.trim().split("").map(c => c);
    setCharsToType(chars);
  }, []);

  useKeyPress(key => {
    // Optimize by keeping track of chr index and current chr
    // But for clarity storing all typed characters.
    if(key !== "Backspace") {
      setChrsTyped((prevChrsTyped) => ([...prevChrsTyped, key]))
    } else {
      // Create a shallow copy of prev Chrs typed excluding the last element.
      setChrsTyped((prevChrsTyped) => (prevChrsTyped.filter((_, i) => i !== prevChrsTyped.length - 1)))
    }
  })

  // Start timer when user types first character.
  if(timer.current === undefined && chrsTyped.length > 0) {
    testStatusFetch('PUT', '/agents/{user.id}/tests/' + test.id + '/start' + (practice ? '?practice=true' : ''));
    // Start timer to keep track of WPM
    const start = Date.now();
    timer.current = setInterval(() => {
      setSeconds((Date.now() - start) / 1000);
    }, 500) // update about every 100ms

  }

  // Once we reach the end then stop timer.
  if(timer.current && (seconds >= 60 || charsToType.length <= chrsTyped.length)) {
    testStatusFetch('PUT', '/agents/{user.id}/tests/' + test.id + '/end' + (practice ? '?practice=true' : ''), {content: chrsTyped.join('')});
    clearInterval(timer.current);
    timer.current = null;
    setSeconds(60);
    navigate(url('tests'), {replace: true});
  }

  let chrCount = -1;

  // Calculate accuracy and wpm
  const correct = chrsTyped.reduce((acc, chr, i) => {
    return chr === charsToType[i] ? acc + 1 : acc;
  }, 0)

  const accuracy = correct / chrsTyped.length * 100;
  const accuracyText = `${correct} / ${chrsTyped.length} (${(accuracy).toFixed(0)}%)`
  const incorrect = chrsTyped.length - correct;
  const cpm = seconds > 0 ? (chrsTyped.length) / (seconds / 60) : 0;

  return (
    <>
      <div className="content__info-full">
        <div className="content__info-header-line">Click on the first word below to see the cursor and then the test will start when you begin typing </div>
        <div className="content__info-line">Do not close the browser, refresh the page, or click back after starting.</div>
        <div className="content__info-line">Once you start you will not be able to redo the test.</div>
        <div className="typing-speed__results-header">
          <div className="typing-speed__display">Time</div>
          <div className="typing-speed__display">Words/Min</div>
          <div className="typing-speed__display">Errors</div>
          <div className="typing-speed__display">Chars/Min</div>
        </div>
        <div>
          <div className="typing-speed__display typing-speed__timer">{Math.round(60 - seconds)}</div>
          <div className="typing-speed__display typing-speed__wpm">{(cpm/5).toFixed(0)} </div>
          <div className="typing-speed__display typing-speed__errors">{incorrect}</div>
          <div className="typing-speed__display typing-speed__cpm">{cpm.toFixed(0)}</div>
        </div>
      </div>
      {seconds < 60 ?
        <div className="typing-speed__content">
          {test[(practice ? 'practiceComponent' : 'component')].content.trim().split("").map((chr) => {
            chrCount += 1
            return (
              <Character chr={chr} key={chrCount} id={chrCount} chrsTyped={chrsTyped} seconds={seconds} />
            )
          })}
        </div>
      : <div>That completes the test. You will automatically be redirected back to the tests page in a few seconds.</div>
      }
    </>
  )
}

export default TypingTestForm;
