import React from 'react';
import GeneralInfo from '../components/GeneralInfo';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const ForgotPassword = () => {
  return (
    <section className="content content__no-auth">
      <div className="title">
        <h1>Forgot your password?</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Login Help',
            content: [
              {text: 'Your username is your email address.'},
              {text: 'Passwords ar a minimum of 8 characters and must include numeric, special, lower and uppercase characters.'},
              {text: 'If you still don\'t remember enter your email in the field provided and we will send a message to reset your password.'},
            ],
          },
        ]}/>
        <div className="forgot-password-form content__form">
          <GoogleReCaptchaProvider reCaptchaKey="6LfdZKUjAAAAACEziad2F6wX-1w0kMS0aU2Ghcgu">
            <ForgotPasswordForm/>
          </GoogleReCaptchaProvider>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
