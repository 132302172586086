'use strict'

module.exports = {
  PAYPAL : 1,
  WISE : 2,
  PAYONEER : 3,
  ids : {
    '1' : 'PayPal',
    '2' : 'Bank',
    '3' : 'Payoneer',
  },
};
