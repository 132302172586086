import React, { useState } from 'react';
import useFetch from '../../hooks/useFetch';
import {formatErrorMessage} from '../../helper';
import { PieChart, Pie, Sector, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { AgGridReact } from 'ag-grid-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Dates from '../../components/Dates';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const SummaryReport = () => {
  const now = new Date();
  const {apiFetch, data, error, loading} = useFetch();
  const [ offset, setOffset ] = useState(0);
  const [ pageSize, setPageSize ] = useState(100);
  const [startDate, setStartDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999));

  const submit = () => {
    apiFetch('GET', '/reports/agentSummary', {
      startedAt: startDate.getTime(),
      endedAt: endDate.getTime(),
      offset: offset,
      limit: pageSize,
    });
  };

  React.useEffect(() => {
    submit();
  }, []);

  const getChartData = () => {
    const statuses = {};
    for(const item of data) {
      if(!statuses[item.agentStatus]) {
        statuses[item.agentStatus] = {name: item.agentStatus, value: 0};
      }
      statuses[item.agentStatus].value += new Date(item.endedAt).getTime() - new Date(item.createdAt).getTime();
    }
    return Object.values(statuses);
  };

  const getRowData = () => {
    const rows = [];
    for(const item of data) {
      rows.push({agentStatus: item.agentStatus, startedAt: new Date(item.createdAt), endedAt: new Date(item.endedAt)});
    }
    return rows;
  };

  const getColumnData = () => {
    return [
      {field: 'agentStatus', headerName: 'Status'},
      {field: 'startedAt'},
      {field: 'endedAt'},
    ];
  }
  const onPieEnter = () => {
  };

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const customLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    return Math.round(100 * percent) + '%';
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Summary</h1>
      </div>
      <div className="content__main content__main--padded">
        <Formik
          validateOnChange={false}
          initialValues={{
            startDate: startDate,
            endDate: endDate,
            date: null,
          }}
          validationSchema={Yup.object({
          })}
          initialTouched={{
          }}
          onSubmit={(values) => {
          }}
        >
          {({ setFieldValue , isSubmitting }) => (
          <Form className="form report-form">
            <Dates startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
            <div className="report-header__line">
              <button className="button" onClick={submit}>Submit</button>
            </div>
          </Form>
          )}
        </Formik>
        {loading ? <div className="processing"></div> : ''}
        {data ? 
          (data.length > 0 ? 
          <>
            <PieChart width={320} height={320} onMouseEnter={onPieEnter}>
              <Pie
                data={getChartData()}
                cx={160}
                cy={160}
                innerRadius={80}
                outerRadius={100}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
                label={customLabel}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
             <div className="report-data-grid ag-theme-alpine">
               <AgGridReact
                 rowData={getRowData()}
                 columnDefs={getColumnData()}
                 onFirstDataRendered={onFirstDataRendered}
                 onGridSizeChanged={onGridSizeChanged}>
               </AgGridReact>
            </div>
          </>
            : <div className="info-message">No results</div>)
          : (!error && !loading ? <div className="info-message">Submit dates to see results</div> : '')
        }
        {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
      </div>
    </section>
  )
}

export default SummaryReport;
