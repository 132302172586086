'use strict'

const ADMINISTRATOR = 'administrator';
const MANAGER = 'manager';
const REPORTING = 'reporting';
const VERIFIED = 'verified';

module.exports = {
  PREFIX : 'role-',
  ADMINISTRATOR : ADMINISTRATOR,
  MANAGER : MANAGER,
  REPORTING : REPORTING,
  VERIFIED : VERIFIED,
  administrator : [ADMINISTRATOR, MANAGER, REPORTING],
  manager : [MANAGER, REPORTING],
  reporting : [REPORTING],
  verified : [VERIFIED],
  ids : {
    '1' : ADMINISTRATOR,
    '2' : MANAGER,
    '3' : REPORTING,
    '4' : VERIFIED,
  },
};
