import React, { useState } from 'react';
import KeypadButton from './KeypadButton';
import useLoudness from './hooks/useLoudness';
import useMuteWarning from './hooks/useMuteWarning';
import './OnCall.scss';

const OnCall = ({ device, addScreenPop }) => {
  const [muted, setMuted] = useState(false);
  const [running, setRunning, loudness] = useLoudness();
  const [showMuteWarning] = useMuteWarning(loudness, running);

  const handleMute = () => {
    device.toggleMute();
    setMuted(device.muted);
    setRunning(device.muted);
  };

  const handleHangUp = async () => {
    await device.hangUp();
  };

  const muteWarning = (
    <p className="warning">Are you speaking? You are on mute!</p>
  );

  return (
    <>
      {showMuteWarning && muteWarning}
      <button className="fa fa-external-link call__screen-pop" onClick={addScreenPop}>&nbsp;&nbsp;Screen Pop</button>
      <button className={`fa ${muted ? 'fa-microphone-slash' : 'fa-microphone'} call__mute`} onClick={handleMute}>&nbsp;&nbsp;{muted ? 'Unmute' : 'Mute'}</button>
      <button className="fa fa-window-close call__hang-up" onClick={handleHangUp}>&nbsp;&nbsp;Hang Up</button>
    </>
  );
};

export default OnCall;
