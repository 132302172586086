import { CarrierDevice } from './carrier-device';
import NexmoClient from 'nexmo-client';

class VonageDevice extends CarrierDevice {
  constructor(params) {
    super(params);
    this.device = new NexmoClient({ debug: true });
  };

  destroy() {
    let wasConnected = false;
    if(this.device) {
      wasConnected = this.endSession();
      this.device = null;
    }
    this.onDestroy(wasConnected);
  };

  endSession() {
    if(this.app) {
      if(this.app.session?.sessionReady) {
        this.device.deleteSession();
      }
      this.app = null;
      this.conversation = null;
      if(this.call) {
        this.call = null;
        return true;
      }
    }
    return false;
  };

  async connect(params) {
    //TODO this is the default user for the vonage app 222a41a0-ccf2-11ed-a992-8197847d2312
    this.app.callServer(params.agentId, 'app', params);
  };

  async initialize(token, params) {
    this.app = await this.device.createSession(token);
    this.app.on("member:call", (member, call) => {
      console.log('receiving call');
      /*
        notification.textContent = "You are receiving a call";
        // Answer the call.
        answerBtn.addEventListener("click", () => {
            call.answer();
            notification.textContent = "You are in a call";
        });
        // Reject the call
        rejectBtn.addEventListener("click", () => {
            call.reject();
            notification.textContent = `You rejected the call`;
        });
        // Hang-up the call
        hangupBtn.addEventListener("click", () => {
            call.hangUp();
            notification.textContent = `You ended the call`;
        });
        */
    });
    this.app.on('call:status:changed', (call) => {
      console.log('call status: ' + call.status);
      /*
      STARTED: 'started',
      RINGING: 'ringing',
      ANSWERED: 'answered',
      COMPLETED: 'completed',
      BUSY: 'busy',
      TIMEOUT: 'timeout',
      UNANSWERED: 'unanswered',
      REJECTED: 'rejected',
      FAILED: 'failed'
      */
      //TODO handle failed
      if (call.status === call.CALL_STATUS.STARTED) {
        this.call = call;
        this.conversation = call.conversation;
        this.conversation.on('*', async (e) => {
          console.log('conversation * ' + e);
        });
        if(this.conversation.me) {
          this.conversation.me.on('*', async (e) => {
            console.log('conversation me * ' + e);
          });
          this.conversation.me.on('media:stream:on', async (e) => {
            console.log('media stream ' + e);
          });
        }
        this.onConnect();
      } else if (call.status === call.CALL_STATUS.RINGING) {
        this.onIncomingCall();
      } else if (call.status === call.CALL_STATUS.ANSWERED) {
      } else {
        this.onDisconnect();
        this.destroy();
      }
    });
    this.app.on('cancel', async (call) => {
      this.onCancel();
    });
    this.app.on('offline', async (call) => {
      this.onOffline();
    });
    this.app.on('error', async (call) => {
      this.onError();
    });
    this.app.on('system:error:expired-token', async (err) => {
      this.onError(err);
    });
    this.app.on('*', async (e) => {
      console.log('app * ' + e);
    });
    this.onReady();
    this.device.on('*', async (e) => {
      console.log('device * ' + e);
    });
  };

  sendDigits(digits) {
    this.conversation.media.sendDTMF(digits);
  };

  getAuthorizationUrl() {
    return '/vonage/clientAuthorize';
  };

  async toggleMute() {
    this.muted = !this.muted;
    try {
      if(this.conversation) {
        await this.conversation.me.mute(this.muted);
      } else {
        this.destroy();
      }
    } catch(err) {
      console.log(err);
      if(this.isCallUnavailable(err)) {
        this.destroy();
      }
    }
  };

  async toggleEarMuff(user) {
    try {
      if(this.conversation) {
        if(this.earMuffed[user]) {
          delete this.earMuffed[user];
          await this.conversation[user].earmuff(false);
        } else {
          this.earMuffed[user] = user;
          await this.conversation[user].earmuff(true);
        }
      } else {
        this.destroy();
      }
    } catch(err) {
      console.log(err);
      if(this.isCallUnavailable(err)) {
        this.destroy();
      }
    }
  };

  async hangUp() {
    if(this.call) {
      try {
        await this.call.hangUp();
      } catch(err) {
        this.destroy();
      }
    } else {
      this.destroy();
    }
  };

  async decline() {
    try {
      await this.call?.reject();
    } catch(err) {
      if(this.isCallUnavailable(err)) {
        this.destroy();
      }
    }
  };

  isCallUnavailable(err) {
    return err.description?.indexOf('state of the conversation is INACTIVE') > -1 || err.description?.indexOf('Conversation does not exist') > -1 || err.description?.indexOf('invalid current member state') > -1;
  };
};

export {
  VonageDevice,
};
