import { createSlice } from '@reduxjs/toolkit'

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState: {
    value: '',
  },
  reducers: {
    update: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { update, increment, decrement } = tokensSlice.actions;

export default tokensSlice.reducer;
