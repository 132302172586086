'use strict'

module.exports = {
  CHECKING : 1,
  SAVINGS : 2,
  ids : {
    '1' : 'Checking',
    '2' : 'Savings',
  },
};
