'use strict'

module.exports = {
  SIGNED_UP: 1,
  CONFIRMED_SIGNUP: 2,
  SIGNED_IN: 3,
  SIGNED_OUT: 4,
  ADDED_MFA: 5,
  REMOVED_MFA: 6,
  UPDATED_EMAIL: 7,
  VERIFIED_EMAIL: 8,
  UPDATED_PHONE_NUMBER: 9,
  VERIFIED_PHONE_NUMBER: 10,
  UPDATED_PASSWORD: 11,
  UPDATED_ADDRESS: 12,
  UPDATED_NAME: 13,
  FORGOT_PASSWORD: 14,
  RESET_PASSWORD: 15,
  UPDATED_BIRTHDAY: 16,
  ADDED_PAYMENT_METHOD: 17,
  REMOVED_PAYMENT_METHOD: 18,
  UPDATED_DEFAULT_PAYMENT_METHOD: 19,
  UPDATED_TIME_ZONE: 20,
  COMPLETED_INTERVIEW: 21,
  COMPLETED_VERBAL_TEST: 22,
  COMPLETED_TRANSCRIPTION_TEST: 23,
  COMPLETED_TYPING_TEST: 24,
  STARTED_INTERVIEW: 25,
  STARTED_VERBAL_TEST: 26,
  STARTED_TRANSCRIPTION_TEST: 27,
  STARTED_TYPING_TEST: 28,
  ADDED_GOAL: 29,
  UPDATED_GOAL: 30,
  COMPLETED_GOAL: 31,
  ADDED_TEST: 32,
  UPDATED_MONEY: 34,
  UPDATED_TOKENS: 35,
  UPDATED_BADGES: 36,
  ADDED_ROLE: 37,
  REMOVED_ROLE: 38,
  CALL_RECIEVED: 39,
  CALL_ANSWERED: 40,
  CALL_DECLINED: 41,
  CALL_MISSED: 42,
  CUSTOMER_HANGUP: 43,
  AGENT_HANGUP: 44,
  COMPLETED_PRACTICE_INTERVIEW: 45,
  COMPLETED_PRACTICE_VERBAL_TEST: 46,
  COMPLETED_PRACTICE_TRANSCRIPTION_TEST: 47,
  COMPLETED_PRACTICE_TYPING_TEST: 48,
  STARTED_PRACTICE_INTERVIEW: 49,
  STARTED_PRACTICE_VERBAL_TEST: 50,
  STARTED_PRACTICE_TRANSCRIPTION_TEST: 51,
  STARTED_PRACTICE_TYPING_TEST: 52,
  CLOSED_GOAL: 53,
  UPDATED_RESUME: 54,
  UPDATED_UNSETTLED_MONEY: 55,
  UPDATED_UNSETTLED_TOKENS: 56,
};
