'use strict'

module.exports = {
  INITIATED: 1,
  QUEUED: 2,
  REQUEUED: 3,
  ROUTED: 4,
  AGENT_DECLINED: 5,
  AGENT_NO_ANSWER: 6,
  AGENT_CONNECTED_CONFERENCE: 7,
  AGENT_DISCONNECTED_CONFERENCE: 8,
  PARTY_DISCONNECTED_CONFERENCE: 9,
  DEQUEUED: 10,
  CALL_RECORDING_ADDED: 11,
  TRANSCRIPTION_ADDED: 12,
  DISPOSITION_ADDED: 13,
  ROLLED_OVER: 14,
  DIALING: 15,
  PARTY_BUSY: 16,
  PARTY_NO_ANSWER: 17,
  PARTY_CONNECTED_CONFERENCE: 18,
  FAILED: 19,
  CANCELLED: 20,
  ENTERED: 21, //IVR
  DIGITS_GATHERED: 22,
  OFF_HOURS: 23,
  QUEUED_OFFLINE: 24,
  EXITED: 25,
  //VOICEMAIL_ADDED: 26,
  //VOICEMAIL_ASSIGNED: 27,
  //VOICEMAIL_UNASSIGNED: 28,
  SPEECH_GATHERED: 29,
  AGENT_CONNECTED: 30,
  AGENT_DISCONNECTED: 31,
  PARTY_DISCONNECTED: 32,
  PARTY_CONNECTED: 33,
  AGENT_ACCEPTED: 34,
  SMS_MESSAGE_QUEUED: 35,
  MMS_MESSAGE_QUEUED: 36,
  SMS_MESSAGE_SENT: 37,
  MMS_MESSAGE_SENT: 38,
  CALL_CONVERSION: 39,
  CALL_STATUSES: [2, 3, 4, 7, 15, 18, 21, 30, 33],
  NOTIFICATION_STATUSES: [2, 3, 10],
  ids: {
    '1': 'Initiated',
    '2': 'Queued',
    '3': 'Requeued',
    '4': 'Routed',
    '5': 'Agent Declined',
    '6': 'Agent No Answer',
    '7': 'Agent Connected Conference',
    '8': 'Agent Disconnected Conference',
    '9': 'Party Disconnected Conference',
    '10': 'Dequeued',
    '11': 'Call Recording Added',
    '12': 'Call Transcribed',
    '13': 'Disposition Added',
    '14': 'Rolled Over',
    '15': 'Dialing',
    '16': 'Party Busy',
    '17': 'Party No Answer',
    '18': 'Party Connected Conference',
    '19': 'Failed',
    '20': 'Cancelled',
    '21': 'Entered',
    '22': 'Digits Gathered',
    '23': 'Off Hours',
    '24': 'Queued Offline',
    '25': 'Exited',
    //'26': 'Voicemail Added',
    //'27': 'Voicemail Assigned',
    //'28': 'Voicemail Unassigned',
    '29': 'Speech Gathered',
    '30': 'Agent Connected',
    '31': 'Agent Disconnected',
    '32': 'Party Disconnected',
    '33': 'Party Connected',
    '34': 'Agent Accepted',
    '35': 'SMS Message Queued',
    '36': 'MMS Message Queued',
    '37': 'SMS Message Sent',
    '38': 'MMS Message Sent',
    '39': 'Call Conversion',
  },
};
