'use strict'

module.exports = {
  UNCONFIRMED: 1,
  CONFIRMED: 2,
  SIGNED_IN: 3,
  SIGNED_OUT: 4,
  DISABLED: 5,
  DELETED: 6,
};
