import React from 'react';
import { useField } from 'formik';
import { titleToDash } from 'agents-remotely-commons/src/formatter';

const HiddenInput = ({ label, formName, ...props }) => {
 // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
 // which we can spread on <input>. We can use field meta to show an error
 // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  const classPrefix = formName + '__' + titleToDash(field.name);
  return (
    <>
      <input className={'form__hidden-input ' + classPrefix} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className={'form__error ' + classPrefix + '-error'}>{meta.error}</div>
      ) : null}
    </>
  );
};

export default HiddenInput;
