import React from 'react';
import GeneralInfo from '../../components/GeneralInfo';
import VerificationForm from '../../components/VerificationForm';
import useFetch from '../../hooks/useFetch';
import {formatErrorMessage} from '../../helper';

const Verification = (props) => {
  const {apiFetch, data: verification, error, loading} = useFetch();

  React.useEffect(() => {
    apiFetch('GET', '/agents/{user.id}/' + props.verificationType + 'Verification', {accessToken: '{user.accessToken}'});
  }, []);

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Verification</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Verification',
            content: [
              {text: 'NEVER share the code you receive with anyone.'},
              {text: 'No one from Agents Remotely or the companies on Agents Remotely will ask you for it.'},
              {text: 'If someone does ask you for it, please report the incident to us.'},
            ],
          },
        ]}/>
        <div className="verification-form content__form">
          {loading ? <div className="processing"></div> : ''}
          {verification ? <VerificationForm formName="verification" verificationType={props.verificationType}/> : ''}
          {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
        </div>
      </div>
    </section>
  );
}

export default Verification;
