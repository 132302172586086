'use strict'
const accountStatus = require('./account-status');
const agent = require('./agent');
const agentComponentStatus = require('./agent-component-status');
const agentJobStatus = require('./agent-job-status');
const agentPaymentType = require('./agent-payment-type');
const agentRole = require('./agent-role');
const agentRoutingType = require('./agent-routing-type');
const agentTask = require('./agent-task');
const aggregatorType = require('./aggregator-type');
const badge = require('./badge');
const bankAccountType = require('./bank-account-type');
const bankRecipientType = require('./bank-recipient-type');
const calendar = require('./calendar');
const callDetailType = require('./call-detail-type');
const callDirection = require('./call-direction');
const callType = require('./call-type');
const carrier = require('./carrier');
const choiceType = require('./choice-type');
const company = require('./company');
const component = require('./component');
const componentActionType = require('./component-action-type');
const componentStatus = require('./component-status');
const componentType = require('./component-type');
const conditionRequirementType = require('./condition-requirement-type');
const country = require('./country');
const currency = require('./currency');
const customerRole = require('./customer-role');
const customerTask = require('./customer-task');
const distributionType = require('./distribution-type');
const endpointType = require('./endpoint-type');
const environment = require('./environment');
const eventCategory = require('./event-category');
const gameType = require('./game-type');
const gender = require('./gender');
const goal = require('./goal');
const holdMusic = require('./hold-music');
const http = require('./http');
const industry = require('./industry');
const invoiceItemType = require('./invoice-item-type');
const jobAttribute = require('./job-attribute');
const jobJoinType = require('./job-join-type');
const jobStatus = require('./job-status');
const language = require('./language');
const languageCode = require('./language-code');
const offScheduleOption = require('./off-schedule-option');
const operatorType = require('./operator-type');
const party = require('./party');
const partyConnectionType = require('./party-connection-type');
const paymentMethod = require('./payment-method');
const phoneNumberPatternType = require('./phone-number-pattern-type');
const phoneNumberType = require('./phone-number-type');
const rewardType = require('./reward-type');
const rolloverOption = require('./rollover-option');
const schedule = require('./schedule');
const scheduleType = require('./schedule-type');
const softphoneStatus = require('./softphone-status');
const stateProvince = require('./state-province');
const testType = require('./test-type');
const timeIntervalUnitType = require('./time-interval-unit-type');
const timeIntervalType = require('./time-interval-type');
const timeZone = require('./time-zone');
const userEventType = require('./user-event-type');
const waitNotificationType = require('./wait-notification-type');
const workStatus = require('./work-status');
const workType = require('./work-type');
const yearsOfExperience = require('./years-of-experience');

module.exports = {
  accountStatus,
  agent,
  agentComponentStatus,
  agentJobStatus,
  agentPaymentType,
  agentRole,
  agentRoutingType,
  agentTask,
  aggregatorType,
  badge,
  bankAccountType,
  bankRecipientType,
  calendar,
  callDetailType,
  callDirection,
  callType,
  carrier,
  choiceType,
  company,
  component,
  componentActionType,
  componentStatus,
  componentType,
  conditionRequirementType,
  country,
  currency,
  customerRole,
  customerTask,
  distributionType,
  endpointType,
  environment,
  eventCategory,
  gameType,
  gender,
  goal,
  holdMusic,
  http,
  industry,
  invoiceItemType,
  jobAttribute,
  jobJoinType,
  jobStatus,
  language,
  languageCode,
  offScheduleOption,
  operatorType,
  party,
  partyConnectionType,
  paymentMethod,
  phoneNumberPatternType,
  phoneNumberType,
  rewardType,
  rolloverOption,
  schedule,
  scheduleType,
  softphoneStatus,
  stateProvince,
  testType,
  timeIntervalUnitType,
  timeIntervalType,
  timeZone,
  userEventType,
  waitNotificationType,
  workStatus,
  workType,
  yearsOfExperience,
};
