import React from 'react';
import './Badges.scss';

const Badges = ({badges}) => {
  const renderBadgeGroup = (badgeGroupId) => {
    const rows = [];
    for(const badge of badges[badgeGroupId]) {
      if(badge.acquiredAt) {
        rows.push(
          <div className="badge" key={badge.id}>
            <img className="badge-img" src={`/images/badges/${badge.id}.png`} alt={badge.description}/>
            <span className="badge-title">{badge.name}</span>
          </div>
        );
      } else {
        rows.push(
          <div className="badge" key={badge.id}>
            <span className="badge-img fa fa-question"></span>
            <span className="badge-description">{badge.howToAcquire}</span>
          </div>
        );
      }
    }
    return (
      <div className="badges" key={badgeGroupId}>
        {rows}
      </div>
    );
  };

  const rows = [];
  for(const badgeGroupId in badges) {
    rows.push(renderBadgeGroup(badgeGroupId));
  }

  return rows;
}

export default Badges;
