import React from 'react';
import GeneralInfo from '../../components/GeneralInfo';
import PaymentMethodForm from '../../components/PaymentMethodForm';
import useFetch from '../../hooks/useFetch';
import {formatErrorMessage} from '../../helper';

const PaymentMethod = (props) => {
  return (
    <div className="payment-method">
      <span className="payment-method__type-and-nickname">
        <span className="payment-method__type-description">{props.paymentMethod.type.description}</span>
        &nbsp;-&nbsp;
        <span className="payment-method__nickname">{props.paymentMethod.nickname}</span>
      </span>
      <span className="payment-method__account-display">{props.paymentMethod.accountDisplay}</span>
      {props.paymentMethod.default ?
        <>
          <span className="payment-method__is-default fa fa-check-square"></span>
          <span className="payment-method__delete"></span>
        </>
      :
        <>
          {props.defaultPaymentMethodLoading ? <span className="payment-method__is-default"><div className="processing"></div></span> :
          <span className="payment-method__is-default fa fa-square" onClick={() => props.updateDefaultPaymentMethod(props.paymentMethod.id)}></span>
          }
          {props.deletePaymentMethodLoading ? <span className="payment-method__delete"><div className="processing"></div></span> :
          <span className="payment-method__delete fa fa-times-circle"onClick={() => props.deletePaymentMethod(props.paymentMethod.id)}></span>
          }
        </>
      }
    </div>
  );
};

const PaymentMethods = () => {
  const {apiFetch, data: paymentMethods, error, loading, setData: setPaymentMethods} = useFetch();
  const {apiFetch: deletePaymentMethodFetch, data: deletedPaymentMethod, error: deletePaymentMethodError, loading: deletePaymentMethodLoading, setData: setDeletedPaymentMethod} = useFetch();
  const {apiFetch: defaultPaymentMethodFetch, data: defaultPaymentMethod, error: defaultPaymentMethodError, loading: defaultPaymentMethodLoading, setData: setDefaultPaymentMethod} = useFetch();

  const deletePaymentMethod = (id) => {
    deletePaymentMethodFetch('DELETE', '/agents/{user.id}/paymentMethods/' + id);
  };

  const updateDefaultPaymentMethod = (id) => {
    defaultPaymentMethodFetch('PUT', '/agents/{user.id}/paymentMethods/' + id + '/default');
  };

  const updatePaymentMethod = () => {
    setPaymentMethods(null);
    apiFetch('GET', '/agents/{user.id}/paymentMethods');
  };

  if(deletedPaymentMethod || defaultPaymentMethod) {
    setDeletedPaymentMethod(null);
    setDefaultPaymentMethod(null);
    updatePaymentMethod();
  };

  React.useEffect(() => {
    updatePaymentMethod();
  }, []);

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Payment Settings</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'To save money and make payment management easier, keep in mind a few key things.',
            content: [
              {text: 'Bank transfers tend to have higher fees, so consider using alternative payment methods.'},
              {text: 'Opting for payment in USD can effectively lower your fees by avoiding extra costs associated with currency conversion and international transfers.'},
              {text: 'You can store multiple payment methods on our platform, making payments faster and more convenient for you and your clients/customers.'},
            ],
          },
        ]}/>
        {loading ? <div className="processing"></div> : ''}
        {error ? <div className="error-message">{formatErrorMessage(error)}</div> : ''}
        {deletePaymentMethodError ? <div className="error-message">{formatErrorMessage(deletePaymentMethodError)}</div> : ''}
        {defaultPaymentMethodError ? <div className="error-message">{formatErrorMessage(defaultPaymentMethodError)}</div> : ''}
        {paymentMethods ?
          <>
            <div className="payment-settings-form content__form">
              <PaymentMethodForm updatePaymentMethod={updatePaymentMethod}/>
            </div>
            {paymentMethods.length > 0 ?
            <div className="payment-methods form">
              <div className="payment-method--title">
                <span className="payment-method__type-and-nickname">Type/Nickname</span>
                <span className="payment-method__account-display">Info</span>
                <span className="payment-method__is-default">Default</span>
                <span className="payment-method__delete">Delete</span>
              </div>
              {paymentMethods.map(paymentMethod => <PaymentMethod paymentMethod={paymentMethod} key={paymentMethod.id} deletePaymentMethod={deletePaymentMethod} updateDefaultPaymentMethod={updateDefaultPaymentMethod} defaultPaymentMethodLoading={defaultPaymentMethodLoading} deletePaymentMethodLoading={deletePaymentMethodLoading}/>)}
            </div>
            : ''}
          </> : ''
        }
      </div>
    </section>
  );
}

export default PaymentMethods;
