import React, { useState } from 'react';
import WheelComponent from '../../components/WheelOfPrizes';
import { rewardType } from 'agents-remotely-commons/src/model';
import useFetch from '../../hooks/useFetch';
import { formatErrorMessage, getQueryStringParameter } from '../../helper';
import { useSelector } from 'react-redux';

const shuffle = (array) => {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const Wheel = () => {
  const tokens = useSelector((state) => state.tokens.value);
  const [wager, setWager] = useState(10);
  const {apiFetch: gameFetch, data: game, error: gameError, loading: gameLoading, setData: setGame} = useFetch();
  const {apiFetch: playGameFetch, data: playGame, error: playGameError, loading: playGameLoading, setData: setPlayGame} = useFetch();
  React.useEffect(() => {
    gameFetch('GET', '/games/' + getQueryStringParameter('id') + '/' + wager);
  }, [wager]);

  const segColors= [
    '#e6194B', '#3cb44b', '#ccca98', '#4363d8', '#f58231', '#911eb4', '#42d4f4', '#ffd8b1', '#bfef45', '#fabed4', '#469990', '#dcbeff', '#fff000', '#9A6324', '#800000', '#aaffc3', '#808000', '#f032e6', '#000075', '#a9a9a9', '#ffe159'
  ];
  const onFinished = (winner) => {
    console.log(winner)
  }

  const renderWager = () => {
    const options = [];
    for(let i = game.minTokens; i <= game.maxTokens && i <= tokens; i+=game.step) {
      options.push(<option value={i} selected={i === wager}>{i}</option>);
    }
    return (
      <>
        <select onChange={(e) => {setPlayGame(null); setWager(Number(e.target.value))}}>
          {options}
        </select>
        <button onClick={(e) => {play()}}>Spin</button>
      </>
    );
  };

  const play = () => {
    const transactionId = 'test';
    playGameFetch('PUT', '/games/' + getQueryStringParameter('id') + '/' + wager);
  };

  if(game && !game.shuffled) {
    game.outcomes = shuffle(game.outcomes.map(outcome => (outcome.rewardTypeId === rewardType.MONEY ? '$' + outcome.value : outcome.value)));
    game.shuffled = true;
    setGame(game);
  }

  return (
    <section className="content content__auth">
      <div className="title">
        <h1>Wheel of Prizes</h1>
      </div>
      {gameError ? <div className="error-message">{formatErrorMessage(gameError)}</div> : ''}
      {playGameError ? <div className="error-message">{formatErrorMessage(playGameError)}</div> : ''}
      <div className="token-game">
        <div className="token-game__board">
          {gameLoading || (game && !game.shuffled) ? <div className="processing"></div> : ''}
          {game ? 
            <WheelComponent
              segments={game.outcomes}
              segColors={segColors}
              game={playGame}
              onFinished={(winner) => onFinished(winner)}
              primaryColor='black'
              contrastColor='white'
              buttonText='Spin'
              isOnlyOnce={false}
              size={280}
              upDuration={1000}
              downDuration={10000}
              fontFamily='Arial'
            />
            : ''
          }
        </div>
        <div className="token-game__controls">
          {!playGameLoading && game ? renderWager() : ''}
        </div>
      </div>
    </section>
  );
}

export default Wheel;
