'use strict'

module.exports = {
  VERBAL : 1,
  TRANSCRIPTION : 2,
  TYPING : 3,
  INTERVIEW : 4,
  ids : {
    '1' : 'Verbal',
    '2' : 'Transcription',
    '3' : 'Typing',
    '4' : 'Interview',
  },
};
