import React, { useState } from 'react';
import './Dialer.css';
import KeypadButton from './KeypadButton';

const Dialer = ({ number, setNumber, handleCall }) => {
  const [showKeypad, setShowKeypad] = useState(false);

  const toggleKeypad = () => {
    setShowKeypad(current => !current);
  };

  const handleNumberChange = event => {
    setNumber(event.target.value);
  };

  const handleBackSpace = () => {
    setNumber(number.substring(0, number.length - 1));
  };

  const handleNumberPressed = newNumber => {
    return () => {
      setNumber(`${number}${newNumber}`);
    };
  };

  return (
    <div className="dialer">
      <div className="dialer__input-line">
        <input type="tel" value={number} onChange={handleNumberChange} className="dialer__input" placeholder="+15556667777" />
        <button className="dialer__back" onClick={handleBackSpace}>&lt;&lt;</button>
      </div>
      {showKeypad ? 
        <ol className="keypad">
          <li>
            <KeypadButton handleClick={handleNumberPressed("1")}>1</KeypadButton>
          </li>
          <li>
            <KeypadButton handleClick={handleNumberPressed("2")}>2</KeypadButton>
          </li>
          <li>
            <KeypadButton handleClick={handleNumberPressed("3")}>3</KeypadButton>
          </li>
          <li>
            <span className="keypad-extra">&nbsp;</span>
          </li>
          <li>
            <span className="keypad-extra">A B C</span>
          </li>
          <li>
            <span className="keypad-extra">D E F</span>
          </li>
          <li>
            <KeypadButton handleClick={handleNumberPressed("4")}>4</KeypadButton>
          </li>
          <li>
            <KeypadButton handleClick={handleNumberPressed("5")}>5</KeypadButton>
          </li>
          <li>
            <KeypadButton handleClick={handleNumberPressed("6")}>6</KeypadButton>
          </li>
          <li>
            <span className="keypad-extra">G H I</span>
          </li>
          <li>
            <span className="keypad-extra">J K L</span>
          </li>
          <li>
            <span className="keypad-extra">M N O</span>
          </li>
          <li>
            <KeypadButton handleClick={handleNumberPressed("7")}>7</KeypadButton>
          </li>
          <li>
            <KeypadButton handleClick={handleNumberPressed("8")}>8</KeypadButton>
          </li>
          <li>
            <KeypadButton handleClick={handleNumberPressed("9")}>9</KeypadButton>
          </li>
          <li>
            <span className="keypad-extra">P Q R S</span>
          </li>
          <li>
            <span className="keypad-extra">T U V</span>
          </li>
          <li>
            <span className="keypad-extra">W X Y Z</span>
          </li>
          <li>
            <KeypadButton handleClick={handleNumberPressed("*")}>*</KeypadButton>
          </li>
          <li>
            <KeypadButton handleClick={handleNumberPressed("0")}>0</KeypadButton>
          </li>
          <li>
            <KeypadButton handleClick={handleNumberPressed("#")}>#</KeypadButton>
          </li>
        </ol>
        : ''
      }
      <div className="call__button-row">
        <KeypadButton handleClick={toggleKeypad}><span className="fa fa-keyboard"></span></KeypadButton>
        <button className="call__make fa fa-phone" onClick={handleCall}></button>
      </div>
    </div>
  );
};

export default Dialer;
