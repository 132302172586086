'use strict'

const ids = {
  '1': 'Toll-Free',
  '2': 'Landline',
  '3': 'Mobile',
};

const values = {};
for(const id in ids) {
  values[ids[id]] = parseInt(id);
}

module.exports = {
  TOLL_FREE: 1,
  LANDLINE: 2,
  MOBILE: 3,
  ids: ids,
  values: values,
};
