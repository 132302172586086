import { configureStore } from '@reduxjs/toolkit'
import badgesReducer from '../features/badgesSlice'
import goalsReducer from '../features/goalsSlice'
import moneyReducer from '../features/moneySlice'
import testsReducer from '../features/testsSlice'
import tokensReducer from '../features/tokensSlice'

export default configureStore({
  reducer: {
    badges: badgesReducer,
    goals: goalsReducer,
    money: moneyReducer,
    tests: testsReducer,
    tokens: tokensReducer,
  },
});
