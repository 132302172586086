'use strict'

module.exports = {
  ENGLISH : 1,
  SPANISH : 2,
  FRENCH : 3,
  RUSSIAN : 4,
  GERMAN : 5,
  DUTCH : 6,
  PORTUGESE : 7,
  HINDI : 8,
  CHINESE : 9,
  ids : {
    '1' : 'English',
    '2' : 'Spanish',
    '3' : 'French',
    '4' : 'Russian',
    '5' : 'German',
    '6' : 'Dutch',
    '7' : 'Portugese',
    '8' : 'Hindi',
    '9' : 'Chinese',
  },
};
