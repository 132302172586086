import React from 'react';
import GeneralInfo from '../components/GeneralInfo';
import TextInput from '../components/TextInput';
import { NavLink } from 'react-router-dom';
import { url } from 'agents-remotely-commons/src/formatter';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { getQueryStringParameter, formatErrorMessage } from '../helper';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const ConfirmAccount = () => {
  const {apiFetch: confirmFetch, data: user, error, loading} = useFetch();
  const {apiFetch: resendFetch, data: resend, error: resendError, loading: resendLoading} = useFetch();
  const navigate = useNavigate();

  const resendCode = () => {
    resendFetch('PUT', '/agents', {email: getQueryStringParameter('email')});
  };

  React.useEffect(() => {
    if(getQueryStringParameter('resend') === 'true') {
      resendCode();
    }
  }, []);

  if(user) {
    navigate(url('signIn'));
  }
  return (
    <section className="content content__no-auth">
      <div className="title">
        <h1>Confirm Account</h1>
      </div>
      <div className="content__container">
        <GeneralInfo contentGroups={[
          {
            text: 'Confirmation',
            content: [
            {text: (<>
                {'We have successfully sent a confirmation code to your registered mobile number via SMS. If you have not received the code yet, we kindly suggest waiting for a few minutes before '}
              {resendLoading ? <span className="processing"></span> : <span className="confirm-account__resend" onClick={resendCode}>clicking here</span>}
                {' to resend.'}
              </>),
            }],
          },
        ]}/>
        <GoogleReCaptchaProvider reCaptchaKey="6LfdZKUjAAAAACEziad2F6wX-1w0kMS0aU2Ghcgu">
          <div className="confirm-account-form content__form">
            <Formik
              validateOnChange={false}
              initialValues={{
                email: getQueryStringParameter('email'),
                confirmationCode: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string().email('Email is invalid').required('Email is required'),
                confirmationCode: Yup.string()
                  .required('Confirmation code is required')
                  .matches(/^[0-9]+$/, "Confirmation code must be only digits")
                  .min(6, 'Confirmation code must be exactly 6 digits')
                  .max(6, 'Confirmation code must be exactly 6 digits'),
              })}
              onSubmit={(values) => {
                confirmFetch('PUT', '/agents', values);
              }}
            >
              <Form className="form confirm-account">
                {error ? <div className="form__error">{formatErrorMessage(error)}</div> : ''}
                {resendError ? <div className="form__error">{formatErrorMessage(resendError)}</div> : ''}
                {resend ? 
                  <>
                    {resend === 'Already verified' ?
                      <div className="form__error">
                        The account has already been verified.
                        &nbsp;
                        <NavLink className="form__link confirm-account__resend" to={url('signIn')}>Click here</NavLink> to login.
                      </div>
                    : <div className="form__error">{resend.confirmationCodeSentBy + ' has been resent to ' + resend.confirmationCodeSentTo}</div>}
                  </>
                : ''}

                {resend === 'Already verified' ? '' :
                  <>
                    <div className="form__line">
                      <TextInput
                        formName="confirm-account"
                        name="email"
                        type="hidden"
                        autoComplete="email"
                        autoCorrect="off"
                        spellCheck="false"
                        required="required"
                      />
                      <TextInput
                        formName="confirm-account"
                        label="Confirmation Code"
                        name="confirmationCode"
                        type="tel"
                        autoFocus={true}
                        autoComplete="off"
                        autoCorrect="off"
                        spellCheck="false"
                        required="required"
                      />
                    </div>
                    <div className="form__line">
                      {loading ? <div className="processing"></div>  : <button className="form__submit" type="submit">Submit</button>}
                    </div>
                  </>
                }
              </Form>
            </Formik>
          </div>
        </GoogleReCaptchaProvider>
      </div>
    </section>
  );
}

export default ConfirmAccount;
